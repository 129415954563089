// ==========================================================================================================
// ==== VIEW WATCHLIST SHARED ===============================================================================
// ==========================================================================================================

/**
 * @description Methods that need to be called on every view
 */

modulejs.define(
	// name
	'view.watchlist.shared',
	// dependencies
	[
		'jQuery',
		'shared.helper',
		'service.config',
		'service.state',
		'controller.ajax',
		'controller.meta',
		'controller.watchlist',
		'controller.pageChange',
		'controller.lazyImage',
		'module.closePage'
	],
	// constructor
	function ( $, Helper, Config, State, Ajax, Meta, Watchlist, PageChange, LazyImage, ClosePage ) {

		// VIEW NAME //////////////////////////////////////////////////////////
		var name = 'WatchlistShared';

		// STATIC VARS ////////////////////////////////////////////////////////
		var TYPE = 'View',
			URL = '/merkliste',
			LOAD_COUNT = 12;

		// PRIVATE VARS ///////////////////////////////////////////////////////
		var _log = TYPE + '.' + name + ' | ',

			_watchlist = false,

			_selector = '[view=watchlist_shared]',

			_selector_list = '.watchlist-teaser',

			_selector_teaser = '.teaser',
			_selector_teaser_grid = _selector_list + ' > .row > .columns',
			_selector_container = '[role=main]',
			_selector_teaser_container = '.watchlist-teaser > .row',
			
			_selector_load_more = '#btn_load_more',
			_selector_remove_button = '.btn-remove',
			_selector_transfer_button = '#btn_transfer_list',
			_selector_save_list_button = '#btn_save_list',
			_selector_list_holder = '#list',

			_element = [],
			_element_more_button = [],
			_element_generate_button = [],
			_element_code_display = [],
			_element_transfer_button = [],
			_element_save_list_button = [],

			_element_list = [],
			_element_teaser_list = [],

			_attr_id = 'data-watchlist-id',
			_attr_list = 'data-list',

			_current_id = false,

			_list = [],

			_load_section = [],

			_loaded = 0,

			_initialized = false;
		
		// PRIVATE METHODS ////////////////////////////////////////////////////
		var View = {};

		View.log = function ( string ) {
			console.log( _log + string );
		};

		View.init = function () {

			View.log('init');

			_loaded = 0;

			_element = $(_selector);
			_element_list = $(_selector_list, _element);
			
			_element_more_button = $(_selector_load_more, _element);
			_element_teaser_list = $(_selector_teaser_container, _element);

			_element_transfer_button = $(_selector_transfer_button, _element);
			_element_save_list_button = $(_selector_save_list_button);

			_current_id = _element.attr(_attr_id);
			console.log(_current_id);

			var listString = window.watchlists['list_'+_current_id];
			
			if ( listString.length ) {
				_list = listString;
			}
			
			_element_transfer_button.on('click', function (event) {

				View.log('transfer');

				var newList = [];

				for (var i = 0, l = _list.length; i < l; i++) {
					newList.push([_list[i]]);
				}

				console.log(newList);

				if (newList.length) {
					Watchlist.addList(newList);
				}
			});


			_element_save_list_button.on('click', function (event) {

				View.log('save shared');

				if (_current_id && _current_id.length > 0) {
					Watchlist.saveSharedList(_current_id);
				}
			});

			
			LazyImage.init(_element_teaser_list);
			
			_element_more_button.hide();


			ClosePage.init(_element);

			$(window).trigger('scroll');

			Meta.readFrom(_element);
			
			_initialized = true;

		};

		View.show = function () {
			View.log('show');
			if (!_initialized) View.init();

			State.current_view = name;
		};


		
		View.load = function (ctx) {
			
			// check if data is already cached
			
			View.log( 'Route.load' );

			var defer = $.Deferred();

			$.get(ctx.path, function (data) {

				var $temp = $(data).filter('.page');

				$(_selector_container).append($temp);

				_element = $temp;

				defer.resolve();
			})
			.fail(function(){
				defer.reject();
			});

			return defer.promise();
		};
		
		/*
		View.loadTeaser = function () {

			View.log('load from '+_loaded+' to '+(_loaded+LOAD_COUNT));

			var section = _watchlist.list.slice(_loaded,_loaded + LOAD_COUNT);

			var watchpart = JSON.stringify({list:section});

			return Ajax.load(URL, {watchlist:watchpart }, View.onLoad);
		};


		View.onLoad = function (data) {
			// console.log(data);
			View.log('onLoad');
			//console.log(data);

			var $temp = $(data).find(_selector_teaser_grid);
			// console.log($temp);


			$temp.each(function (index) {
				_element_teaser_list.append(this);
			});

			// _element_teaser_list.append($temp);

			LazyImage.init($temp);

			_loaded += LOAD_COUNT;

			View.log('loaded: '+_loaded+' of '+_watchlist.list.length);
			

			if ( _loaded < _watchlist.list.length ) {
				_element_more_button.show();
			} else {
				_element_more_button.hide();
			}
		};
		*/



		View.exit = function (ctx) {
			
			_element_transfer_button.off('click');
			_element_save_list_button.off('click');

			if (_element.length) {
				_element.remove();
				_element = false;
			}

			_current_id = false;
			_list = [];
			_initialized = false;




			State.last_view = name;
		};


		
		// ROUTE CONNECTOR ////////////////////////////////////////////////////
		/**
		 * View.Routes
		 * Provide Methos for Page.js router
		 * Connect your View methods with the router
		 */
		var _routes = {

			// Load and setup any View specific data
			load: function ( ctx, next ) {
				console.groupCollapsed(TYPE+name+' | '+ctx.path);
				
				if (State.is_first_load && next) {
					State.is_first_load = false;
					next();
				} else {
					View.load(ctx).then(
						// success
						function(){
							if (next) next();
						},
						// failed
						function () {
							View.log('):||||||||||||||:( loading error ):||||||||||||||:(');
							ctx.state.error = true;
							if (next) next();
						}
					);
				}
				
			},

			// Add nodes to the Dom, start listeners, setup environment
			init: function ( ctx, next ) {
				//View.log( 'Route.init' );

				// continue
				if (next) next();
			},

			// show (fadeIn) the View;
			show: function ( ctx, next ) {
				
				if(ctx.state.error && next) {
					console.groupEnd();
					next();
				} else {
					View.show();
					console.groupEnd();
					PageChange.enter(ctx);
				}
				
			},

			// Remove nodes from the Dom, stop listeners, setup environment
			exit: function ( ctx, next ) {
				//View.log( 'Route.exit' );
				View.exit();

				PageChange.exit(ctx);

				if (next) next();
			}

		};

		// PUBLIC METHODS /////////////////////////////////////////////////////
		return {
			Routes: _routes
		};
 	}
 );