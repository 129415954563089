// ==========================================================================================================
// ==== OVERSCROLL MODULE ===================================================================================
// ==========================================================================================================

/*
 * @description do something when user scrolls at the end af an element
 * @dependencies jQuery, jQuery.Throttle.Debounce
 * @author Jannis Menzel
 * @version 0.1 22.11.2016
 */

modulejs.define(
    // name
    'module.overscroll',
    // dependencies
    [
        'jQuery',
        'page',
        'TweenMax',
        'TimelineMax',
        'shared.helper',
        'service.config'
    ],
    // constructor
    function ($, page, TweenMax, TimelineMax, Helper, Config) {
        // VIEW NAME //////////////////////////////////////////////////////////
        var name = 'Overscroll';

        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'Module';

        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ',

            _settings = {
                element: {
                    main: false,
                    context: false,
                    indicator: false,
                    mobileNextLink: false,
                    progress: false,
                    headline: false
                },
                state: {
                    enabled: false,
                    atEnd: false,
                    canScroll: false,
                    complete: 0,
                    targetHeight: 0,
                    maxScroll: 0,
                    timeout: 0,
                    link: false
                },
                metrics: {
                    scrollHeight: 0
                },
                listener: {
                    general: function () {
                    },
                    detail: function () {
                    }
                },
                template: {
                    mobileNextLink: '<div class="article-next"><a role="button" href="#">Nächster Beitrag</a></div>',
                    indicator: '<div class="overscroll-indicator"><p class="overscroll-label">Nächster Beitrag</p></div>',
                    headline: '<p class="overscroll-headline"></p>',
                    progress: '<span class="overscroll-progress"></span>'
                },
                class: {
                    base: 'overscroll'
                },
                timeline: false
            },
            _options = {
                element: false,
                teaser: false,
                context: $(window),
                intervalBefore: 250, // scroll listener interval before reaching the end
                intervalAfter: 50, // scroll listener interval when end is reached
                autostart: false, // is it active on initialization
                targetHeight: 'half', // will use half context height
                topic: false
            };

        // PRIVATE METHODS ////////////////////////////////////////////////////
        var Controller = {};

        Controller.log = function (string) {
            console.log(_log + string);
        };

        Controller.logError = function (string) {
            console.error(_log + string);
        };


        // t: current time, b: begInnIng value, c: change In value, d: duration
        Controller.easeInQuad = function (t, b, c, d) {
            return c * (t /= d) * t + b;
        };
        Controller.easeOutQuad = function (t, b, c, d) {
            return -c * (t /= d) * (t - 2) + b;
        };

        // instantiable object
        var Overscroll = function (options) {
            Controller.log('instantiated');

            this.settings = $.extend(true, {}, _settings);

            console.log(this.settings);

            var options = options || {};

            this.options = $.extend(_options, options);

            // check if context is set
            if (this.options.teaser && this.options.teaser.length && this.options.element && this.options.element.length && this.options.context && this.options.context.length) {
                this.settings.element.main = $(this.options.element);
                this.settings.element.main.addClass(this.settings.class.base);
                this.settings.element.context = $(this.options.context);
                this.settings.element.teaser = this.options.teaser;

                this.init();
            } else {
                Controller.logError('Context not found');
            }
        };


        Overscroll.prototype.init = function () {

            Controller.log('init');

            this.setupIndicator();

            this.setupMetrix();
            this.setupListener();

            if (this.options.autostart) {

                Controller.log('canScroll : ' + this.settings.state.canScroll);

                this.enable();
            }

            var self = this;

            $(window).on(Config.EVENT_RESIZE, function () {
                self.setupMetrix();
            });
        };


        Overscroll.prototype.setupIndicator = function () {
            this.settings.element.indicator = $(this.settings.template.indicator);

            var text = this.settings.element.teaser.find('.head').text();
            var url = this.settings.element.teaser.find('.teaser-link').attr('href');
            var link = $('<a class="overscroll-link"><span>' + text + '</span></a>');
            
            this.settings.state.link = url;

            //this.settings.element.headline = $(this.settings.template.headline);
            this.settings.element.progress = $(this.settings.template.progress);

            link.attr('href', url);
            this.settings.element.progress.attr('data-topic-bg', this.options.topic);
            //this.settings.element.headline.append(link);
            //this.settings.element.headline.addClass('topic-text-color-'+this.options.topic);

            /*
             if(text.length > 42) {
             this.settings.element.headline.addClass('small');
             }
             */

            this.settings.element.indicator.append(this.settings.element.headline);
            this.settings.element.indicator.append(this.settings.element.progress);
            this.settings.element.indicator.append(link);

            // topic-text-color-
            // this.settings.element.indicator.append(this.settings.element.teaser);

            this.settings.element.indicator.appendTo(this.settings.element.main);
            
            var self = this;
            link.on('click', function (e) {
                self.triggerSwitch();
                e.preventDefault();
                return false;
            });
            
            // mobile only next article button
            var $articleBackToTop = $('.article-back-to-top').first();
            if ($articleBackToTop.length) {
                var $mobileLink = $(this.settings.template.mobileNextLink);
                $articleBackToTop.after($mobileLink);
                this.settings.element.mobileNextLink = $mobileLink;
    
                var $articleNextLink = this.settings.element.mobileNextLink.find('a');
                
                $articleNextLink.attr('href', url);
                $articleNextLink.on('click', function (e) {
                    self.triggerSwitch();
                    e.preventDefault();
                    return false;
                });
            }
        };


        Overscroll.prototype.enable = function () {

            var scrollTop = this.settings.element.context.scrollTop();
            Controller.log('atempt enable | is enabled: ' + this.settings.state.enabled + ' | canScroll: ' + this.settings.state.canScroll + ' | scrollTop: ' + scrollTop + ' | maxScroll: ' + this.settings.metrics.maxScroll);

            if (this.settings.state.enabled == false) {
                Controller.log('enable');
                if (this.settings.state.canScroll && ( scrollTop + 10 < this.settings.metrics.maxScroll )) {
                    this.startGeneralListener();
                } else {
                    this.startDetailListener();
                }
                this.settings.state.enabled = true;
            }
        };

        Overscroll.prototype.disable = function () {
            if (this.settings.state.enabled) {
                Controller.log('disable');
                this.stopGeneralListener();
                this.stopDetailListener();
                this.stopResetTimeout();

                if (this.settings.state.complete > 0) {
                    TweenMax.to(this.settings.timeline, 0.15, {progress: 0});
                    this.settings.state.complete = 0;
                }

                this.settings.state.enabled = false;
            }
        };

        Overscroll.prototype.destroy = function () {
            //this.settings.state.enabled = false;
            this.settings.element.main.stop();
            this.stopGeneralListener();
            this.stopDetailListener();
            this.stopResetTimeout();
            
            // remove indicator
            this.settings.element.indicator.remove();
            this.settings.element.mobileNextLink.remove();

            //this = null;
        };


        // calculate static values
        Overscroll.prototype.setupMetrix = function () {
            this.settings.metrics.scrollHeight = this.settings.element.context.height();
            this.settings.metrics.outerHeight = this.settings.element.main.outerHeight();
            this.settings.metrics.maxScroll = this.settings.metrics.outerHeight - this.settings.metrics.scrollHeight;

            if (this.options.targetHeight == 'half') {
                this.settings.state.targetHeight = this.settings.metrics.scrollHeight / 2;
            }

            Controller.log('scrollHeight: ' + this.settings.metrics.scrollHeight);
            Controller.log('outerHeight: ' + this.settings.metrics.outerHeight);

            this.settings.state.canScroll = (this.settings.metrics.scrollHeight < this.settings.metrics.outerHeight);

            // Create Animation Timeline
            if (this.settings.timeline) this.settings.timeline.stop().seek(0);
            this.settings.timeline = new TimelineMax({paused: true});
            //this.settings.timeline.fromTo(this.settings.element.main, 1, {scrollTo:0}, {scrollTo:this.settings.state.targetHeight, ease:Sine.easeIn});
            this.settings.timeline.fromTo(this.settings.element.progress, 1, {width: '0%'}, {
                width: '100%',
                ease: Sine.easeInOut
            }, '-=1');
            //this.settings.timeline.fromTo(this.settings.element.headline, 1, {opacity:0}, {opacity:1}, '-=1');
        };


        // create listener function for later reference
        Overscroll.prototype.setupListener = function () {
            this.settings.listener.general = $.throttle(this.options.intervalBefore, this.setupGeneralScroll(this));
            this.settings.listener.detail = $.throttle(this.options.intervalBefore, this.setupDetailScroll(this));
        };


        // start the general scroll listener
        Overscroll.prototype.startGeneralListener = function () {
            Controller.log('startGeneralListener');
            this.settings.element.context.on('scroll', this.settings.listener.general);
        };


        // stop the general scroll listener
        Overscroll.prototype.stopGeneralListener = function () {
            Controller.log('stopGeneralListener');
            this.settings.element.context.off('scroll', this.settings.listener.general);
        };


        // start the detail scroll listener
        Overscroll.prototype.startDetailListener = function () {
            Controller.log('startDetailListener');
            this.settings.element.context.on('mousewheel DOMMouseScroll', this.settings.listener.detail);
        };


        // stop the general scroll listener
        Overscroll.prototype.stopDetailListener = function () {
            Controller.log('stopDetailListener');
            this.settings.element.context.off('mousewheel DOMMouseScroll', this.settings.listener.detail);
        };


        // check if scrolled to end
        Overscroll.prototype.setupGeneralScroll = function (self) {

            return function (event) {

                // a quick fix to disable functionality when the layer is not active
                if (self.settings.element.main.closest('.interactive').hasClass('active') == false) return true;

                // if scrolling down check if end is reached
                if (self.settings.element.context.scrollTop() + 10 >= self.settings.metrics.maxScroll) {
                    self.settings.state.atEnd = true;
                    self.stopGeneralListener();
                    self.startDetailListener();
                }
            }
        };


        // track mousewheel when at end
        Overscroll.prototype.setupDetailScroll = function (self) {

            return function (event) {

                // a quick fix to disable functionality when the layer is not active
                if (self.settings.element.main.closest('.interactive').hasClass('active') == false) return true;

                var direction = (event.originalEvent.detail < 0 || event.originalEvent.wheelDelta > 0) ? -1 : 1;

                // break if scrolling up
                if (direction < 0) {

                    self.settings.state.complete = 0;

                    self.stopDetailListener();

                    TweenMax.to(self.settings.timeline, 0.3, {
                        progress: 0, onComplete: function () {
                            if (self.settings.state.canScroll) {
                                self.startGeneralListener();
                            } else {
                                self.startDetailListener();
                            }

                        }
                    });


                    /*
                     self.settings.element.main.stop().animate({scrollTop:0},300,'swing',function(){
                     self.startGeneralListener();
                     });
                     */

                    return true;
                } else if (self.settings.state.complete < 1) {

                    var value = parseFloat(self.settings.state.complete) + parseFloat(0.15),
                        parsed = value.toFixed(1);

                    if (parsed < 1) {


                        self.settings.state.complete = parsed;

                        //var height = Controller.easeInQuad(self.settings.state.complete, 0, 1, 1) * self.settings.state.targetHeight;

                        //self.settings.element.indicator.css('height',height);

                        //var scrollPos = self.settings.metrics.maxScroll + height;

                        TweenMax.to(self.settings.timeline, 0.3, {progress: self.settings.state.complete});
                        /*
                         Helper.requestAnimationFrame(function(){

                         //self.settings.element.main.stop().animate({scrollTop:height},300,'linear',function(){Controller.log('animation end')});
                         });
                         */


                        //self.startResetTimeout();

                        event.preventDefault();
                        return false;
                    } else {
                        TweenMax.to(self.settings.timeline, 0.2, {progress: 1});

                        self.stopResetTimeout();
                        Controller.log('complete');

                        self.triggerSwitch();
                    }
                }
            }
        };

        Overscroll.prototype.triggerSwitch = function () {
            var self = this;
            TweenMax.to(self.settings.element.indicator, 0.3, {
                y: '85px', onComplete: function () {
                    TweenMax.set(self.settings.element.indicator, {opacity: 0});
                    page.show(self.settings.state.link);
                }
            });
        };

        Overscroll.prototype.startResetTimeout = function () {
            var self = this;
            this.stopResetTimeout();
            this.settings.state.timeout = setTimeout(function () {
                self.settings.state.complete = 0;
                //self.settings.element.main.scrollTop(0);
                self.settings.timeline.stop();
                TweenMax.to(self.settings.timeline, 0.65, {progress: 0});
                // self.settings.element.main.stop().animate({scrollTop:0},500,'swing');
            }, 1000);
        };


        Overscroll.prototype.stopResetTimeout = function () {
            //this.settings.element.indicator.stop();
            clearTimeout(this.settings.state.timeout);
        };

        // PUBLIC METHODS /////////////////////////////////////////////////////
        return Overscroll;
    }
);
