'use strict';


modulejs.define( 'jQuery', function () { jQuery.noConflict(); return jQuery; } );


modulejs.define( 'page', function () { return page; } );
modulejs.define( 'TweenMax', function () { return TweenMax; } );
modulejs.define( 'TimelineMax', function () { return TimelineMax; } );
modulejs.define( 'Cookies', function () { return Cookies.noConflict(); } );
modulejs.define( 'PhotoSwipe', function () { return PhotoSwipe; });
modulejs.define( 'lscache', function () { return lscache; } );
modulejs.define( 'sessioncache', function () { return sessioncache; } );

/**
 * Get all URL Parameters as hash of {key => value}
 * 
 * @param query
 * @returns {{}}
 */
jQuery.fn.urlParseQuery = function (query) {
    /**
     * Origin: http://www.tutorials.de/forum/javascript-ajax/149174-probleme-mit-dem-auslesen-von-variablen.html
     * @returns {Array}
     */
    var HTTP_GET_VARS = {};
    var strGET = query ? query : document.location.search.substr(1, document.location.search.length);
    if (strGET !== '' && typeof strGET === 'string') {
        var gArr = strGET.split('&'), vArr;
        for (var i = 0; i < gArr.length; ++i) {
            vArr = gArr[i].split('=');
            if (vArr.length > 1) {
                
                try {
                    HTTP_GET_VARS[decodeURIComponent(vArr[0])] = decodeURIComponent(vArr[1]);
                } catch(e) {
                    // try to fix latin1 to utf8
                    
                    try {
                        vArr[0] = decodeURIComponent(unescape(vArr[0]));
                        vArr[1] = decodeURIComponent(unescape(vArr[1]));

                        HTTP_GET_VARS[decodeURIComponent(vArr[0])] = decodeURIComponent(vArr[1]);
                    } catch( convertException) {
                        console.error(convertException);
                    }
                    
                }
                
                
            }
        }
    }
    return HTTP_GET_VARS;
};

modulejs.define(
	'baunetzwissen',
	[
		// include dependencies
		'module.debug',
		'controller.initialization',
		'module.menu',
		'settings.route',
		'module.cookieCheck',
		'controller.history',
		'controller.watchlist'
	],
	function ( Debug, Initialization, Menu, Routes, CookieCheck, History, Watchlist ) {

		return {
			start: function () {
				Initialization.init();
				Debug.init();
				CookieCheck.init();
				History.init();
				Watchlist.init();
				Menu.init();
				Routes.init();
			}
		};
	}
);


document.addEventListener( 'DOMContentLoaded', function () {
	console.log( 'START APP' );

	var app = modulejs.require( 'baunetzwissen' );
	app.start();
});

// Um http Cache aktivieren zu können, muss zwischen AJAX und Client Request unterschieden werden, 
// was durch ein Url Parameter funktioniert. Ansonsten wird bereits der X-Requested-With: XMLHttpRequest Header gesendet.
// Grund war das der Browser zwischen AJAX und Seitenaufruf nicht unterschied und beim AJAX Request
// von der Startseite die vollständige Seite liefert aus dem Cache, da der Request laut Url gleich ist. 
(function($) {
	$.ajaxSetup({
	    // Disable caching of AJAX responses
	    // cache: false
        data: {ajax: 1}
	});
}(jQuery));