// ==========================================================================================================
// ==== VIEW HISTORY ========================================================================================
// ==========================================================================================================

/**
 * @description Methods that need to be called on every view
 */

 modulejs.define(
 	// name
 	'view.history',
 	// dependencies
 	[
 		'jQuery',
 		'lscache',
 		'shared.helper',
 		'service.state',
 		'controller.ajax',
 		'controller.meta',
 		'controller.history',
 		'controller.pageChange',
 		'controller.lazyImage',
 		'module.closePage'
 	],
 	// constructor
 	function ( $, lscache, Helper, State, Ajax, Meta, History, PageChange, LazyImage, ClosePage ) {

		// VIEW NAME //////////////////////////////////////////////////////////
		var name = 'History';

		// STATIC VARS ////////////////////////////////////////////////////////
		var TYPE = 'View',
			URL = '/verlauf',
			LOAD_COUNT = 12,
			HTML_DIVIDER = '<div class="columns" aria-hidden="true" data-label=""></div>',
			MONTH_NAMES = ["Januay", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

		// PRIVATE VARS ///////////////////////////////////////////////////////
		var _log = TYPE + '.' + name + ' | ',

			_initialized = false,
			_selector = '[view=history]',
			_selector_container = '[role=main]',

			_selector_list = '.history-list',
			_selector_teaser = '.teaser',
			_selector_teaser_container = '.history-list > .row',
			_selector_teaser_grid = '.history-list > .row > .columns',
			_selector_load_more = '#btn_load_more',

			_selector_head = '.page-title',
			_selector_head_multiple = '.multiple',
			_selector_head_single = '.single',
			_selector_head_counter = '.counter',
			
			_selector_remove_button = '.btn-remove',

			_element = [],
			_element_list = [],
			_element_teaser_list = [],
			_element_current_list = [],
			_element_more_button = [],

			_history = false,

			_load_section = [],
			_loaded = 0,

			_date_now,
			_date_progress = 0,
			_date_label = '',

			_date_values = ['Heute', 'Gestern', 'Diesen Monat'];

		
		// PRIVATE METHODS ////////////////////////////////////////////////////
		var View = {};

		View.log = function ( string ) {
			console.log( _log + string );
		};


		View.init = function () {
			View.log('init');
			
			_loaded = 0;
			_load_section = [];
			_date_progress = 0;
			_date_label = '';

			_element = $(_selector);
			_element_list = $(_selector_list, _element);
			_element_teaser_list = $(_selector_teaser_container, _element);
			_element_current_list = _element_teaser_list;

			_element_more_button = $(_selector_load_more, _element);

			var history = History.get();

			if (history) {
				_history = history;
			} else {
				_history = false;
			}

			// listen for remove button
			_element_list.on('click', _selector_remove_button, View.onRemoveListener);

			if ( !State.cache_disabled && _history ) {

				_element_more_button.on('click', function (event) {

					// load first set off teasers
					View.loadTeaser();

					_element_more_button.hide();
				});

				View.updateCounter();

			} else {
				_element_more_button.hide();
			}
			
			ClosePage.init(_selector);

			Meta.readFrom(_element);

			_initialized = true;
		};


		View.show = function () {
			View.log('show');
			if (!_initialized) View.init();

			// set compare date when loading the page
			_date_now = new Date();
			_date_progress = 0;

			// LazyImage.init(_element);

			if ( !State.cache_disabled && _history ) {
				View.loadTeaser();
			}

			State.current_view = name;
		};


		View.updateCounter = function () {
			var length = _history.list.length,
				label = length + '';

			while ( label.length < 4 ) label = '0' + label;

			// write head counter
			$(_selector_head + ' ' + _selector_head_counter).html(label).show();

			if ( length == 1 ) {
				$(_selector_head + ' ' + _selector_head_single).show();
				$(_selector_head + ' ' + _selector_head_multiple).hide();
			} else if ( length > 1 ) {
				$(_selector_head + ' ' + _selector_head_multiple).show();
				$(_selector_head + ' ' + _selector_head_single).hide();
			}
		};


		View.onRemoveListener = function (event) {
			
			View.log('onRemoveListener');

			var teaser = $(this).closest(_selector_teaser),
				id = teaser.attr('data-id');

			var index = Helper.indexByAttr(_history.list, 0, id);

			View.log('id: ' + id + ' | index: '+index);

			if ( index !== false ) {
				_history.list.splice(index,1);
				
				History.overwrite(_history);
			}
			teaser.parent().remove();
			_loaded -= 1;

			View.updateCounter();
		};

		View.load = function (ctx) {
			
			View.log( 'Route.load' );

			return $.get(ctx.path, function (data) {

				var $temp = $(data).filter(_selector);
                
                $(window).trigger('pageload.event', [data]);

				$(_selector_container).append($temp);

				_element = $temp;
			});
				
		};


		View.loadTeaser = function () {

			View.log('load from '+_loaded+' to '+(_loaded+LOAD_COUNT));

			_load_section = _history.list.slice(_loaded, _loaded+LOAD_COUNT);

			var historypart = JSON.stringify({list: _load_section});

			return Ajax.load(URL, {history:historypart}, View.onLoad);
		};


		View.onLoad = function (data) {
			// console.log(data);
			View.log('onLoad');
			//console.log(data);

			var $temp = $(data).find(_selector_teaser_grid);
			// console.log($temp);


			$temp.each(function (index) {

				View.daysSince(_load_section[index][1]);

				_element_current_list.append(this);
			});

			LazyImage.init($temp);

			_loaded += LOAD_COUNT;

			View.log('loaded: '+_loaded+' of '+_history.list.length);
			
			if ( _loaded < _history.list.length ) {
				_element_more_button.show();
			} else {
				_element_more_button.hide();
			}
		};


		View.daysSince = function (timestamp) {
			
			View.log(timestamp);

			var match_date = new Date(timestamp);

			var dateValue = '';

			// check today
			if ( View.isToday(timestamp) ) {
				View.log('today');

				dateValue = _date_values[0];
			}
			// check yesterday
			else if ( View.isYesterday(timestamp) ) {
				View.log('yesterday');

				dateValue = _date_values[1];
			}
			// check day before yesterday
			else if ( View.isThisMonth(timestamp) ) {
				View.log('this month');

				dateValue = _date_values[2];
			}
			else if ( View.isThisYear(timestamp) ) {
				View.log('isThisYear');

				dateValue = View.dateGetMonth(timestamp);
			}
			// older 
			else {
				View.log('is older');

				dateValue = View.dateGetYear(timestamp);
			}

			if ( _date_label != dateValue ) {
				
				var newList = _element_teaser_list.clone();

				_element_current_list.after(newList);

				_element_current_list = newList;

				var divider = $(HTML_DIVIDER);
				divider.attr('data-label', dateValue);
				divider.html('<p>'+dateValue+'</p>');
				_date_label = dateValue;

				_element_current_list.append( divider );
				
				Helper.requestAnimationFrame(function(){
					var offset = ( State.is_mobile ) ? 60 : 135;
					
					divider.stick_in_parent({
						'parent': newList,
						'offset_top': offset
					});
				});
			}
		};

		View.isToday = function (timestamp) {

			var startOfDay = new Date(_date_now.getFullYear(), _date_now.getMonth(), _date_now.getDate());

			if ( timestamp >= startOfDay.getTime() ) {
				return true;
			} else {
				return false;
			}
		};

		View.isYesterday = function (timestamp) {

			var startOfYesterday = new Date(_date_now.getFullYear(), _date_now.getMonth(), _date_now.getDate() - 1);

			if ( timestamp >= startOfYesterday.getTime() ) {
				return true;
			} else {
				return false;
			}
		};

		View.isThisMonth = function (timestamp) {

			var startOfThisMonth = new Date(_date_now.getFullYear(), _date_now.getMonth(), 1);

			if ( timestamp >= startOfThisMonth.getTime() ) {
				return true;
			} else {
				return false;
			}
		};

		View.isThisYear = function (timestamp) {

			var startOfThisMonth = new Date(_date_now.getFullYear(), 1, 1);

			if ( timestamp >= startOfThisMonth.getTime() ) {
				return true;
			} else {
				return false;
			}
		};


		View.dateGetMonth = function (timestamp) {
			var date = new Date();

			date.setTime(timestamp);

			return MONTH_NAMES[ date.getMonth() ];
		};


		View.dateGetYear = function (timestamp) {
			var date = new Date();

			date.setTime(timestamp);

			return date.getFullYear();
		};

		View.exit = function (ctx) {
			
			if (_element.length) {
				_element_list.off('click', _selector_remove_button, View.onRemoveListener);
				_element.remove();
				_element = false;
			}

			_initialized = false;

			State.last_view = name;
		};


		
		// ROUTE CONNECTOR ////////////////////////////////////////////////////
		/**
		 * View.Routes
		 * Provide Methos for Page.js router
		 * Connect your View methods with the router
		 */
		var _routes = {

			// Load and setup any View specific data
			load: function ( ctx, next ) {
				console.groupCollapsed(TYPE+name+' | '+ctx.path);
				
				if (State.is_first_load && next) {
					State.is_first_load = false;
					next();
				} else {
					View.load(ctx).then(function(){
						if (next) next();
					});
				}
				
			},

			// Add nodes to the Dom, start listeners, setup environment
			init: function ( ctx, next ) {
				//View.log( 'Route.init' );

				// continue
				if (next) next();
			},

			// show (fadeIn) the View;
			show: function ( ctx, next ) {
				
				View.show();

				console.groupEnd();
				PageChange.enter(ctx);
			},

			// Remove nodes from the Dom, stop listeners, setup environment
			exit: function ( ctx, next ) {
				//View.log( 'Route.exit' );
				View.exit();

				PageChange.exit(ctx);

				if (next) next();
			}

		};

		// PUBLIC METHODS /////////////////////////////////////////////////////
		return {
			Routes: _routes
		};
 	}
 );