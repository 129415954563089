// ==========================================================================================================
// ==== MENU MODULE CONTROLLER ==============================================================================
// ==========================================================================================================

modulejs.define(
    // name
    'module.menu',
    // dependencies
    [
        'jQuery',
        'service.config',
        'module.search',
        'module.search.superheader',
        'module.partnerSlider',
    ],
    // constructor
    function ($, Config, Search, SearchSuperheader, PartnerSlider) {

        // VIEW NAME //////////////////////////////////////////////////////////
        var name = 'Menu';


        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'Module';


        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ',

            _selector_toggle = '#menu_toggle',
            _selector_menu = '#menu',
            _selector_wrapper = '.menu-wrapper',
            _selector_imprint_link = '[data-id=imprint_link]',

            _element = [],
            _element_toggle = [],
            _element_wrapper = [],
            _element_imprint_link = [];


        // PRIVATE METHODS ////////////////////////////////////////////////////
        var Controller = {};

        Controller.log = function (string) {
            console.log(_log + string);
        };

        Controller.init = function () {

            _element = $(_selector_menu);
            _element_toggle = $(_selector_toggle, _element);
            _element_wrapper = $(_selector_wrapper, _element);
            _element_imprint_link = $(_selector_imprint_link);


            _element_toggle.on('click', function () {
                _element.toggleClass('active');
            });

            /* Close in pageChange
             _element_wrapper.on('click', 'a', function (event) {
             _element.removeClass('active');
             });
             */

            Search.init();
            SearchSuperheader.init();
            
            PartnerSlider.init($('#baunetz-superheader'));
        };


        Controller.updateTopic = function (topic) {

            var link = Config.IMPRINT_URL;

            if (topic && topic.length > 0) {
                link += '/' + topic;
            }

            _element_imprint_link.attr('href', link);
        };

        Controller.close = function () {
            _element.removeClass('active');
        };

        // PUBLIC METHODS /////////////////////////////////////////////////////
        return {
            init: Controller.init,
            updateTopic: Controller.updateTopic,
            close: Controller.close
        }
    }
);
