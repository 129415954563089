// ==========================================================================================================
// ==== SEARCH MODULE CONTROLLER ============================================================================
// ==========================================================================================================

modulejs.define(
    // name
    'module.search',
    // dependencies
    [
        'jQuery',
        'service.state'
    ],
    // constructor
    function ($, State) {
        // VIEW NAME //////////////////////////////////////////////////////////
        var name = 'Search';


        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'Module',
            AVERAGE_LETTER_WIDTH = 17;


        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ',

            _selector = '#search_field',
            _selector_input = '#searchfield',
            _selector_hint = '.tt-hint',
            _selector_form = 'form',

            _element = [],
            _element_form = [],
            _element_input = [];


        // PRIVATE METHODS ////////////////////////////////////////////////////
        var Controller = {};

        Controller.log = function (string) {
            console.log(_log + string);
        };

        Controller.init = function () {

            Controller.log('init');

            _element = $(_selector);
            _element_input = $(_selector_input, _element);
            _selector_form = $(_selector_form, _element);


            var autocomplete = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.whitespace,
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote: {
                    url: '/suche/vorschlag?q=%QUERY',
                    wildcard: '%QUERY',
                    transform: function (data) {
                        console.log(data);
                        return $.map(data, function (val, index) {
                            return {
                                value: val
                            }
                        });
                    }
                }
            });

            _element_input.typeahead(null, {

                hint: true,
                highlight: true,
                minLength: 2,
                // name: 'autocomplete',
                display: 'value',
                source: autocomplete,
                limit: 100
            }).on('typeahead:select', function (event, value) {
                //_element_form.submit(); - produces javascript error
                //Controller.log('select '+ value.value);
            });


            _element_input
                .focus(function () {
                    $('.underscore').addClass("blinking");
                }).focusout(function () {
                $('.underscore').removeClass("blinking");
            });


            if (State.is_desktop) {

                if (window.location.hash == '#focus') {
                    _element_input.focus();
                }

                _element_input.on("change paste keyup", function (event) {
                    //Controller.log('anything');

                    var query = _element_input.val();
                    var queryLength = query.length;
                    var transformValue = AVERAGE_LETTER_WIDTH * queryLength;
                    var inputWidth = $(this).width();
                    if (transformValue <= inputWidth) {
                        $('.tt-open').css({
                            transform: "translate(" + transformValue + "px)",
                            "-ms-transform": "translate(" + transformValue + "px)",
                            "-webkit-transform": "translate(" + transformValue + "px)"
                        });
                    } else {
                        $('.tt-open').css({
                            transform: "translate(" + inputWidth + "px)",
                            "-ms-transform": "translate(" + inputWidth + "px)",
                            "-webkit-transform": "translate(" + inputWidth + "px)"
                        });
                    }
                });
            }


            $('#search_mobile_toggle').on('click', function () {
                $('#search_field').toggleClass('active');
                $('#menu').removeClass('active');
                $('.submit-search-btn').removeClass('icon-search');
                return false;
            });

            $('.icon-close').on('click', function () {
                $('#search_field').removeClass('active');
            });

        };


        // PUBLIC METHODS /////////////////////////////////////////////////////
        return {
            init: Controller.init
        }
    }
);
