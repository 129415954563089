// ==========================================================================================================
// ==== EYE CATCHER MODULE CONTROLLER ============================================================================
// ==========================================================================================================

modulejs.define(
    // name
    'module.eyeCatcher',
    // dependencies
    [
        'jQuery',
        'TweenMax',
        'TimelineMax',
        'service.state'
    ],
    // constructor
    function ($, TweenMax, TimelineMax, State) {
        // VIEW NAME //////////////////////////////////////////////////////////
        var name = 'EyeCatcher';


        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'Module';


        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ',

            _selector = '.eye-catcher',
            _selector_placeholder = '.eye-catcher-placeholder',
            _selector_text_bubble = '.text-bubble',
            _selector_large_bubble = '.large-bubble',
            _selector_small_bubble = '.small-bubble',
            _selector_bubble_content = '.bubble-content',
            _selector_hidden = '.hidden-when-closed',
            _selector_close_bubble = '.close-bubble',
            _selector_container = '[view=topic]',

            _element = [],
            _element_placeholder = [],
            _element_text_bubble = [],
            _element_large_bubble = [],
            _element_small_bubble = [],
            _element_bubble_content = [],
            _element_hidden = [],
            _element_close_bubble = [],

            _bubbleAnimation = null,

            _initialized = false;

        // PRIVATE METHODS ////////////////////////////////////////////////////
        var Controller = {};

        Controller.log = function (string) {
            console.log(_log + string);
        };

        Controller.init = function (element) {

            if (!element || !element.length) return false;

            Controller.log('init');

            _element = $(element);

            $(_selector_container).append(_element);


            _element_placeholder = $(_selector_placeholder, _element);
            _element_bubble_content = $(_selector_bubble_content, _element);
            _element_close_bubble = $(_selector_close_bubble, _element);

            // Controller.update('fliesen-und-platten', 'Test Text!', 'http://api.jquery.com/attr/'); // test content

            _element_text_bubble = $(_selector_text_bubble, _element);
            _element_large_bubble = $(_selector_large_bubble, _element);
            _element_small_bubble = $(_selector_small_bubble, _element);
            _element_hidden = $(_selector_hidden, _element);

            _bubbleAnimation = new TimelineMax();

            _bubbleAnimation.add(TweenLite.fromTo(_element_hidden, 0.1, {autoAlpha: 0}, {autoAlpha: 0, display: 'block'}))
                .add(TweenLite.to(_element_hidden, 0.1, {autoAlpha: 1}), 1.9)
                .add(TweenLite.from(_element_small_bubble, 0.2, {height: 0, width: 0}, {ease: Power3.easeInOut}), 2)
                .add(TweenLite.from(_element_large_bubble, 0.4, {height: 0, width: 0}, {ease: Power3.easeInOut}), 2.1)
                .set(_element_text_bubble, {height: '15px', width: '15px'}, 2.45)
                .add(TweenLite.to(_element_text_bubble, 0.5, {
                    height: '100px',
                    width: '200px'
                }, {ease: Power3.easeInOut}), 2.45)
                .add(TweenLite.to(_element_bubble_content, 0.2, {opacity: 1}))
                .set(_element_close_bubble, {display: 'block'});

            var showIcon = function () {
                _element_close_bubble.addClass('icon-close');
            };

            var showCloseBubble = function () {
                TweenLite.to(_element_close_bubble, 0.2, {height: '23px', width: '23px', onComplete: showIcon});
            };

            // var hideIcon = function () {
            //     _element_close_bubble.removeClass('icon-close');
            // };
            //
            // var hideCloseBubble = function() {
				// TweenLite.to( _element_close_bubble , 0.2, {height: 0, width: 0, onStart: hideIcon});
            // };
            //
            // if (State.is_tablet) {
            //     showCloseBubble();
            // } else {
            //     _element.on('mouseenter', showCloseBubble).on('mouseleave', hideCloseBubble);
            // }
            //
            // _element.on('mouseenter', showCloseBubble).on('mouseleave', hideCloseBubble);

            showCloseBubble();

            Controller.close();

            _element_placeholder.on('mouseenter', function () {
                TweenLite.set(_element_placeholder, {display: 'none'});
                _bubbleAnimation.play(3.1);
            });

            setTimeout(Controller.play, 3000);

            _initialized = true;
        };

        Controller.play = function () {
            if (_bubbleAnimation) {
                _bubbleAnimation.play(3.1);
                _bubbleAnimation.call(function() {
                    setTimeout(function() {
                        Controller.closeBubble()
                    }, 12000); //schliessen nach 8s
                })
            }
        };

        /**
         * Eyecatcher schliessen - Animation rückwärts ausführen
         */
        Controller.closeBubble = function() {
            if (_bubbleAnimation) {
                _bubbleAnimation.reverse();
                TweenLite.set(_element_placeholder, {display: 'block'});
            }
        };

        Controller.close = function () {

            _element_close_bubble.on('click', function () {
                Controller.closeBubble();
            });
        };

        Controller.exit = function () {

            if (!_initialized) return false;

            Controller.log('exit');

            _element.off('mouseenter');
            _element.off('mouseleave');

            _element_close_bubble.off('click');

            _element_placeholder.off('mouseenter');

            _bubbleAnimation = null;

            _element.remove();
            _element = [];
            _element_placeholder = [];
            _element_text_bubble = [];
            _element_large_bubble = [];
            _element_small_bubble = [];
            _element_bubble_content = [];
            _element_hidden = [];
            _element_close_bubble = [];
            _initialized = false;
        };


        // PUBLIC METHODS /////////////////////////////////////////////////////
        return {
            init: Controller.init,
            //update: Controller.update,
            close: Controller.close,
            exit: Controller.exit
        }
    }
);
