// ==========================================================================================================
// ==== PAGECHANGE CONTROLLER ===============================================================================
// ==========================================================================================================

// @description Functions that are required on every page change. Called by the Router before the actual View is called and on exit after the Views exit function.

modulejs.define(
    // name
    'controller.pageChange',
    // dependencies
    [
        'jQuery',
        'service.config',
        'service.state',
        'controller.watchlist',
        'module.menu',
        'module.partnerSlider',
        'Cookies'
    ],
    // constructor
    function ($, Config, State, Watchlist, Menu, PartnerSlider, Cookies) {
        // CONTROLLER NAME ////////////////////////////////////////////////////
        var name = 'PageChange';

        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'Controller';

        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ',
            _selector_main = '[role=main]',
            _element_main = $(),

            _initialized = false;

        // PRIVATE METHODS ////////////////////////////////////////////////////
        var Controller = {};

        Controller.log = function (string) {
            console.log(_log + string);
        };

        Controller.init = function () {
            _element_main = $(_selector_main);

            _initialized = true;
        };

        /**
         * Preview sets ?preview=1 on all links 
         */
        Controller.handlePreview = function() {
            
            if ('URLSearchParams' in window) {
                var urlParams = new URLSearchParams(window.location.search);
                if (urlParams.get('preview') === '1') {
                    
                    // set dev cookie anyway 
                    Cookies.set('x-dev', '1');
                    
                    $('a[href]').not('[target="_blank"]').each(function() {
                        var url = $(this).attr('href');
                        if (url.indexOf('preview=1') < 0) {
                            url += url.indexOf('?') < 0 ? '?' : '&';
                            url += 'preview=1';
                            $(this).attr('href', url);
                        }
                    });
                    
                }
            }
            
        };
        
        Controller.enterRoute = function (ctx) {
            Controller.log('enter route');
            
            Controller.handlePreview();
            
            //check for Screenshot
            if ('URLSearchParams' in window) {
                var urlParams = new URLSearchParams(window.location.search);
                if (urlParams.get('screenshot') === '1') {
                    if (!$('head #css-screenshot').length) {
                        $('head').append('<link id="css-screenshot" href="/css/screenshot.css" rel="stylesheet" type="text/css">');
                    }

                    var bgColor = $('.topic-background').css('background-color');
                    if (bgColor) {
                        $('body').css({
                            background: 'url(/assets/gfx/bg_dots_transparent.png) 0 0 repeat ' + bgColor
                        });
                        modulejs.require('controller.lazyImage').loadAll();
                    }
                }
            }

            $('[role="main"]').removeClass('loading');

            // Destroy Topic Route
            if (State.is_topic_route && State.current_view != 'Topic') {
                State.topicDestroy();
            }

            // Destroy Glossary Route
            if (State.is_glossary_route && State.current_view != 'Glossary') {
                State.glossaryDestroy();
            }

            // if current route has a topic update menu topic
            if (( State.current_topic && State.current_topic.length ) || ( State.current_search_topic && State.current_search_topic.length )) {
                Menu.updateTopic(State.current_topic);
            } else {
                Menu.updateTopic(false);
            }

            Menu.close();

            var filter = false;

            if (State.partner_filter_topic && State.partner_filter_topic.length) {
                filter = State.partner_filter_topic;
            } else if (State.current_topic && State.current_topic.length) {
                filter = State.current_topic;
            } else if (State.current_glossary_topic) {
                filter = State.current_glossary_topic;
            }

            State.current_pathname = ctx.pathname;

            PartnerSlider.filter(filter);

            //var check = $.extend({},State);

            console.log(State.current_article + '_');

            $(window).trigger(name, State);
            
            // Reset des status von selectFirstTeaser (siehe controller.topicTeaser) 
            State.is_selected_first_teaser = false;
        };


        Controller.exitRoute = function (ctx) {
            Controller.log('exit route ' + ctx.path);

            $('[role="main"]').addClass('loading');
            
            State.referrer = window.location.href;

            Watchlist.disable();
        };


        Controller.startTransition = function () {
            _element_main.addClass('animating');
        };


        Controller.stopTransition = function () {
            _element_main.removeClass('animating');
        };

        // PUBLIC METHODS /////////////////////////////////////////////////////
        return {
            init: Controller.init,
            enter: Controller.enterRoute,
            exit: Controller.exitRoute,
            startTransition: Controller.startTransition,
            stopTransition: Controller.stopTransition
        };
    }
);