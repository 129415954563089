// ==========================================================================================================
// ==== VIEW EXCEPTION ======================================================================================
// ==========================================================================================================

/**
 * @description Catchall for non matched routes (404). Or if there were loading errors in the matched route.
 * 				Disables page.js to make shure we have a clean reload afterwards.
 */

 modulejs.define(
 	// name
 	'view.exception',
 	// dependencies
 	[
 		'page'
 	],
 	// constructor
 	function ( page ) {

		// VIEW NAME //////////////////////////////////////////////////////////
		var name = 'Exception';

		// STATIC VARS ////////////////////////////////////////////////////////
		var TYPE = 'View';

		// PRIVATE VARS ///////////////////////////////////////////////////////
		var _log = TYPE + '.' + name + ' | ';

		
		// PRIVATE METHODS ////////////////////////////////////////////////////
		var View = {};

		View.log = function ( string ) {
			console.error( _log + string );
		};


		View.show = function (ctx) {
			// View.log('catched error');
			
			page.stop();

			window.location.reload();
		};




		
		// ROUTE CONNECTOR ////////////////////////////////////////////////////
		/**
		 * View.Routes
		 * Provide Methos for Page.js router
		 * Connect your View methods with the router
		 */
		var _routes = {

			// show (fadeIn) the View;
			show: function ( ctx, next ) {
				View.show(ctx);
			}

		};

		// PUBLIC METHODS /////////////////////////////////////////////////////
		return {
			Routes: _routes
		};
 	}
 );