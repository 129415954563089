(function (window, modulejs) {

    //Vgl. mit http://photoswipe.com/documentation/getting-started.html
    modulejs.define(
        // name
        'module.photoswipe',
        // dependencies
        [
            'jQuery',
            'PhotoSwipe'
        ],
        function ($, PhotoSwipe) {
            // VIEW NAME //////////////////////////////////////////////////////////
            var name = 'PhotoSwipe';

            // STATIC VARS ////////////////////////////////////////////////////////
            var TYPE = 'Controller';

            // PRIVATE VARS ///////////////////////////////////////////////////////
            var _log = TYPE + '.' + name + ' | ',
                _selector_gallery = '.pswp',
                _selector_title = '.article-headline-pswp',
                _selector_caption = 'figcaption',
                _selector_link = '.pswp-link',
                _cache = {},

                _gallery = false,

                _just_opened = false,
                _initialized = false;

            // PRIVATE METHODS ////////////////////////////////////////////////////
            var Controller = {};

            Controller.log = function (string) {
                console.log(_log + string);
            };

            /**
             * Galerie Items initialisieren
             * @param container
             * @param item
             * @param articleId
             */
            Controller.init = function (container, item, articleId) {

                Controller.log('Init | selector: ' + item);

                // multiple galleries by data-gallery-id
                var $galleries = $(container).find('[data-gallery-id]');
                if (!$galleries.length) return;
                
                $galleries.each(function() {
                    var $container = $(this);
                    
                    // count galleries
                var galleryId = Object.keys(_cache).length;

                var galleryObj = {
                    id: 'gallery-' + (Object.keys(_cache).length + 1),
                    container: $(container),
                    items: []
                };

                // collect gallery items
                    $(item, $container).each(function (index) {
                    // collect info from item
                    var $link = $(_selector_link, this),
                        data = {
                            w: $link.attr('data-width'),
                            h: $link.attr('data-height'),
                            src: $link.attr('href'),
                            title: $(_selector_caption, this).html(),
                            img: $('img', $link).get(0),
                            elem: $('img', $link)
                        };

                    // store index on link
                    $link.attr('data-index', index);

                    // store gallery id on link
                    $link.attr('data-gallery', galleryId);

                    // add item data to gallery list
                    galleryObj.items.push(data);
                });

                // store gallery info in _cache
                if (!_cache[articleId]) {
                    _cache[articleId] = [];
                }
                _cache[articleId].push(galleryObj);

                // catch all item link clicks in gallery container
                /*
                 $(_selector_link, galleryObj.container).on('click', function (event) {

                 Controller.log('Link Clicked');

                 var index = parseInt($(this).attr('data-index')),
                 galleryId = parseInt($(this).attr('data-gallery'));

                 Controller.openPhotoSwipe(galleryId, index);

                 event.preventDefault();

                 return false;
                 });
                 */
                });

                _initialized = true;
            };

            /**
             * Controller Variablen zurücksetzen
             */
            Controller.destroy = function (articleId) {
                Controller.log('destroy');

                if (_initialized) {
                    //_gallery.destroy();
                    _gallery = false;
                    _cache[articleId] = [];
                    delete _cache[articleId];
                    $(_selector_gallery).first().off('click.pswp');
                }

                _initialized = false;
            };

            /**
             * Galerie öffnen
             * @param gallery
             * @param image
             * @param articleId
             */
            Controller.openPhotoSwipe = function (gallery, image, articleId) {
                
                var galleryId = parseInt(gallery, 10) - 1;
                var index = parseInt(image, 10) - 1;

                Controller.log('Open | ' + galleryId + ' | index: ' + index);

                var pswp = $(_selector_gallery).first();

                //Vgl. mit http://photoswipe.com/documentation/options.html
                var options = {
                    history: false,

                    closeEl: true,
                    captionEl: true,
                    fullscreenEl: true,
                    zoomEl: false, //Zoom deaktiviert (stattdessen 1/3, 2/3 Klickstrecken)
                    shareEl: false,
                    counterEl: true,
                    arrowEl: true,
                    preloaderEl: true,

                    barsSize: {top: 44, bottom: 130},

                    maxSpreadZoom: 1,
                    bgOpacity: 0.95,
                    galleryUID: galleryId,
                    closeElClasses: ['caption', 'ui'], //Schliessen nur bestimmten Flächen gestatten (https://github.com/dimsemenov/PhotoSwipe/issues/762)

                    getThumbBoundsFn: function (index) {
                        if (typeof _cache[articleId] !== 'undefined') {
                            var thumbnail = _cache[articleId][galleryId].items[index].elem.get(0);

                            // console.log(_cache[galleryId].items[index]);

                            var pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
                            var rect = thumbnail.getBoundingClientRect();
                            var offset = -1 * parseInt($(_selector_gallery).css('top'), 10);

                            return {x: rect.left, y: rect.top + pageYScroll + offset, w: rect.width};
                        }
                    },

                    getNumItemsFn: function () {
                        return _cache[articleId][galleryId].items.length;
                    },

                    index: index
                };

                Controller.log('New | ' + galleryId);
                _just_opened = true;

                // initialize photoswipe gallery
                _gallery = new PhotoSwipe(pswp.get(0), PhotoSwipeUI_Default, $.extend({}, _cache[articleId][galleryId].items), options);
                
                (function () {
                    //Artikeltitel übernehmen
                    var title = $('[article-module="headline"]').text();
                    var subTitle = $('[article-module="subheadline"]').text();
                    var $titleContainer = $(_selector_title);
                    $titleContainer.find('.title').text(title);
                    $titleContainer.find('.subtitle').text(subTitle);
                })();

                /*
                 * Click auf linke oder rechte Hälfte NEBEN dem Bild ist wie ein Klick auf den next- prev Button
                 */
                $(pswp.get(0)).off('click.pswp');
                $(pswp.get(0)).on('click.pswp', function (e) {
                    //nicht 2mal weiternavigieren beim Button
                    if ($(e.target).is('button')) {
                        return;
                    }

                    var width = $('body').width();

                    //40% links vorheriges Bild zeigen - rechts nächstes Bild (vgl. mit baunetz.de)
                    if (e.pageX < (width * 0.4)) {
                        _gallery.prev();
                    } else {
                        _gallery.next();
                    }
                });

                _gallery.listen('close', function () {
                    $(window).trigger('pswp-close');
                });

                _gallery.listen('afterChange', function () {
                    if (!_just_opened) {
                        $(window).trigger('pswp-change', _gallery.getCurrentIndex());
                    } else {
                        _just_opened = false;
                    }

                });

                _gallery.init();
            };

            /**
             * Schliessen der Galerie
             */
            Controller.closePhotoSwipe = function () {

                if (_gallery) {
                    _gallery.close();
                    _gallery = false;
                }
            };


            /**
             * Ist die Galerie aktiv?
             * 
             * @returns {boolean}
             */
            Controller.isOpen = function () {
                return !!_gallery;
            };

            // PUBLIC METHODS /////////////////////////////////////////////////////
            return {
                init: Controller.init,
                show: Controller.openPhotoSwipe,
                close: Controller.closePhotoSwipe,
                destroy: Controller.destroy,
                isOpen: Controller.isOpen
            };
        }
    );

})(window, modulejs);

