// ==========================================================================================================
// ==== WATCHLIST CONTROLLER ================================================================================
// ==========================================================================================================

modulejs.define(
    // name
    'controller.watchlist',
    // dependencies
    [
        'jQuery',
        'Cookies',
        'lscache',
        'shared.helper',
        'service.config',
        'service.state'
    ],
    // constructor
    function ($, Cookies, lscache, Helper, Config, State) {
        // VIEW NAME //////////////////////////////////////////////////////////
        var name = 'Watchlist';

        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'Controller';

        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ',

            _cache_key_list = 'watchlist',
            _cache_key_shared = 'saved-watchlists',

            _selector_counter = '#watchlist_counter',
            _selector_trigger = '#watchlist_trigger',
            _selector_article_trigger = '.watchlist-trigger',
            _selector_disabled = '#watchlist_disabled',
            _selector_label = '#watchlist_label',

            _attr_text_color = 'data-topic-hover-text-color',

            _element_counter = [],
            _element_trigger = [],
            _element_disabled = [],

            _candidate = false,
            _enabled = false,
            _initialized = false;

        // PRIVATE METHODS ////////////////////////////////////////////////////
        var Controller = {};


        Controller.log = function (string) {
            console.log(_log + string);
        };


        Controller.init = function () {

            _element_counter = $(_selector_counter);
            _element_trigger = $(_selector_trigger);

            if (State.cache_disabled) {

                _element_trigger.addClass('disabled');
                $(_selector_disabled).show();
                $(_selector_label).addClass('disabled');

            } else {
                _element_trigger.addClass('checked');

                Controller.log('init');

                _initialized = true;

                var watchlist = Controller.getWatchlist();

                _element_counter.on(Helper.whichAnimationEnd(), function (e) {
                    $(this).removeClass('updating');
                });

                // delegated click on any trigger
                $('body').on('click', _selector_article_trigger, Controller.onTriggerClick);

                // menu trigger click
                _element_trigger.on('click', Controller.onTriggerClick);

                Controller.updateCounter(watchlist);
            }
        };


        Controller.checkFormat = function (list) {
            if (list.list.length && list.list[list.list.length - 1].articleId) {
                alert('Watchlist Cache Outdated');

                return true;
            }

            return false;
        };

        Controller.onTriggerClick = function (event) {
            if (_enabled && _candidate) {

                Controller.add(_candidate);

                Controller.disable();
                _element_trigger.addClass('checked');
                $(this).addClass('checked');
            }

            return false;
        };

        // adds a new entry to the beginning of the list
        Controller.add = function (item) {

            if (!_initialized) return;

            Controller.log('add');

            var watchlist = lscache.get('watchlist');

            if (watchlist && watchlist.list) {
                watchlist.list.unshift(item);

                Controller.updateCache(watchlist);
                Controller.updateCounter(watchlist);
            }
        };


        Controller.addList = function (list) {

            if (!_initialized) return;

            var watchlist = Controller.getWatchlist();

            for (var i = 0, l = list.length; i < l; i++) {
                var item = list[i];

                var exists = Controller.checkExistens(item[0], watchlist.list);

                Controller.log('addList | check | ' + item[0] + ' | ' + exists);

                if (exists === false) {
                    Controller.add(item);
                }
            }
            ;
        };

        // tell watchlist there is a potential candidate to be appended to the list
        // checks if it allready is added and if not enables the add to watchlist buttons
        // stores the candidate for potential later insertion
        Controller.enable = function (entry) {

            if (!_initialized) return;

            var watchlist = Controller.getWatchlist();

            var exists = Controller.checkExistens(entry[0], watchlist.list);

            Controller.log('is in watchlist ' + exists);
            console.log(entry);

            if (exists === false) {
                _enabled = true;
                _candidate = entry;
                _element_trigger.removeClass('checked');

                // enable posible article trigger buttons
                $(_selector_article_trigger).removeClass('checked');

                // update hover color
                if (entry && entry.articleId && entry.topic) {
                    _element_trigger.attr(_attr_text_color, entry.topic);
                }
            } else {

                // enable menu button
                _element_trigger.addClass('checked');

                $(_selector_article_trigger).addClass('checked');
            }

            return exists;

        };


        Controller.disable = function () {

            if (!_initialized) return;

            _enabled = false;
            _candidate = false;

            // disable menu trigger
            _element_trigger.addClass('checked');
            // reset hover color
            _element_trigger.attr(_attr_text_color, '');

            // disable posible article trigger buttons
            $(_selector_article_trigger).addClass('checked');

        };


        Controller.reset = function () {

            if (!_initialized) return;

            var watchlist = {};
            watchlist.list = [];

            Controller.updateCache(watchlist);
            Controller.updateCounter(watchlist);

            return watchlist;
        };


        // read watchlist cache or create an empty one
        Controller.getWatchlist = function () {

            if (State.cache_disabled) return {list: []};

            var watchlistCache = lscache.get('watchlist'),
                watchlist;

            if (watchlistCache == null || watchlistCache == 'undefined' || watchlistCache.list == 'undefined' || Controller.checkFormat(watchlistCache)) {
                watchlist = Controller.reset();
            } else {
                // watchlist = JSON.parse(watchlistCache);
                watchlist = watchlistCache;
            }

            return watchlist;
        };


        Controller.updateCache = function (watchlist) {
            if (!_initialized) return;

            lscache.set('watchlist', watchlist);
        };


        Controller.updateCounter = function (watchlist) {

            var count = watchlist.list.length;

            if (!count) count = '-';

            _element_counter.addClass('updating');

            _element_counter.text(count);
        };


        Controller.overwrite = function (watchlist) {
            Controller.updateCache(watchlist);
            Controller.updateCounter(watchlist);
        };


        Controller.isEnabled = function () {
            return _enabled;
        };


        Controller.saveSharedList = function (id) {

            if (!_initialized) return;

            var savedLists = Controller.getSharedLists();

            var check = Controller.checkExistens(id, savedLists);

            // not in the list yet?
            if (!check) {
                savedLists.list.push([id]);
                Controller.updateSavedCache(savedLists);
            }
        };


        Controller.getSharedLists = function (id) {
            if (State.cache_disabled) return {list: []};

            var savedCache = lscache.get(_cache_key_shared);

            if (savedCache == null || savedCache.list == 'undefined') {
                var newSavedLists = {};
                newSavedLists.list = [];
                Controller.updateSavedCache(newSavedLists);

                return newSavedLists;
            } else {
                return savedCache;
            }
        };


        Controller.updateSavedCache = function (sharedList) {
            if (!_initialized) return;

            lscache.set(_cache_key_shared, sharedList);
        };


        Controller.checkExistens = function (id, list) {

            var found = false;

            for (var i = list.length - 1; i >= 0; i--) {
                if (list[i][0] == id) {
                    found = true;
                    break;
                }
            }

            return found;
        };

        // PUBLIC METHODS /////////////////////////////////////////////////////
        return {
            init: Controller.init,
            enable: Controller.enable,
            disable: Controller.disable,
            get: Controller.getWatchlist,
            reset: Controller.reset,
            overwrite: Controller.overwrite,
            isEnabled: Controller.isEnabled,
            checkExistens: Controller.checkExistens,
            addList: Controller.addList,

            // shared
            saveSharedList: Controller.saveSharedList,
            getSharedLists: Controller.getSharedLists
        }
    }
);
