// ==========================================================================================================
// ==== CONFIG SERVICE ======================================================================================
// ==========================================================================================================

/**
 * @description state service provider
 */

modulejs.define('service.config', function () {
    return {

        base_url: '',

        IMPRINT_URL: '/impressum',

        GLOSSARY_URL: '/glossar',

        WATCHLIST_SAVE_URL: '/merkliste/save',

        WATCHLIST_SHARED_BASE_URL: '/merkliste/',

        MOBILE_BREAKPOINT: 720,

        TABLET_BREAKPOINT: 1200,
        
        DESKTOP_BREAKPOINT: 1600,

        ULTRA_BREAKPOINT: 2200,

        PLACEHOLDER_IMAGE: '/assets/gfx/placeholder.png',

        COOKIE_EXPIRES: 3652.5, //in days

        CACHE_EXPIRES: 60 * 24 * 365.25 * 10, //in minutes

        DEBUG: true,

        EVENT_RESIZE: 'app_event_resize'

    };
});