// ==========================================================================================================
// ==== STATE SERVICE =======================================================================================
// ==========================================================================================================

/**
 * @description state service provider
 */

modulejs.define('service.state', function () {
    return {
        is_first_load: true,

        // estimated on window size
        is_mobile: false,
        is_tablet: false,
        is_desktop: false,
        is_ultra: false,

        is_menu: false,

        is_topic_route: false,
        is_glossary_route: false,
        is_search_route: false,
        
        // select first article of topic 
        is_selected_first_teaser: false,

        // exclusive for topic routes
        current_topic: false,
        current_section: false,
        current_selection: false,
        current_article: false, // article slug
        current_gallery: false, // gallery id (index)
        current_glossary_layer: false, // glossary layer shown in topic article
        
        // store scroll position when going from topic article to glossary
        article_scroll_position: {
            id: false,
            top: 0
        },

        current_article_instance: false, // article object

        // exclusive for glossary rotes
        current_glossary_topic: false,
        current_glossary_article: false,
        current_glossary_gallery: false,

        // exclusive for search route
        // Filter nach Thema
        current_search_topic: false,
        // Filterrubrik
        current_search_selection: false,
        // Trefferanzahl
        current_search_count: false,
        // Seite der Trefferliste
        current_search_page: false,
        // Anzahl aller Seiten der Trefferliste
        current_search_page_counter: false,
        
        last_view: '',
        referrer: '', // last route visited - manual change on page change  

        partner_filter_topic: false,

        // target for close button defaults to landing page
        last_content_route: '/',

        cookies_disabled: false,
        cache_disabled: false,
        
        context: {},

        topicDestroy: function () {
        },
        glossaryDestroy: function () {
        }

    };
});