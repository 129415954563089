// ==========================================================================================================
// ==== SEARCH MODULE CONTROLLER ============================================================================
// ==========================================================================================================

modulejs.define(
    // name
    
    'module.search.superheader',
    // dependencies
    [
        'jQuery',
            'service.state'
    ],
    // constructor
    function ($, State) {
        // VIEW NAME //////////////////////////////////////////////////////////
        var name = 'SearchSuperheader';


        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'Module';


        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ',
            
            _selector = '#baunetz-superheader',
            _selector_input = '.bn-search-button input',
            _selector_hint = '.tt-hint',
            _selector_form = 'form',

            _element = [],
            _element_form = [],
            _element_input = [];


        // PRIVATE METHODS ////////////////////////////////////////////////////
        var Controller = {};

        Controller.log = function (string) {
            console.log(_log + string);
        };
        
        Controller.init = function () {

            Controller.log('init');

            _element = $(_selector);
            _element_input = $(_selector_input, _element);
            _selector_form = $(_selector_form, _element);
            

            // Type Suggestion Query
            var autocomplete = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.whitespace,
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote: {
                    url: '/suche/vorschlag?q=%QUERY',
                    wildcard: '%QUERY',
                    transform: function (data) {
                        // console.log(data);
                        return $.map(data, function (val, index) {
                            return {
                                value: val
                            }
                        });
                    }
                }
            });

            // Type Suggestion Tool 
            _element_input.typeahead(null, {

                hint: true,
                highlight: true,
                minLength: 2,
                // name: 'autocomplete',
                display: 'value',
                source: autocomplete,
                limit: 100
            }).on('typeahead:select', function (event, value) {
                //_element_form.submit(); - produces javascript error
                //Controller.log('select '+ value.value);
            });

            if (State.is_desktop) {

                if (window.location.hash == '#focus') {
                    _element_input.focus();
                }

                // https://www.geeksforgeeks.org/calculate-the-width-of-the-text-in-javascript/
                function getTextWidth(input, content) {
                    if (!input) return 0;
                    if (content === '') return 0;
                    
                    var text = document.createElement("span");
                    document.body.appendChild(text);

                    var styles = window.getComputedStyle(input);
                    
                    text.style.fontFamily = styles.fontFamily
                    text.style.fontSize = styles.fontSize;
                    text.style.width = 'auto';
                    text.style.position = 'absolute';
                    text.style.left = '-200vw';
                    text.innerHTML = content;

                    var width = Math.ceil(text.clientWidth);
                    document.body.removeChild(text);
                    return width;
                } 
                
                _element_input.on("change paste keyup", function (event) {
                    //Controller.log('anything');

                    var query = _element_input.val();
                    // var queryLength = query.length;
                    // var transformValue = AVERAGE_LETTER_WIDTH * queryLength;
                    var inputWidth = $(this).width();
                    // Buchstaben nicht einheitlich breit, berechnen
                    var transformValue = getTextWidth(_element_input.get(0), query);
                    
                    if (transformValue <= inputWidth) {
                        $('.tt-open').css({
                            transform: "translate(" + transformValue + "px)"
                        });
                    } else {
                        $('.tt-open').css({
                            transform: "translate(" + inputWidth + "px)"
                        });
                    }
                });
            }
            
            function openMobileSearch() {
                $('#search_field').toggleClass('active');
                $('#menu').removeClass('active');
                $('.submit-search-btn').removeClass('icon-search');
            }
            
/*
            // Superheader mobile Suchoverlay öffnen
            $('.bn-search-button', _selector).on('click', function() {
                if (!'matchMedia' in window) return;
                if (window.matchMedia('(min-width: 768px)').matches) return;

                // viewport width <= 767px
                openMobileSearch();
            });
*/

            $('.icon-close').on('click', function () {
                $('#search_field').removeClass('active');
            });
        };


        // PUBLIC METHODS /////////////////////////////////////////////////////
        return {
            init: Controller.init
        }
    }
);
