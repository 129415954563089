// ==========================================================================================================
// ==== TAXONOMY MENU CONTROLLER ============================================================================
// ==========================================================================================================

modulejs.define(
	// name
	'controller.taxonomyMenu',
	// dependencies
	[
		'jQuery',
		'shared.helper',
		'service.config',
		'service.state',
		'controller.ajax',
		'module.eyeCatcher',
        'module.welcome'
	],
	// constructor
	function ( $, Helper, Config, State, Ajax, EyeCatcher, Welcome ) {
		// VIEW NAME //////////////////////////////////////////////////////////
		var name = 'TaxonomyMenu';

		// STATIC VARS ////////////////////////////////////////////////////////
		var TYPE = 'Controller';

		// PRIVATE VARS ///////////////////////////////////////////////////////
		var _log = TYPE + '.' + name + ' | ',
			
			_selector = '#taxonomy_menu',
			_selector_container = '[view=topic]',
			_selector_headers = '.head',

			_selector_panel = '.panel',
			_selector_wrapper = '.wrapper',
			_selector_background = '.topic-background',
			_selector_partner_banner = '.partner-teaser',
			_selector_menu_block = '.menu-block',

			_selector_eye_catcher = '#eye_catcher',
            _selector_welcome = '#welcome',

			_selector_article = '[view="article"]',

			_element = [],
			_element_panel = $(),
			_element_wrapper = $(),
			_element_container = [],
			_element_headers = [],
			_element_background = [],
			_element_partner_banner = [],
			_element_partner_banner_current = 0,

			_cache = {},

			_loading_url = '',
			_loading = false,
			_initialized = false;

		// PRIVATE METHODS ////////////////////////////////////////////////////
		var Controller = {};

		Controller.log = function ( string ) {
			console.log( _log + string );
		};


		Controller.init = function () {
			Controller.log('init');

			_element = $(_selector);
			_element_panel = $(_selector_panel, _element);
			_element_wrapper = $(_selector_wrapper, _element);
			_element_headers = $(_selector_headers, _element);
			_element_background = $(_selector_background);
			_element_partner_banner = $(_selector_partner_banner).remove();

			_element.on('click', _selector_headers+' a', function (event) {
				
				var ancor = $(this).attr('href');

				if ( State.is_mobile ) {
					
					if ( $(this).hasClass('active') ) {
						$(this).removeClass('active');

						$(ancor).removeClass('active');
					} else {
						$('.active', _element_headers).removeClass('active');
						$(this).addClass('active');

						$(ancor).addClass('active').siblings().removeClass('active');
					}

				} else {
					$(ancor + ' a').first().focus();
				}

				event.preventDefault();
				return false;

			});

			_initialized = true;

		};


		Controller.show = function (topic, section, selection) {

			if(!_initialized) Controller.init();

			Controller.log('show | '+topic+','+section+','+selection);

			if ( State.current_section != section ) {
				Controller.log('update section | '+section);
				$('.active[data-section]', _element).removeClass('active');
			}

			if ( section && selection ) {
				$('[data-section='+ section +']', _element).addClass('active');
				_element.addClass('has-selection');

				$('.active[data-selection]', _element).removeClass('active');
				$('[data-selection='+ selection +']', _element).addClass('active');
			} else {
				_element.removeClass('has-selection');
				$('.active[data-selection]', _element).removeClass('active');
			}

			$('.active', _element_headers).removeClass('active');

			if (!State.is_mobile) {
                var welcome = $(_selector_welcome,_element);
			    var eyeCatcher = $(_selector_eye_catcher,_element);

                //Eyecatcher nur aktiv, wenn Welcome Nachricht deaktiviert ist
                var _welcomeHidden = true;

                if (welcome.length) {
                    _welcomeHidden = Welcome.init(welcome) === false;
                }

                if (_welcomeHidden && eyeCatcher.length) {
                    EyeCatcher.init(eyeCatcher);
                }

			}

			// activate sections on mobile if hash is defined
			if(window.location.hash) {

				Controller.log('started with hash');
				var hash = window.location.hash;
				var segments = hash.replace('#', '').split('=');
				console.log(segments);

				if ( segments.length == 2 && segments[0] == 'bereich' ) {

					$(_selector_menu_block, _element).filter('[data-section="'+segments[1]+'"]').addClass('active');
					_element_headers.filter('[data-section="'+segments[1]+'"]').find('a').addClass('active');
				}
			} else {
			// Fragment doesn't exist
			}
		};


		Controller.load = function (url) {

            Welcome.exit();
		    EyeCatcher.exit();

			// prepare loading if not already loading
			if(!_loading && _initialized) {
				var w = _element_panel.outerWidth(),
					h = _element_panel.outerHeight();

				_element_panel.css({
					width: w,
					height: h
				});

				TweenMax.to(_element_wrapper, 0.2, {autoAlpha:0});
				TweenMax.to(_element_background, 0.3, {autoAlpha:0});

				_loading = true;
				_element.addClass('loading');
			}

			_loading_url = url;

			return Ajax.load(url, {module:'menu'}).then(function (data) {
				Controller.onLoad(data, url);
			});
		};


		Controller.onLoad = function (data, url) {
			if (url != _loading_url) return;

			Controller.log('onLoad | initialized: '+_initialized);

			var $data = $(data);
			
			_element_partner_banner = $data.find(_selector_partner_banner);

			console.log(_element_partner_banner);

			if (_initialized) {
				
				_element.removeClass('loading');
				_loading = false;
				_loading_url = '';

				// filtering the answer to remove any unnecessary elements
				
				var $temp = $data.find(_selector),
					$new = $(_selector_panel + ' > ' + _selector_wrapper, $temp),
					type = $temp.attr('teaser-type'),
					$temp_bg = $(data).find(_selector_background);

				_element.attr('teaser-type', type);
					
				
				// and replace the content
				TweenMax.set($new, {autoAlpha:0});
				_element_wrapper.replaceWith($new);
				_element_wrapper = $new;

				TweenMax.set($temp_bg, {autoAlpha:0});
				_element_background.replaceWith($temp_bg);
				_element_background = $temp_bg;



				Helper.requestAnimationFrame( function () {

					var w = _element_wrapper.width() + 40,
						h = _element_wrapper.height() + 80;

					Controller.log('w: '+w+' | h: '+h);

					TweenMax.to(_element_panel, 0.45, {width: w, height: h, top: 155, onComplete: function () {
						_element_panel.attr('style', '');
					}});

					TweenMax.to(_element_wrapper, 0.3, {autoAlpha: 1, delay: 0.65});


					TweenMax.to(_element_background, 0.5, {autoAlpha: 1});
				});
				
			} else {

				var container = $(_selector_container),
					article = container.children(_selector_article);

				// make shoure the menu is entered after an potential article
				if ( article.length > 0) {
					article.after($data.find(_selector))
				} else {
					$(_selector_container).prepend($data.find(_selector));
				}

				$(_selector_container)
					.append($data.find(_selector_background))
					.append($data.find(_selector_partner_banner));
			}
		};
		

		Controller.destroy = function () {
            Welcome.exit();
		    EyeCatcher.exit();
			_element.remove();
			_element_partner_banner = [];
			_initialized = false;
			_loading_url = false;
			_loading = false;
		};


        /**
		 * Gibt die Werbebotschaften von Sponsoren unterhalb von Artikeltexten
		 * Bei mehreren Bannern, wechseln diese sukkzessive, je nach Anzahl
         * @returns {*}
         */
		Controller.getPartnerBanner = function () {
			Controller.log('getPartnerBanner');
			console.log(_element_partner_banner);
            
			var output = false;
			
			if (_element_partner_banner.length) {
                // zeigt Partnerbanner erst bei Artikeln an - verhindert Rendering Flickr beim Thema 
			    _element_partner_banner.show();
				if (_element_partner_banner_current >= _element_partner_banner.length) {
					_element_partner_banner_current = 0;
				}
				if (_element_partner_banner.eq(_element_partner_banner_current).length) {
					output = _element_partner_banner.eq(_element_partner_banner_current).clone();
					++_element_partner_banner_current;
				}
			}
			
			return output;
		};

		Controller.getElement = function () {
			if(!_initialized) return [];
			return _element;
		};


		// PUBLIC METHODS /////////////////////////////////////////////////////
		return {
			load: Controller.load,
			show: Controller.show,
			destroy: Controller.destroy,
			getPartnerBanner: Controller.getPartnerBanner,
			getElement: Controller.getElement
		}
	}
);
