
// ==========================================================================================================
// ==== EYE CATCHER MODULE CONTROLLER ============================================================================
// ==========================================================================================================

modulejs.define(
	// name
	'module.welcome',
	// dependencies
	[
		'jQuery',
		'TweenMax',
		'TimelineMax',
		'service.state',
		'sessioncache'
	],
	// constructor
	function ( $, TweenMax, TimelineMax, State, sessioncache ) {
		// VIEW NAME //////////////////////////////////////////////////////////
		var name = 'Welcome';


		// STATIC VARS ////////////////////////////////////////////////////////
		var TYPE = 'Module';

			
		// PRIVATE VARS ///////////////////////////////////////////////////////
		var _log = TYPE + '.' + name + ' | ',

            _selector = '.welcome',
            _selector_placeholder = '.welcome-placeholder',
			_selector_text_bubble = '.text-bubble',
			_selector_large_bubble = '.large-bubble',
			_selector_small_bubble = '.small-bubble',
			_selector_bubble_content = '.bubble-content',
			_selector_hidden = '.hidden-when-closed',
			_selector_close_bubble = '.close-bubble',
			_selector_container = '[view=topic]',

			_element = [],
			_element_placeholder = [],
			_element_text_bubble = [],
			_element_large_bubble = [],
			_element_small_bubble = [],
			_element_bubble_content = [],
			_element_hidden = [],
			_element_close_bubble = [],

			_bubbleAnimation = null,

			_initialized = false;

		// PRIVATE METHODS ////////////////////////////////////////////////////
		var Controller = {};

		Controller.log = function ( string ) {
			console.log( _log + string );
		};

		Controller.init = function ( element ) {

			if ( !element || !element.length ) return false;

			Controller.log('init');
            // deaktiviert bis es wieder gebraucht wird 
			Controller.exit();
            return false;
            
			// NEU Holz Thema Willkommen - nur für dieses Thema anzeigen
			
            //Nur 1mal innerhalb einer Session anzeigen - aber nur im HOLZ Thema 
/*
            if (State.context.pathname === '/holz') {
                var _counter = sessioncache.get('module.welcome.showcounter');
                if (_counter > 0) {
                    Controller.exit();
                    return false;
                }
            } else {
                Controller.exit();
                return false;
            }
*/

            sessioncache.set('module.welcome.showcounter', 1);

			_element = $( element );

			$(_selector_container).append(_element);
			

			_element_placeholder = $( _selector_placeholder, _element );
			_element_bubble_content = $( _selector_bubble_content , _element);
			_element_close_bubble = $( _selector_close_bubble , _element);

			// Controller.update('fliesen-und-platten', 'Test Text!', 'http://api.jquery.com/attr/'); // test content

			_element_text_bubble = $( _selector_text_bubble , _element);
			_element_large_bubble = $( _selector_large_bubble , _element);
			_element_small_bubble = $( _selector_small_bubble , _element);
			_element_hidden = $( _selector_hidden , _element);

			_bubbleAnimation = new TimelineMax();

			_bubbleAnimation.add( TweenLite.fromTo( _element_hidden , 0.1, {autoAlpha:0}, {autoAlpha:0, display: 'block'}))
				.add( TweenLite.to( _element_hidden , 0.1, {autoAlpha:1}), 1.9)
				.add( TweenLite.from( _element_small_bubble , 0.2, {height: 0, width: 0}, {ease: Power3.easeInOut}), 2 )
				.add( TweenLite.from( _element_large_bubble , 0.4, {height: 0, width: 0}, {ease: Power3.easeInOut}), 2.1 )
				.set( _element_text_bubble , {height: '15px', width: '15px'}, 2.45 )
				.add( TweenLite.to(_element_text_bubble , 0.5, {height: '500px', width: '800px'}, {ease: Power3.easeInOut}), 2.45 )
				.add( TweenLite.to(_element_bubble_content, 0.2, {opacity: 1}) )
				.set( _element_close_bubble , {display: 'block'} );
			

            var showIcon = function () {
                _element_close_bubble.addClass('icon-close');
            };

            var showCloseBubble = function () {
                TweenLite.to(_element_close_bubble, 0.2, {height: '23px', width: '23px', onComplete: showIcon});
            };

            // var hideIcon = function () {
            //     _element_close_bubble.removeClass('icon-close');
            // };
            //
            // var hideCloseBubble = function() {
				// TweenLite.to( _element_close_bubble , 0.2, {height: 0, width: 0, onStart: hideIcon});
            // };
            //
            // if (State.is_tablet) {
            //     showCloseBubble();
            // } else {
            //     _element.on('mouseenter', showCloseBubble).on('mouseleave', hideCloseBubble);
            // }
            //
            // _element.on('mouseenter', showCloseBubble).on('mouseleave', hideCloseBubble);

            showCloseBubble();

			Controller.close();

			_element_placeholder.on('mouseenter', function(){
				TweenLite.set( _element_placeholder , {display: 'none'});
				_bubbleAnimation.play(2.1);
			});

			setTimeout(Controller.play, 1500);

			_initialized = true;
		};

		Controller.play = function () {
            if (_bubbleAnimation) {
                _bubbleAnimation.play(2.1);
            }
		};


		Controller.close = function () {

			_element_close_bubble.on('click', function(){
				_bubbleAnimation.reverse();
				TweenLite.set( _element_placeholder , {display: 'block'});
			});
		};

		Controller.exit = function () {

			if( !_initialized ) return false;

			Controller.log('exit');
			
			_element.off('mouseenter');
			_element.off('mouseleave');

			_element_close_bubble.off('click');

			_element_placeholder.off('mouseenter');

			_bubbleAnimation = null;

			_element.remove();
			_element = [];
			_element_placeholder = [];
			_element_text_bubble = [];
			_element_large_bubble = [];
			_element_small_bubble = [];
			_element_bubble_content = [];
			_element_hidden = [];
			_element_close_bubble = [];
			_initialized = false;
		};


		// PUBLIC METHODS /////////////////////////////////////////////////////
		return {
			init: Controller.init,
			//update: Controller.update,
			close: Controller.close,
			exit: Controller.exit
		}
	}
);
