
// ==========================================================================================================
// ==== CAROUSEL MODULE CONTROLLER ==========================================================================
// ==========================================================================================================

modulejs.define(
	// name
	'module.carousel',
	// dependencies
	[
		'jQuery',
		'shared.helper'
	],
	// constructor
	function ( $, Helper ) {
		// VIEW NAME //////////////////////////////////////////////////////////
		var name = 'Carousel';


		// STATIC VARS ////////////////////////////////////////////////////////
		var TYPE = 'Module';


		// PRIVATE VARS ///////////////////////////////////////////////////////
		var _log = TYPE + '.' + name + ' | ',

			_selector = '.carousel',
			_elements = $();


		// PRIVATE METHODS ////////////////////////////////////////////////////
		var Controller = {};

		Controller.STYLE_GALLERY = {
			dots: false,
			arrows: true
		};

		Controller.log = function ( string ) {
			console.log( _log + string );
		};

		Controller.init = function (container, style) {

			if (!style) style = Controller.STYLE_GALLERY;

			_elements = $(_selector, container);

			_elements.each(function(index) {

				Controller.log('init '+index);
                
                // start with initialSlide setzen 
                var startWith = $(this).data('gallery-start');
                style.initialSlide = typeof startWith === 'number' ? startWith : 0; // 0 ist das 1. 

				$(this).slick(style);
                $(this).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                    $(window).trigger('slick.changed', [slick, currentSlide, nextSlide]);
                });

			});
		};


		// PUBLIC METHODS /////////////////////////////////////////////////////
		return {
			init: Controller.init
		}
	}
);
