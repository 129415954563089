// ==========================================================================================================
// ==== INTERACTIVE CONTROLLER ==============================================================================
// ==========================================================================================================
/*
 * @author Jannis Menzel
 * @version 0.2
 * @description Manage mouse/touch interaction on topic routes
 */


modulejs.define(
    // name
    'controller.interactive',
    // dependencies
    [
        'jQuery',
        'TweenMax',
        'TimelineMax',
        'shared.helper',
        'service.config',
        'service.state'
    ],
    // constructor
    function ($, TweenMax, TimelineMax, Helper, Config, State) {
        // VIEW NAME //////////////////////////////////////////////////////////
        var name = 'Interactive';

        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'Controller',

            TEASER_DEFAULT = 'default',
            TEASER_OBJECT = 'objekte',
            TEASER_LATEST = 'latest';

        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ',

            _selector_interactive = '.interactive > .panel, .interactive > #topic_dropdown',
            _selector_taxonomy_submenu = '.menu-block',

            _class_interactive = 'interactive',
            _class_timer = 'visual-timer',

            _view_article = 'article',
            _view_taxonomy = 'taxonomy-menu',
            _view_teaser = 'topic-teaser',
            _view_glossary_layer = 'glossary-layer',

            _element = $(),
            // _element_timer,

            _element_article = $(),
            _element_taxonomy = $(),
            _element_teaser = $(),

            _tl_delay,
            _delay = 350,

            _target = $(),
            _animate = true,
            _animate_no_article = true,

            _article_base_left = 1000,
            _taxonomy_moved = false,
            _teaser_moved = false,

            _teaser_type = TEASER_LATEST,
            _has_article = false,

            _values,

            _value_sets = {
                medium: {
                    L0: 40, 		// taxonomy left
                    L1: 700, 		// teaser left
                    L2: 700 + 480,  // article left
                    W0: 700, 		// taxonomy width
                    W1: { 			// possible teaser widths
                        default: 480,
                        latest: 480,
                        objekte: 444
                    },
                    W2: 522, 		// article width
                    M1: 30, 		// min teaser left after move
                    M2: 40, 		// min article right
                    O1: -40, 		// offset taxonomy <-> teaser
                    O2: 40 			// offset teaser <-> article
                },
                large: {
                    L0: 60,
                    L1: 720,
                    L2: 720 + 520,
                    W0: 700,
                    W1: {
                        default: 480,
                        latest: 690,
                        objekte: 646
                    },
                    W2: 740,
                    M1: 96,
                    M2: 60, // todo Problem bei grossen Auflösungen ist der Artikel immer rechts aussen und viel Platz dazwischen ist ungenutzt  
                    O1: -40,
                    O2: 60
                },
                xlarge: {
                    L0: 60,
                    L1: 720,
                    L2: 720 + 520,
                    W0: 700,
                    W1: {
                        default: 480,
                        latest: 690,
                        objekte: 848
                    },
                    W2: 740,
                    M1: 96,
                    M2: 60, // todo Problem bei grossen Auflösungen ist der Artikel immer rechts aussen und viel Platz dazwischen ist ungenutzt  
                    O1: -40,
                    O2: 60
                }
            },

            _firstload = false,
            _initialized = false;

        // PRIVATE METHODS ////////////////////////////////////////////////////
        var Controller = {};

        Controller.log = function (string) {
            console.log(_log + string);
        };


        Controller.init = function (container) {

            if (State.is_mobile) {
                return false;
            }

            Controller.log('init');

            // init elements
            _element = $(container);
            _element_taxonomy = $('[view=' + _view_taxonomy + '] > .panel', _element);
            _element_teaser = $('[view=' + _view_teaser + '] > .panel', _element);
            _element_article = $('[view=' + _view_article + '] > .panel', _element);

            Controller.setupValues();

            // if this is the first view visited
            if (!State.last_view.length) {
                _firstload = true;
            }

            // mouse/touch listener
            if (!_firstload) {
                Controller.startEnterListener();
            }

            Controller.startLeaveListener();

            // resize listener
            $(window).on(Config.EVENT_RESIZE, function () {
                Controller.setupValues();
                
                Controller.reset(true, true);
                if (State.current_article) {
                    State.current_article_instance.maximize();
                }
                
                // if (!_animate) {
                //     Controller.reset(true, true);
                //     if (State.current_article) {
                //         State.current_article_instance.maximize();
                //     }
                // } else {
                //     if (State.current_article_instance && !State.current_article_instance.isMinimized() && _teaser_moved == false) {
                //         Controller.moveTeaser(false);
                //         Controller.moveTaxonomy(false);
                //     } else if (_teaser_moved) {
                //         // Controller.reset(true, true);
                //         _teaser_moved = false;
                //         _taxonomy_moved = false;
                //         Controller.moveTeaser(false);
                //         Controller.moveTaxonomy(false);
                //     }
                // }
            });

            // activate article if exists
            if (State.current_article) {
                _target = _element_article.parent();
                // wait for the dom to render
                // Helper.requestAnimationFrame();
                Controller.switch();
            }
            
            // moveable teaser
            // Controller.moveable(_element_teaser);
            
            _initialized = true;
        };
        
        Controller.startEnterTimer = null;
        
        Controller.startEnterListener = function () {

            // prevents multiple event listener
            _element.off('mouseenter.startEnterListener touchstart.startEnterListener');
            _element.on('mouseenter.startEnterListener touchstart.startEnterListener', _selector_interactive, function () {
                
                if (Controller.startEnterTimer) {
                    window.clearTimeout(Controller.startEnterTimer);
                }
                
                _target = $(this).parent();
                
                Controller.startEnterTimer = window.setTimeout(function() {

                    if (!_target.hasClass('active')) {
                        Controller.switch();
                    }
                    
                }, 150);
            });
        };

        Controller.startLeaveListener = function () {

            // prevents multiple event listener 
            _element.off('mouseleave.startLeaveListener touchend.startLeaveListener');
            _element.on('mouseleave.startLeaveListener touchend.startLeaveListener', _selector_interactive, function () {
                
                if (Controller.startEnterTimer) {
                    window.clearTimeout(Controller.startEnterTimer);
                }
                
                if (_firstload) {
                    _firstload = false;
                    Controller.startEnterListener();
                }

            });
        };


        Controller.setupValues = function () {

            var contentWidth = $(window).width();

            if (State.is_desktop) {
                
                // xlarge breakpoint 2.200px 
                if (State.is_ultra) {
                    _values = _value_sets.xlarge;
                } else {
                    _values = _value_sets.large;
                }
                
            } else {
                _values = _value_sets.medium;
            }

            // check current teaser type
            if (State.current_section) {
                if (State.current_section == TEASER_OBJECT) {
                    _teaser_type = TEASER_OBJECT;
                } else {
                    _teaser_type = TEASER_DEFAULT;
                }
            } else {
                _teaser_type = TEASER_LATEST;
            }


            // get the total required width for all elements ...
            var requiredWidth = _values['L0'] + _values['W0'] + _values['O1'] + _values['W1'][_teaser_type];

            if (requiredWidth < contentWidth) {
                Controller.log('required width: ' + requiredWidth + ' without article | no animation required');
                _animate_no_article = false;
            } else {
                Controller.log('required width: ' + requiredWidth + ' without article | animations active');
                _animate_no_article = true;
            }

            if (State.current_article) {
                requiredWidth += _values['O2'] + _values['W2'];
            }

            // ... and check if animation is required
            if (requiredWidth < contentWidth) {
                Controller.log('required width: ' + requiredWidth + ' | no animation required');
                _animate = false;
            } else {
                Controller.log('required width: ' + requiredWidth + ' | animations active');
                _animate = true;
            }


            if (_element_article.length) {
                _article_base_left = contentWidth - _values['M2'] - _values['W2'] - _values['O2'] - 10;
            } else {
                _article_base_left = contentWidth - 150;
            }
        };

        Controller.reset = function (resetTaxonomy, resetTeaser) {

            if (resetTaxonomy) {
                _taxonomy_moved = false;
                TweenMax.set(_element_taxonomy, {x: 0});
            }

            if (resetTeaser) {
                _teaser_moved = false;
                TweenMax.set(_element_teaser, {x: 0});
            }
        };


        Controller.exit = function () {

            if (!_initialized) return false;

            Controller.log('exit');

            _element.off('mouseenter touchstart');
            _element.off('mouseleave touchend');
            
            // Controller.moveableDestroy(element);
            
            _initialized = false;
        };
        
        Controller.moveableDestroy = function(element) {
            // deregister first
            $(document).off("touchmove.move");
            $(document).off("mousemove.move");

            $(element).off("touchstart.move");
            $(element).off("touchend.move");

            $(element).off("mousedown.move");
            $(element).off("mouseup.move");
            $(element).off("click.move");
        }
        
        Controller.moveable = function(element) {
            // move per Maus/Finger verschiebbares Fenster 
            if (!$(element).length) return;
            
            var dragItem = $(element).get();
            
            // https://www.kirupa.com/html5/drag.htm
            var currentX;
            var currentY;
            var initialX;
            var initialY;
            var xOffset = 0;
            var yOffset = 0;

            var setTranslate = function (xPos, yPos, el) {
                $(el).css('transform', "translate3d(" + xPos + "px, " + yPos + "px, 0)");
            }
            var dragStart = function (e) {
                if (e.type === "touchstart") {
                    initialX = e.touches[0].clientX - xOffset;
                    initialY = e.touches[0].clientY - yOffset;
                } else {
                    initialX = e.clientX - xOffset;
                    initialY = e.clientY - yOffset;
                }

                // listener für drag registrieren bei Bedarf 
                $(document).on("touchmove.move", drag);
                $(document).on("mousemove.move", drag);
            };
            var drag = function (e) {
                e.preventDefault();
                if ($(e.target).is('a')) {
                    dragEnd();
                }
                if (e.target !== dragItem) {
                    if (!$(e.target).closest(dragItem)) {
                        dragEnd();
                    }
                }
                
                if (e.type === "touchmove") {
                    currentX = e.touches[0].clientX - initialX;
                    currentY = e.touches[0].clientY - initialY;
                } else {
                    currentX = e.clientX - initialX;
                    currentY = e.clientY - initialY;
                }

                xOffset = currentX;
                yOffset = currentY;
                
                setTranslate(currentX, currentY, dragItem);                
            };
            var dragEnd = function () {
                // drag listener entfernen 
                $(document).off("touchmove.move", drag);
                $(document).off("mousemove.move", drag);
            };
            
            Controller.moveableDestroy(element);
            
            // drag/move listener auf dem Element, nicht auf dem Container 
            $(element).on("touchstart.move", dragStart);
            $(element).on("touchend.move", dragEnd);
        
            $(element).on("mousedown.move", dragStart);
            $(element).on("mouseup.move", dragEnd);
            $(element).on("click.move", dragEnd);
        }

        Controller.switch = function (target) {
            
            _target = $(target).length ? $(target) : _target;
            if (!_target.length) return;
            
            // console.warn('my _target', _target, target);

            Controller.log('switch');
            
            // Animation des Teaserfenster ausgesetzt, wird durch Drag & Drop gesteuert 
            // und initial immer etwas über dem Taxonomy Fenster um den Artikel nicht zu überdecken 

            // has an article
            if (_element_article.length && _animate) {
                if (_target.attr('view') == _view_article) {
                    // move teaser to show article
                    Controller.moveTeaser(false);
                    Controller.moveTaxonomy(false);

                    if (State.current_article_instance) {
                        State.current_article_instance.maximize();
                    }
                } else if (_target.attr('view') == _view_taxonomy) {
                    // move teaser to show taxonomy menu
                    Controller.moveTaxonomy(true);
                    if (_animate_no_article) {
                        Controller.moveTeaser(false);
                    } else {
                        Controller.moveTeaser(true);
                    }

                    if (State.current_article_instance) {
                        State.current_article_instance.minimize();
                    }
                } else if (_target.attr('view') == _view_teaser && _animate_no_article) {
                    Controller.moveTeaser(true);
                    Controller.moveTaxonomy(false);
                    if (State.current_article_instance) {
                        State.current_article_instance.minimize();
                    }
                }

            } else if (_animate_no_article) {
                Controller.log('toggle and animate');

                if (_target.attr('view') == _view_teaser) {
                    Controller.moveTeaser(true);
                    Controller.moveTaxonomy(false);
                } else if (_target.attr('view') == _view_taxonomy) {
                    Controller.moveTeaser(false);
                    Controller.moveTaxonomy(true);
                }
            }

            Controller.toggleActive();
        };


        Controller.moveTeaser = function (activate) {

            var moveTime = 0.30;
            
            if (activate && _animate_no_article) {

                Controller.log('moveTeaser activate no article');

                var left = $(window).width() - _values['L1'] - _values['W1'][_teaser_type] - 150;
                TweenMax.to(_element_teaser, moveTime, {x: left, ease: Power2.easeInOut});
                _teaser_moved = false;
            } else if (activate && _teaser_moved) {

                Controller.log('moveTeaser activate');

                TweenMax.to(_element_teaser, moveTime, {x: 0, ease: Power2.easeInOut});

                _teaser_moved = false;

            } else if (!activate) {

                if (_animate_no_article && _target.attr('view') == _view_taxonomy) {
                    Controller.log('moveTeaser deactivate for taxonomy');
                    TweenMax.to(_element_teaser, moveTime, {x: 0, ease: Power2.easeInOut});
                } else {
                    Controller.log('moveTeaser deactivate for article');
                    var left = _values['L1'];
                    var toLeft = _article_base_left - _values['W1'][_teaser_type];

                    // console.info(toLeft, _values['M1'], State.is_desktop)

                    if (toLeft < _values['M1']) {
                        toLeft = _values['M1'];
                    }

                    var x = toLeft - left;
                    // console.info(left, toLeft, x)

                    TweenMax.to(_element_teaser, moveTime, {x: x, ease: Power2.easeInOut});
                }

                _teaser_moved = true;

            } else if (!activate && _animate_no_article) {
                TweenMax.to(_element_teaser, moveTime, {x: 0, ease: Power2.easeInOut});
                _teaser_moved = true;
            }
        };

        Controller.moveTaxonomy = function (activate) {
            return;

            Controller.log('moveTaxonomy');

            if (activate) {
                // move taxonomy menu to default position
                TweenMax.to(_element_taxonomy, 0.5, {x: 0, ease: Power2.easeInOut});
                _taxonomy_moved = false;

            } else {
                if (_animate_no_article && _target.attr('view') == _view_teaser) {
                    TweenMax.to(_element_taxonomy, 0.5, {x: 0, ease: Power2.easeInOut});
                } else {
                    var overlap = Controller.teaserCoversTaxonomy();
                    // check if the active taxonomy submenu is covered by the teaser menu
                    if (overlap !== false) {

                        Controller.log('overlap: ' + overlap);

                        TweenMax.to(_element_taxonomy, 0.75, {x: overlap, ease: Power2.easeInOut});
                    }
                }
                _taxonomy_moved = true;
            }
        };


        Controller.teaserCoversTaxonomy = function () {

            var activeMenu = $(_selector_taxonomy_submenu + '.active', _element_taxonomy);

            if (activeMenu.length == 0) {
                activeMenu = $(_selector_taxonomy_submenu, _element_taxonomy).first();
            }

            var gs_offset = (_element_taxonomy.get(0)._gsTransform) ? _element_taxonomy.get(0)._gsTransform.x : 0,
                activeLeft = activeMenu.offset().left - gs_offset,
                activeWidth = activeMenu.width(),

                teaserWidth = _values['W1'][_teaser_type], //_element_teaser.width(),
                teaserLeft = _article_base_left - _values['W1'][_teaser_type], //teaserWidth,

                taxonomyWidth = _values['W0']; //_element_taxonomy.width();

            if (teaserLeft < ( activeLeft + activeWidth )) {
                var maxLeft = -1 * ( activeLeft - _values['L0'] ),
                    minLeft = -1 * ( activeLeft - teaserLeft + activeWidth - _values['O1'] );

                if (maxLeft > minLeft) {
                    return maxLeft;
                } else {
                    return minLeft;
                }
                // return left position of active taxonomy menu
                // return activeLeft;
            } else {
                return false;
            }
        };

        // todo direct activate of window target 
        Controller.toggleActive = function () {
            Controller.log('toggleActive');
            
            if (!_target.length) return;

            _target.siblings('.active').each(function () {

                var panel = $('> .panel', this);
                
                if (!panel.length) return;

                if (!panel[0].hasAttribute('data-topOrg')) {
                    panel.attr('data-topOrg', parseInt(panel.css('top'), 10));
                }

                var scrollTop = $(window).scrollTop();

                // if it has a scroll top value it was set outside off interactive and should overwrite window scroll top
                if (panel[0].hasAttribute('data-scrollTop')) {
                    scrollTop = panel.attr('data-scrollTop');
                } else {
                    panel.attr('data-scrollTop', scrollTop);
                }

                Controller.log('window scrollTop on change: ' + scrollTop);

                $(this).removeClass('active');
                //Controller.log(panel.data('topOrg'));
                panel.css('top', panel.attr('data-topOrg') - scrollTop + 'px');

                Controller.log('last active values | scrollTop: ' + panel.attr('data-scrollTop') + ' | topOrg: ' + panel.attr('data-topOrg'));
            });

            var panel = $('> .panel', _target);

            if (!panel.length) return;

            _target.addClass('active');
            var scrollTop = 0;

            if (panel[0].hasAttribute('data-scrollTop')) {
                scrollTop = panel.attr('data-scrollTop');
                panel.removeAttr('data-scrollTop');
            }
            if (panel[0].hasAttribute('data-topOrg')) {
                panel.css('top', panel.attr('data-topOrg') + 'px');
            }

            // console.warn('my active', scrollTop, $(window).scrollTop())
            
            if (State.current_glossary_layer) {

            }
            
            $(window).scrollTop(scrollTop);

            Controller.log('new active values | scrollTop: ' + panel.attr('data-scrollTop') + ' | topOrg: ' + panel.attr('data-topOrg'));

        };

        // PUBLIC METHODS /////////////////////////////////////////////////////
        return {
            init: Controller.init,
            exit: Controller.exit,
            reset: Controller.reset,
            switchToTarget: Controller.switch
        }
    }
);
