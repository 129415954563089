// ==========================================================================================================
// ==== TAXONOMY MENU CONTROLLER ============================================================================
// ==========================================================================================================

/* dependencies:
** https://github.com/customd/jquery-visible
**
*/

modulejs.define(
	// name
	'controller.topicTeaser',
	// dependencies
	[
		'jQuery',
		'TweenMax',
		'shared.helper',
		'service.state',
		'controller.ajax',
		'controller.lazyImage'
	],
	// constructor
	function ( $, TweenMax, Helper, State, Ajax, LazyImage ) {
		// VIEW NAME //////////////////////////////////////////////////////////
		var name = 'TopicTeaser';

		// STATIC VARS ////////////////////////////////////////////////////////
		var TYPE = 'Controller';

		// PRIVATE VARS ///////////////////////////////////////////////////////
		var _log = TYPE + '.' + name + ' | ',
			
			_selector = '#topic_teaser_block',
			_selector_container = '[view=topic]',

			_selector_lazy = '.lazy-image',
			_selector_lazy_image = '.lazy-image img',

			_selector_link = '.teaser-link',
            _selector_teaser_text = '.teaser-text',
			_selector_teaser = '.teaser',
			_selector_panel = '.panel',
			_selector_wrapper = '.wrapper',
			_selector_close = '.btn-close-panel',


			_class_lazy = 'lazy-image',
			_class_teaser_text = 'teaser-text',

			_attr_article = 'data-article',

			_element = $(),
			_element_panel = $(),
			_element_wrapper = $(),
			_element_container = $(),
			_element_close = $(),

			_cache = {},

			_loading_url = '',
			_loading = false,
			_initialized = false;
		
		var _isFirstShow = true;

		// PRIVATE METHODS ////////////////////////////////////////////////////
		var Controller = {};

		Controller.log = function ( string ) {
			console.log( _log + string );
		};


		Controller.init = function () {
			
			Controller.log('init');

			_element = $(_selector);
			_element_panel = $(_selector_panel, _element);
			_element_wrapper = $(_selector_wrapper, _element);
            _element_close = $(_selector_close, _element);
            
			LazyImage.init(_element);
            
			Controller.setupCloseButton();
			
			_initialized = true;
		};

		Controller.show = function (article) {
		    
			if(!_initialized) Controller.init();
		
			var next = Controller.updateActiveTeaser(article);
			
            Controller.initSelection();
			
			$(window).trigger('scroll');
            
            _isFirstShow = false;
			
			// pass trough the next article teaser for infinite scrolling
			return next;
		};
        
		Controller.setupCloseButton = function() {

            // init event listener

            if (_element_close.length) {
                _element_close.off('click.close');
                _element_close.on('click.close', function (e) {
                    e.preventDefault();
                    e.stopPropagation();
    
                    Controller.close();
                });
            }
		    
        };

		Controller.open = function () {
		    // show panel if hidden 
			if (!State.is_mobile && !_element.is(':visible')) {
                _element.show();
            }
		};

		Controller.close = function () {
		    if (!State.is_mobile && !_loading) {
                _element.hide();
            }
		};

		Controller.load = function (url) {

			// prepare loading if not already loading
			if(!_loading && _initialized) {
				var w = _element_panel.width(),
					h = _element_panel.height();

				if (!State.is_mobile) {
					_element_panel.css({
						width: w,
						height: h
					});
				}

				if (State.is_mobile && !State.current_article && _element.attr('teaser-type') != 'latest') {
					TweenMax.fromTo(_element_panel, 0.45, {x: '0%'}, {x:'100%', ease: Power4.easeIn, onComplete:function () {
						TweenMax.set(_element_wrapper, {autoAlpha: 0});
					}});
				} else {
					TweenMax.to(_element_wrapper, 0.2, {autoAlpha:0});
				}
				

				_loading = true;
				_element.addClass('loading');
			}

			_loading_url = url;
			

			return Ajax.load(url, {module:'teaser'}).then(function (data) {
				Controller.onLoad(data, url);
			});
		};


		Controller.onLoad = function (data, url) {
				
			if (url != _loading_url) return;

			Controller.log('onLoad | initialized: '+_initialized);

			if (_initialized) {
				
				_element_panel.attr('data-scrollTop',0);
				_element.removeClass('loading');
				_loading = false;
				_loading_url = '';

				// filtering the answer to remove any unnecessary elements
				var $temp = $(data).find(_selector),
					$new = $(_selector_panel + ' > ' + _selector_wrapper, $temp),
					type = $temp.attr('teaser-type');
					_element.attr('teaser-type', type);
                
				// switch border color class
                _element_panel.attr('class', $temp.find(_selector_panel).attr('class'));
					
				// and replace the content
				TweenMax.set($new, {autoAlpha:0});
				_element_wrapper.replaceWith($new);
				_element_wrapper = $new;
				LazyImage.init(_element);

				Helper.requestAnimationFrame( function () {

					if ( State.is_mobile && _element.attr('teaser-type') != 'latest' ) {
						TweenMax.set(_element_wrapper, {autoAlpha: 1});
						TweenMax.fromTo(_element_panel, 0.45, {x: '100%'}, {x:'0%', ease: Power4.easeOut});
					} else {
						var w = _element_wrapper.outerWidth(),
						h = _element_wrapper.outerHeight();

						TweenMax.to(_element_panel, 0.45, {width: w, height: h, top: 207, onComplete: function () {
							_element_panel.attr('style', '');
						}});

						TweenMax.to(_element_wrapper, 0.3, {autoAlpha: 1, delay: 0.65});
					}
					
				});

			} else {
				$(_selector_container).append( $(data).find(_selector) );
			}
		};
		
		Controller.visiterListener = function() {
			
            // event listener for mouse selection
            if (!State.is_mobile) {
                var $teaserList = $(_selector_teaser, _element);
                
                $teaserList.off('mouseenter.visited');
                $teaserList.off('mouseout.visited');
                
                $teaserList.on('mouseenter.visited', function () {
                    $teaserList.filter('.hover').removeClass('hover');
                }).on('mouseout.visited', function () {
                    // select last visited 
                    $(this).addClass('hover');
                });
            }
		    
        };
		
        Controller.initSelection = function() {
            
            Controller.log('initSelection');
            
            if (State.is_mobile) return;
            if (_element.attr('teaser-type') === 'objekte') return;
            
            // (re)register listener
            Controller.visiterListener();
            
            // reset hovered teaser
            $(_selector_teaser, _element).filter('.hover').removeClass('hover');
            
            // active will be hovered 
            var $active = $(_selector_teaser, _element).filter('.active');
            if ($active.length) {
                $active.addClass('hover');
                return;
            }
            
            // only for knowledge and tips - not objects 
            // no article selected, so first will be detected 
            // $(_selector_link, _element).removeClass('active');

            var $firstTeaser = $(_selector_teaser, _element).first();
            // teaser-text will be selected
            if ($firstTeaser.length && $firstTeaser.hasClass(_class_teaser_text)) {
                    $firstTeaser.addClass('hover');
            }
        };
		
		// change current selected teaser
		Controller.updateActiveTeaser = function (article) {
		    
			Controller.log('updateActiveTeaser ---------------------------------------------');
            
            Controller.open();
			
			$(_selector_teaser+'.active', _element).removeClass('active');

			var current = $(_selector_link+'['+_attr_article+'="'+article+'"]', _element).closest(_selector_teaser);
            
			current.addClass('active');

			var next;

			if (current.hasClass('teaser-medium')) {
				next = current.closest('.columns').next().find('.teaser-medium').clone();
			} else {
				next = current.next().clone();
			}

			if (!State.is_mobile && current && current.length == 1) {
				Controller.log('scroll into view | visible: '+current.visible());
				if (!current.visible() && !_isFirstShow) {

					var topOrg;
					if( !_element_panel[0].hasAttribute('data-topOrg') ) {
						topOrg = parseInt( _element_panel.css('top') );
						_element_panel.attr('data-topOrg', topOrg );
					} else {
						topOrg = parseInt(_element_panel.attr('data-topOrg'));
					}
					
					var o = current.offset(),
						ta = parseInt( _element_panel.css('top') ) - topOrg, // if the teaser layer is not active and has an offset
						t = o.top - ta,
						h = current.height(),
						s = t - ( $(window).height() / 2 ) + ( h / 2 );
					
					var top = topOrg - s;

					Controller.log('scroll into view | s: '+s+' | t: '+t+' | h: '+h+' | t0: '+topOrg);

					TweenMax.to(_element_panel, 0.35, {top:top+'px'});
					_element_panel.attr('data-scrollTop',s);
				}
			}
            
			Controller.log('updateActiveTeaser | next:');
			console.log(next);
			Controller.log('updateActiveTeaser ---------------------------------------------');
			if (next && next.length > 0) {
				return next;
			} else {
				return false;
			}
		};


		Controller.destroy = function () {
			_element.remove();
			_initialized = false;
		};


		Controller.getElement = function () {
			if (!_initialized) return [];

			return _element;
		};
		
        Controller.selectFirstTeaser = function() {
            if (!_initialized) return;
            
            var $firstTeaserLink = $(_selector_teaser, _element).first().find(_selector_link);
            if (!$firstTeaserLink.length) return;
            
            var url = $firstTeaserLink.attr('href');
            if (!url) return;
                        
            State.is_selected_first_teaser = true;
            
            page.show(url);
        };

		// PUBLIC METHODS /////////////////////////////////////////////////////
		return {
			show: Controller.show,
			load: Controller.load,
			destroy: Controller.destroy,
			getElement: Controller.getElement,
            selectFirstTeaser: Controller.selectFirstTeaser
		}
	}
);
