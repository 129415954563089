
// ==========================================================================================================
// ==== LAYER CONTROLLER ====================================================================================
// ==========================================================================================================

modulejs.define(
	// name
	'controller.layer',
	// dependencies
	[
		'jQuery'
	],
	// constructor
	function ( $ ) {
		// VIEW NAME //////////////////////////////////////////////////////////
		var name = 'Layer';

		// STATIC VARS ////////////////////////////////////////////////////////
		var TYPE = 'Controller';

		// PRIVATE VARS ///////////////////////////////////////////////////////
		var _log = TYPE + '.' + name + ' | ',
			_layer = [];

		// PRIVATE METHODS ////////////////////////////////////////////////////
		var Controller = {};

		Controller.log = function ( string ) {
			console.log( _log + string );
		};


		Controller.overlay = function (element) {

			console.log(element);

			var h = $(window).height(),
				s = $(window).scrollTop();
			
			element.css({
				height: h,
				width: '100%',
				overflowY: 'hidden',
				position: 'fixed',
				zIndex: 0,
				left: 0,
				top: 0
			}).scrollTop(s);
		};

		Controller.deoverlay = function (element) {
			var s = element.scrollTop();

			element.attr('style', '');
			$(window).scrollTop(s);
		};

		// PUBLIC METHODS /////////////////////////////////////////////////////
		return {
			overlay: Controller.overlay,
			deoverlay: Controller.deoverlay
		}
	}
);
