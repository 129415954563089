// ==========================================================================================================
// ==== INITIALIZATION CONTROLLER ===========================================================================
// ==========================================================================================================
/*
 * @description setup global values and behavior
 */
modulejs.define(
	// name
	'controller.initialization',
	// dependencies
	[
		'jQuery',
		'service.config',
		'service.state',
		'controller.pageChange'
	],
	// constructor
	function ( $, Config, State, PageChange ) {
		// VIEW NAME //////////////////////////////////////////////////////////
		var name = 'Initialization';

		// STATIC VARS ////////////////////////////////////////////////////////
		var TYPE = 'Controller';

		// PRIVATE VARS ///////////////////////////////////////////////////////
		var _log = TYPE + '.' + name + ' | ',
			_element = [];

		// PRIVATE METHODS ////////////////////////////////////////////////////
		var Controller = {};

		Controller.log = function ( string ) {
			console.log( _log + string );
		};


		Controller.init = function () {

			PageChange.init();

			if ($('body').hasClass('debug-mode')) {
				$(window).on('error', function () {
					var check = confirm('Ups, da ist was schief gelaufen!\nIch versuche neu zu laden.');

					if (check) {
						window.location.reload();
					}
				});
			} else {
				window.console = {
					log: function(opt) {},
					group: function(opt) {},
					groupEnd: function(opt) {},
					info: function(opt) {},
					warn: function(opt) {},
					error: function(opt) {},
					groupCollapsed: function(opt) {}
				}
			}

			Controller.checkBreakpoints();

			$(window).on('resize', $.debounce(250, function (event) {
				// update breakpoints
				Controller.checkBreakpoints();
				// trigger a global resize event
				$(window).trigger(Config.EVENT_RESIZE);
			}));
		};

        Controller.checkBreakpoints = function () {
            var w = Math.max(window.innerWidth, $(window).width());
            State.is_mobile = Config['TABLET_BREAKPOINT'] > w;
            State.is_tablet = Config['TABLET_BREAKPOINT'] <= w && w < Config['DESKTOP_BREAKPOINT'];
            State.is_desktop = Config['DESKTOP_BREAKPOINT'] <= w;
            State.is_ultra = Config['ULTRA_BREAKPOINT'] <= w;
        };

		// PUBLIC METHODS /////////////////////////////////////////////////////
		return {
			init: Controller.init
		}
	}
);
