// ==========================================================================================================
// ==== VIEW DEFAULT ========================================================================================
// ==========================================================================================================

/**
 * @description Methods for all topic related routes. Including articles
 */

modulejs.define(
    // name
    'view.topic',
    // dependencies
    [
        'jQuery',
        'page',
        'service.state',
        'controller.meta',
        'controller.pageChange',
        'controller.layer',
        'controller.taxonomyMenu',
        'controller.topicTeaser',
        'controller.article',
        'controller.interactive',
        'module.topicDropdown',
        'controller.history',
        'controller.watchlist',
        'module.glossarLayer'
        // 'module.pollLayer'
    ],
    // constructor

    function ($, page, State, Meta, PageChange, Layer, TaxonomyMenu, TopicTeaser, Article, Interactive, TopicDropdown, History, Watchlist, GlossarLayer) {


        // VIEW NAME //////////////////////////////////////////////////////////
        var name = 'Topic';

        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'View';

        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ',

            _initialized = false,

            _selector_container = '[role=main]',

            _selector = '[view=topic]',

            _selector_article = '[view="article"]',

            _article = false,
            _next_article = false,

            _element = [],
            _element_dropdown;

        var _glossaryLoaded = {};

        // PRIVATE METHODS ////////////////////////////////////////////////////
        var View = {};

        View.log = function (string) {
            var now = new Date(Date.now());
            console.log(_log, string, now.toLocaleTimeString() + '.' + now.getMilliseconds());
        };

        View.init = function (ctx) {

            if (_initialized) return;

            View.log('init');

            _element = $(_selector);

            _element_dropdown = new TopicDropdown(_element);
            
            // show poll for Schiefer only 
            // PollLayer.init();
            
            // todo ctx will be the old of init and not the new for show, so this context is to old!!! because of closure binding  
            // gallery change image handler 
            $(window).on('pswp-change', function (event, index) {

                // console.log('pswp-change', index);

                if (State.current_gallery) {
                    // topic article gallery
                    page.show('/' + State.current_topic + '/' + State.current_section + '/' + State.current_selection + '/' + State.current_article + '/' + State.current_gallery + '/' + (index + 1));
                }

                // differentiate between glossary and topic gallery
                if (State.current_glossary_layer) {

                    // is glossary layer route?
                    if ('glossar' in State.context.query.params && State.context.query.params.glossar.gallery) {
                        State.context.query.params.glossar.image = (index + 1);

                        // build glossar route with gallery image url 
                        var galleryPaths = [
                            '',
                            'glossar',
                            State.context.query.params.glossar.letter,
                            State.context.query.params.glossar.article,
                            State.context.query.params.glossar.gallery,
                            State.context.query.params.glossar.image
                        ];

                        // change url parameter glossary layer gallery
                        page.show('/' + State.current_topic + '/' + State.current_section + '/' + State.current_selection + '/' + State.current_article + '?glossar=' + galleryPaths.join('/'));
                    }
                }

            });

            _initialized = true;
        };

        View.show = function (ctx) {
            View.log('show');

            State.is_topic_route = true;
            State.current_view = name;
            State.topicDestroy = View.destroy;

            var changeGlossaryLayer = ctx.query.params.glossar ? State.current_glossary_layer !== ctx.query.params.glossar.article : true;

            // did glossary article changed - necessary for opened galleries  
            State.current_glossary_layer = ctx.query.params.glossar ? ctx.query.params.glossar.article : false; // complete glossar url for layer
            State.current_glossary_layer = !!State.current_glossary_layer ? State.current_glossary_layer : false;

            // do not scroll on top with glossary layer
            if (!State.current_glossary_layer) {
                Layer.deoverlay(_element);
            }

            var section = ctx.params.section || false,
                selection = ctx.params.selection || false,
                article = ctx.params.article || 0,
                gallery = ctx.params.gallery || 0,
                galleryImage = ctx.params.image || 0,
                changeArticle = State.current_article != article;

            TaxonomyMenu.show(ctx.params.topic, section, selection);
            var infiniteScrollNext = TopicTeaser.show(article);

            // remove outdated article
            if (_article && changeArticle) {
                _article.exit();
                _article = false;
            }

            if (_next_article) {
                // swap current article
                _article = _next_article;
                _next_article = false;
            } else if (article && changeArticle) {
                // there is a new article that was not initiated on load => first load
                _article = new Article({element: $(_selector_article, _element), container: _element});
            }

            // show article if it is a new one
            if (_article && changeArticle) {

                // append partner banner after article
                var banner = TaxonomyMenu.getPartnerBanner();
                if (banner) {
                    _article.appendPartnerBanner(banner);
                }

                // activate infinite scroll while there are more articles
                console.log(infiniteScrollNext);
                if (infiniteScrollNext) {
                    _article.appendInfiniteTeaser(infiniteScrollNext);
                }

                _article.show();
            }

            // show gallery of article  
            if (article && gallery && galleryImage && gallery != State.current_gallery) {
                _article.showGallery(gallery, galleryImage);

                // when gallery closes reset url to article
                $(window).one('pswp-close', function () {
                    page.show('/' + State.current_topic + '/' + State.current_section + '/' + State.current_selection + '/' + State.current_article);
                });

            } else if (State.current_gallery && !gallery) {
                _article.closeGallery();
            }

            State.current_topic = ctx.params.topic;
            State.current_section = section;
            State.current_selection = selection;
            State.current_article = article;
            State.current_gallery = gallery;
            State.current_article_instance = _article;

            State.last_content_route = ctx.path;
            
            // choose first article of selection - only for desktop   
            if (!State.is_mobile) {
                // no article is chosen and there is a current_selection 
                if (!State.current_article && State.current_selection) {
                    TopicTeaser.selectFirstTeaser();
                }
            }
            
            // info for history and watchlist
            if (article && !gallery) {
                if (!State.current_glossary_layer) {
                    var history = [
                        _article.getId(),
                        Date.now()
                        //,State.current_topic
                    ];
                    var watchlist = [
                        _article.getId()
                    ];
                    Watchlist.enable(watchlist);
                    History.add(history);

                    Meta.readFrom(_article.getElement());
                }


                /*
                 * Glossary layer routes 
                 */

                // if article view - initialize glossary layer - classic view for mobile 
                if (!State.is_mobile) {

                    // load glossary layer if path has active glossary path in url parameter 
                    if (State.current_glossary_layer) {

                        var promise = changeGlossaryLayer && !_glossaryLoaded['ctx.query.glossar'] ? GlossarLayer.load(ctx.query.glossar) : $.Deferred().resolve();

                        // remember $(window).scrollTop() positon of article
                        // todo tweak have to be in interactive?
                        if (_article) {
                            // nur ausführen wenn der Artikel active hat, falls der Glossar Layer geklickt wurde
                            // wird sonst 0 eingesetzt
                            if (_article.getElement().hasClass('active')) {
                                _article.getElement().find('> .panel').attr('data-scrollTop', $(window).scrollTop());
                            }
                        }

                        promise.then(function () {

                            delete _glossaryLoaded['ctx.query.glossar'];

                            // show gallery
                            // search for gallery in URL i.e. ?glossar=/glossar/d/druecker-47241/gallery-1/1
                            var glossaryArticle = GlossarLayer.getArticle();

                            if (glossaryArticle && State.context.query.params.glossar.gallery) {

                                // not if already opened - or gallery article has changed
                                if (!glossaryArticle.isGalleryOpen()) {

                                    // open
                                    // todo use url and then open it 
                                    glossaryArticle.showGallery(State.context.query.params.glossar.gallery, State.context.query.params.glossar.image);

                                    // close listener 
                                    $(window).one('pswp-close', function () {

                                        if ('glossar' in State.context.query.params && State.context.query.params.glossar.gallery) {

                                            // glossar route without gallery part 
                                            var galleryPaths = [
                                                '',
                                                'glossar',
                                                State.context.query.params.glossar.letter,
                                                State.context.query.params.glossar.article
                                            ];

                                            page.show('/' + State.current_topic + '/' + State.current_section + '/' + State.current_selection + '/' + State.current_article + '?glossar=' + galleryPaths.join('/'));

                                        }
                                    });
                                }

                            } else {
                                // close gallery when different route
                                glossaryArticle.closeGallery();
                            }

                        });

                    } else {
                        // destroy possible glossary layer
                        GlossarLayer.destroy();
                    }

                    // change glossary links for new url parameter glossary 
                    $(_article.getElement()).find('.glossarlink').each(function () {
                        var path = $(this).attr('href');
                        if (path && path.indexOf('?glossar=') === -1) {
                            $(this).attr('href', '?glossar=' + path);
                        }
                    });
                }


            } else if (!article) {
                Meta.readFrom(TopicTeaser.getElement());
            }
            
            if (!gallery) {

                // show poll for Schiefer only 
                // PollLayer.show();
                
            }

            Interactive.init(_element);
        };

        View.load = function (ctx) {

            View.log('Route.load');

            // create/restore element
            if (!_initialized) {
                _element = $('<div view="topic" />');
                $(_selector_container).append(_element);
            }

            var section = ctx.params.section || 0,
                selection = ctx.params.selection || 0,
                article = ctx.params.article || 0,
                loading = [],
                defer = $.Deferred();

            var changeTopic = State.current_topic != ctx.params.topic,
                changeSection = State.current_section != section,
                changeSelection = State.current_selection != selection,
                changeArticle = State.current_article != article,
                changeTeaser = ( changeSelection || changeSection || changeTopic );

            var changeGlossaryLayer = ctx.query.params.glossar ? State.current_glossary_layer !== ctx.query.params.glossar.article : false;

            Interactive.reset(changeTopic || changeSection, changeTeaser);

            // +++++ view 4 states: topic, selection, article, glossary-layer +++++

            // load glossary layer
            if (changeGlossaryLayer) {
                // _glossaryLoaded needed to avoid the same ajax requests twice 

                // load glossary layer if path has active glossary path in url parameter 
                _glossaryLoaded['ctx.query.glossar'] = true;
                loading.push(GlossarLayer.load(ctx.query.glossar));
            }

            // load taxonomy
            if (changeTopic) {

                loading.push(TaxonomyMenu.load(ctx.path));
                
                // load user poll / survey for schiefer only 
                // if (ctx.params.topic === 'schiefer') {
                //     loading.push(PollLayer.load(ctx.path));
                // }

                // update for topic dropdown (if browser history changed back this was not updated before)
                if (_element_dropdown) {
                    _element_dropdown.selectTopic(ctx.params.topic);
                }

                // close glossary layer if topic changed 
                if (GlossarLayer) {
                    GlossarLayer.destroy();
                }
            }

            // load teaser
            if (changeSelection || changeSection || changeTopic) {

                loading.push(TopicTeaser.load(ctx.path));

                // close glossary layer if topic changed 
                if (GlossarLayer) {
                    GlossarLayer.destroy();
                }
            }

            // load article
            if (article && ( changeArticle || changeSelection || changeSection || changeTopic )) {

                if (_article) {
                    _article.exit();
                    _article = false;
                }

                if (_next_article) {
                    _next_article.exit();
                    _next_article = false;
                }

                _next_article = new Article({container: _element});
                loading.push(_next_article.load(ctx.path));
            }


            $.when.apply($, loading).then(function () {
                    defer.resolve();
                },
                function () {
                    defer.reject();
                });

            return defer.promise();

        };


        View.exit = function (ctx) {

            State.last_view = name;

            // hide glossary window - not destroy it 
            // todo when close???
            // if (ctx.query.glossar) {
            //     GlossarLayer.hide();
            // }
        };


        View.destroy = function () {

            View.log('destroy');

            $(window).off('pswp-change');

            TopicTeaser.destroy();
            TaxonomyMenu.destroy();
            Interactive.exit();
            GlossarLayer.destroy();
            // PollLayer.destroy();
            _element_dropdown.destroy();

            if (State.is_topic_route) {
                State.is_topic_route = false;
            }

            if (_article) {
                _article.exit();
                _article = false;
            }

            State.current_topic = false;
            State.current_section = false;
            State.current_selection = false;
            State.current_article = false;
            State.current_gallery = false;
            State.current_glossary_layer = false;

            State.current_article_instance = false;

            if (_element.length) {
                _element.remove();
            }

            _initialized = false;
        };

        /**
         * todo extend pagejs with this or a controller for this 
         * Split glossary route parameters from url parameter ?glossar
         * Route: /glossar/:letter/:article/:gallery/:image
         * i.e. ?glossar=/glossar/d/druecker-47241/gallery-1/1
         *
         * Access: ctx.query.params.glossar.letter
         *
         * @param ctx
         */
        function parseQueryParams(ctx) {

            ctx.query.params = {};

            // url contains "glossar" parameter
            if (ctx.query.glossar) {
                var paths = ctx.query.glossar.split('/');
                // parse glossar route /glossar/:letter/:article/:gallery/:image
                ctx.query.params = {
                    glossar: {
                        letter: paths[2] ? paths[2] : '',
                        article: paths[3] ? paths[3] : '',
                        gallery: paths[4] ? paths[4] : '',
                        image: paths[5] ? paths[5] : ''
                    }
                };
            }
        }

        // ROUTE CONNECTOR ////////////////////////////////////////////////////
        /**
         * View.Routes
         * Provide Methos for Page.js router
         * Connect your View methods with the router
         */
        var _routes = {
            // Load and setup any View specific data
            load: function (ctx, next) {
                ctx.query = $().urlParseQuery(ctx.querystring);
                parseQueryParams(ctx);

                console.groupCollapsed(TYPE + name + ' | ' + ctx.path);
                console.log(ctx);

                if (State.is_first_load && next) {
                    State.is_first_load = false;
                    next();
                } else {
                    View.load(ctx).then(
                        // success
                        function () {
                            if (next) next();
                        },
                        // failed
                        function () {
                            View.log('):||||||||||||||:( loading error ):||||||||||||||:(');
                            ctx.state.error = true;
                            if (next) next();
                        }
                    );
                }
            },

            // Add nodes to the Dom, start listeners, setup environment
            init: function (ctx, next) {
                // parse url parameter for glossary layer
                ctx.query = $().urlParseQuery(ctx.querystring);
                parseQueryParams(ctx);
                // new ctx in State
                State.context = ctx;

                // continue without doing a thing on load errors
                if (ctx.state.error && next) {
                    next();
                } else {
                    if (!_initialized) View.init(ctx);
                    // continue
                    if (next) next();
                }
            },

            // show (fadeIn) the View;
            show: function (ctx, next) {
                // parse url parameter for glossary layer
                ctx.query = $().urlParseQuery(ctx.querystring);
                parseQueryParams(ctx);
                // new ctx in State
                State.context = ctx;

                // continue without doing a thing on load errors
                if (ctx.state.error && next) {
                    View.exit();
                    console.groupEnd();
                    PageChange.exit(ctx);

                    // triggers a reload here
                    next();

                } else {
                    View.show(ctx);
                    console.groupEnd();
                    PageChange.enter(ctx);

                }
            },

            // Remove nodes from the Dom, stop listeners, setup environment
            exit: function (ctx, next) {
                // parse url parameter for glossary layer
                ctx.query = $().urlParseQuery(ctx.querystring);
                parseQueryParams(ctx);
                // new ctx in State
                State.context = ctx;

                View.exit(ctx);
                View.log('exit', ctx, next);

                PageChange.exit(ctx);

                if (next) next();
            }

        };

        // PUBLIC METHODS /////////////////////////////////////////////////////
        return {
            Routes: _routes,
            destroy: View.destroy
        };
    }
);