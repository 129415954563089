// ==========================================================================================================
// ==== LAZYIMAGE CONTROLLER ================================================================================
// ==========================================================================================================

modulejs.define(
    // name
    'controller.lazyImage',
    // dependencies
    [
        'jQuery',
        'TweenMax'
    ],
    // constructor
    function ($, TweenMax) {
        // VIEW NAME //////////////////////////////////////////////////////////
        var name = 'LazyImage';

        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'Controller';

        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ',
            _element = [],
            _selector_lazy = '.lazy-image',
            _selector_lazy_image = '.lazy-image img',

            _class_lazy = 'lazy-image';

        // PRIVATE METHODS ////////////////////////////////////////////////////
        var Controller = {};

        Controller.log = function (string) {
            console.log(_log + string);
        };


        Controller.init = function (container) {

            $(_selector_lazy_image, container).lazyload({
                failure_limit: 12,
                placeholder: '/assets/gfx/placeholder.png',
                data_attribute: 'src',
                skip_invisible: false,
                appear: function () {
                    $(this).closest(_selector_lazy).addClass('loading');
                },
                load: function (image_left, settings) {

                    Controller.log('load');

                    $(this).closest(_selector_lazy).removeClass('loading').removeClass(_class_lazy);

                    TweenMax.fromTo($(this), 0.75, {autoAlpha: 0}, {
                        autoAlpha: 1, onComplete: function () {
                            $(this).closest(_selector_lazy).removeClass(_selector_lazy);
                        }
                    });

                }
            });
        };

        Controller.loadAll = function(container) {
            $(_selector_lazy_image, container).trigger('appear');
            $(_selector_lazy_image, container).find('img').trigger('appear');
        };

        // PUBLIC METHODS /////////////////////////////////////////////////////
        return {
            init: Controller.init,
            loadAll: Controller.loadAll
        }
    }
);
