// ==========================================================================================================
// ==== COOKIE CHECK MODULE CONTROLLER ======================================================================
// ==========================================================================================================

modulejs.define(
    // name
    'module.cookieCheck',
    // dependencies
    [
        'jQuery',
        'Cookies',
        'lscache',
        'service.config',
        'service.state'
    ],
    // constructor
    function ($, Cookies, lscache, Config, State) {
        // VIEW NAME //////////////////////////////////////////////////////////
        var name = 'CookieCheck';


        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'Module';


        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ',

            _selector_confirmation = '#cookie_confirmation',
            _selector_enable = '#cookie_enable',
            _element;


        // PRIVATE METHODS ////////////////////////////////////////////////////
        var Controller = {};


        Controller.log = function (string) {
            console.log(_log + string);
        };


        Controller.init = function (container) {

            Controller.log('init');

            Controller.checkCacheSupport();
            Controller.checkCookieSupport();

            if (State.cookies_disabled || State.cache_disabled) {
                Controller.requestEnable();
            } else {

                var cookieConfirmed = Cookies.get('cookieconsent_status');
                if (typeof cookieConfirmed !== 'undefined') {
                    State.cookies_disabled = false;
                }

                // var confirm = Cookies.get('confirmation');
                //
                // if (typeof confirm == 'undefined') {
                //
                //     Controller.requestConfirmation();
                // }
            }
        };

        Controller.checkCookieSupport = function () {
            Cookies.set('baunetzwissen', 'defined');
            var check = Cookies.get('baunetzwissen');

            if (check != 'defined') {
                // cookies seem not to work
                State.cookies_disabled = true;
                Controller.log('no cookie support');
            } else {
                Cookies.remove('baunetzwissen');
                State.cookies_disabled = false;

            }
        };

        Controller.checkCacheSupport = function () {

            lscache.set('baunetzwissen', 'defined');

            var check = lscache.get('baunetzwissen');

            if (check == null || check != 'defined') {
                State.cache_disabled = true;
                Controller.log('no cache support');
            }
        };

        // show confirmation dialog
        Controller.requestConfirmation = function () {
            Controller.log('requestConfirmation');

            var dialog = $(_selector_confirmation);

            if (dialog.length) {
                dialog.appendTo('body');
                dialog.on('click', 'a.dialog-confirm', function () {
                    dialog.remove();
                    Cookies.set('confirmation', true, {expires: Config.COOKIE_EXPIRES});
                })
            }
        };


        // show dialog to enable cookies
        Controller.requestEnable = function () {
            Controller.log('requestEnable');

            var dialog = $(_selector_enable);

            if (dialog.length) {
                dialog.appendTo('body');
                dialog.on('click', 'a.dialog-confirm', function (event) {
                    dialog.remove();
                });
            }
        };


        Controller.resetCookies = function () {
            // clear confirmation cookie
            Cookies.remove('confirmation');

            lscache.flush();
        };

        // PUBLIC METHODS /////////////////////////////////////////////////////
        return {
            init: Controller.init,
            resetCookies: Controller.resetCookies
        }
    }
);
