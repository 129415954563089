
// ==========================================================================================================
// ==== VIDEO MODULE CONTROLLER =============================================================================
// ==========================================================================================================

modulejs.define(
	// name
	'module.video',
	// dependencies
	[
		'jQuery',
		'shared.helper',
		'controller.video.embed'
	],
	// constructor
	function ( $, Helper, VideoEmbed ) {
		// VIEW NAME //////////////////////////////////////////////////////////
		var name = 'VideoOverlay';


		// STATIC VARS ////////////////////////////////////////////////////////
		var TYPE = 'Module';

			
		// PRIVATE VARS ///////////////////////////////////////////////////////
		var _log = TYPE + '.' + name + ' | ',

			_selector = '.video-link',
			_elements = $(),
			_overlays = {};
			

		// PRIVATE METHODS ////////////////////////////////////////////////////
		var Controller = {};


		Controller.log = function ( string ) {
			console.log( _log + string );
		};


		Controller.init = function (container) {

			_elements = $( _selector, container );

			if ( _elements.length == 0 ) return false;

			Controller.log('init');
			
			_elements.each( function() {

				var self = $(this),
					href = $(this).attr('href');

				VideoEmbed.createImage(href).then(function ($image) {
					self.append($image);
				});
				

				$(this).on('click', function (event) {

					var embed = VideoEmbed.create(href);

					if ( embed ) {

						var element = $('<div class="video-container" />');

						element.append(embed);
						
						$(this).after(element);

						return false;
					}
				});
			});
		};


		// PUBLIC METHODS /////////////////////////////////////////////////////
		return {
			init: Controller.init
		}
	}
);