
// ==========================================================================================================
// ==== CLOSEPAGE MODULE CONTROLLER =========================================================================
// ==========================================================================================================
/*
* @description Add a close x button to the current page, that leads to the last visited content page.
*
*/

modulejs.define(
	// name
	'module.closePage',
	// dependencies
	[
		'jQuery',
		'service.state'
	],
	// constructor
	function ( $, State ) {

		// VIEW NAME //////////////////////////////////////////////////////////
		var name = 'ClosePage';


		// STATIC VARS ////////////////////////////////////////////////////////
		var TYPE = 'Module',
			HTML = '<a href="" class="close-page" role="button"><span class="visibility-hidden">Seite schließen</span></a>';

			
		// PRIVATE VARS ///////////////////////////////////////////////////////
		var _log = TYPE + '.' + name + ' | ',

			_element = $(HTML),
			_element_current = $(),
			_element_wrapper = $();
			

		// PRIVATE METHODS ////////////////////////////////////////////////////
		var Controller = {};

		Controller.log = function ( string ) {
			console.log( _log + string );
		};

		Controller.init = function (container) {

			Controller.log('init');

			_element_wrapper = $(container);

			_element_current = _element.clone();
			
			_element_current.appendTo(_element_wrapper);

			_element_current.attr('href', State.last_content_route);

		};


		Controller.exit = function () {
			_element_wrapper = $();
			_element_current.remove();
			_element_current = $();
		};

		// PUBLIC METHODS /////////////////////////////////////////////////////
		return {
			init: Controller.init,
			exit: Controller.exit
		}
	}
);
