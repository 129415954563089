(function (window, modulejs) {

    modulejs.define(
        // name
        'shared.helper',
        // dependencies
        [
            'jQuery'
        ],
        function ($) {
            // VIEW NAME //////////////////////////////////////////////////////////
            var name = 'ControllerAppHelper';

            // STATIC VARS ////////////////////////////////////////////////////////
            var TYPE = 'Controller';

            // PRIVATE VARS ///////////////////////////////////////////////////////
            var _log = TYPE + '.' + name + ' | ',
                _transitionEndEvent,
                _animationEndEvent;

            // PRIVATE METHODS ////////////////////////////////////////////////////
            var Controller = {};

            Controller.log = function (string) {
                console.log(_log + string);
            };

            Controller.requestAnimationFrame = function (callback) {
                (window.requestAnimationFrame) ? window.requestAnimationFrame(callback) : setTimeout(callback(), 40);
            };

            Controller.whichTransitionEnd = function () {
                // Function from David Walsh: http://davidwalsh.name/css-animation-callback
                if (_transitionEndEvent) return _transitionEndEvent;

                var t,
                    el = document.createElement("fakeelement");

                var transitions = {
                    "transition": "transitionend",
                    "OTransition": "oTransitionEnd",
                    "MozTransition": "transitionend",
                    "WebkitTransition": "webkitTransitionEnd"
                };

                for (t in transitions) {
                    if (el.style[t] !== undefined) {
                        _transitionEndEvent = transitions[t];
                        return _transitionEndEvent;
                    }
                }

                _transitionEndEvent = 'undefined';
                return _transitionEndEvent;
            };

            Controller.whichAnimationEnd = function () {

                if (_animationEndEvent) return _animationEndEvent;
                var t,
                    el = document.createElement("fakeelement");

                var animations = {
                    "animation": "animationend",
                    "OAnimation": "oAnimationEnd",
                    "MozAnimation": "animationend",
                    "WebkitAnimation": "webkitAnimationEnd"
                };

                for (t in animations) {
                    if (el.style[t] !== undefined) {
                        return animations[t];
                    }
                }
            };


            Controller.uid = function () {
                return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
            };


            /**
             * Search array(list) of objects for object with attribute(attr) equal to value(val) an return index or false.
             *
             * @method finByAttr
             * @param list array
             * @param attr string
             * @param val string
             * @returns {boolean}
             */
            Controller.indexByAttr = function (list, attr, val) {
                var found = false;
                $.each(list, function (index, value) {
                    if (this[attr] == val) {
                        found = index;
                        return false;
                    }
                });

                return found;
            };


            /**
             * URL-Paramter auslesen
             * @method getParameterByName
             * @description get query parameter by name
             * @param name the name of the query parameter
             * @param url string [optional] uses current window url
             *
             * found here: http://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
             */
            Controller.getParameterByName = function (name, url) {

                if (!url) url = window.location.href;
                name = name.replace(/[\[\]]/g, "\\$&");
                var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                    results = regex.exec(url);
                if (!results) return null;
                if (!results[2]) return '';
                return decodeURIComponent(results[2].replace(/\+/g, " "));
            };

            // INITIALIZATION /////////////////////////////////////////////////////

            // PUBLIC METHODS /////////////////////////////////////////////////////
            return Controller;

        }
    );

})(window, modulejs);