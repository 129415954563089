// ==========================================================================================================
// ==== VIEW NEWSLETTER =====================================================================================
// ==========================================================================================================

/**
 * @description Methods that need to be called on every view
 */

modulejs.define(
    // name
    'view.newsletter',
    // dependencies
    [
        'jQuery',
        'service.state',
        'controller.meta',
        'module.closePage',
        'controller.pageChange'
    ],
    // constructor
    function ($, State, Meta, ClosePage, PageChange) {

        // VIEW NAME //////////////////////////////////////////////////////////
        var name = 'Newsletter';

        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'View';

        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ',

            _initialized = false,
            _selector = '.page',
            _selector_container = '[role=main]',

            _element = $();


        // PRIVATE METHODS ////////////////////////////////////////////////////
        var View = {};

        View.log = function (string) {
            console.log(_log + string);
        };

        View.init = function () {
            View.log('init');

            _element = $(_selector);

            var alleAuswaehlen = true;

            $('.check-all-option').on('click', function () {
                if (alleAuswaehlen) {
                    $(this).text('Alle abwählen');
                    $('.checkbox-topic').prop('checked', true);
                    alleAuswaehlen = false;
                    return false;
                } else {
                    $(this).text('Alle auswählen');
                    $('.checkbox-topic').prop('checked', false);
                    alleAuswaehlen = true;
                    return false;
                }
            });

            if (State.last_view == "Topic") {
                var slug = State.current_topic;
                $('#checkbox-' + slug).prop('checked', true);
            }


            // toggle newsletter examples on mobile  
/*
            $('.newsletter-examples-section h3').on('click.accordion', function (e) {
                if (!State.is_mobile) return;

                e.preventDefault();
                $(this).closest('.newsletter-examples-section').toggleClass('active');
            });
*/

            /*
            $('.newsletter-submit-button').on('click', function() {
                console.log('geclickt');
                $('.newsletter-submit-button').html('Angemeldet');
                $('.submit-success-message').css('display', 'block');
                return false;
            });
            */


            ClosePage.init(_selector_container);

            Meta.readFrom(_element);

            _initialized = true;
        };

        View.show = function () {
            View.log('show');

            if (!_initialized) View.init();

            TweenMax.fromTo(_element, 0.480, {y: '100%'}, {y: '0%'});

            State.current_view = name;
        };

        View.load = function (ctx) {

            // check if data is already cached

            View.log('Route.load');

            return $.get(ctx.path, function (data) {

                var $temp = $(data).filter('.page');

                $(window).trigger('pageload.event', [data]);

                $(_selector_container).append($temp);

                _element = $temp;
            });

        };

        View.exit = function (ctx) {

            var deferred = $.Deferred();

            $('.check-all-option').off('click');
            $('.newsletter-examples-section h3').off('click.accordion');

            if (_element.length) {

                TweenMax.to(_element, 0.36, {
                    y: '100%', onComplete: function () {
                        ClosePage.exit();
                        _element.remove();
                        deferred.resolve();
                    }
                });
            }

            State.last_view = name;

            return deferred.promise()
        };


        // ROUTE CONNECTOR ////////////////////////////////////////////////////
        /**
         * View.Routes
         * Provide Methos for Page.js router
         * Connect your View methods with the router
         */
        var _routes = {

            // Load and setup any View specific data
            load: function (ctx, next) {
                console.groupCollapsed(TYPE + name + ' | ' + ctx.path);

                if (State.is_first_load && next) {
                    State.is_first_load = false;
                    next();
                } else {
                    View.load(ctx).then(function () {
                        if (next) next();
                    });
                }

            },

            // Add nodes to the Dom, start listeners, setup environment
            init: function (ctx, next) {
                //View.log( 'Route.init' );
                View.init();
                // continue
                if (next) next();
            },

            // show (fadeIn) the View;
            show: function (ctx, next) {

                View.show();

                console.groupEnd();
                PageChange.enter(ctx);
            },

            // Remove nodes from the Dom, stop listeners, setup environment
            exit: function (ctx, next) {

                PageChange.exit(ctx);

                View.exit().then(function () {
                    if (next) next();
                });
            }

        };

        // PUBLIC METHODS /////////////////////////////////////////////////////
        return {
            Routes: _routes
        };
    }
);