// ==========================================================================================================
// ==== VIEW HOME ===========================================================================================
// ==========================================================================================================

/**
 * @description Methods for the landing page
 */

modulejs.define(
    // name
    'view.home',
    // dependencies
    [
        'jQuery',
        'TweenMax',
        'shared.helper',
        'service.state',
        'controller.meta',
        'controller.pageChange',
        'controller.lazyImage',
        'module.topicDropdown',
        'module.partnerSlider'
    ],
    // constructor
    function ($, TweenMax, Helper, State, Meta, PageChange, LazyImage, TopicDropdown, PartnerSlider) {

        // VIEW NAME //////////////////////////////////////////////////////////
        var name = 'Home';

        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'View';

        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ',

            _initialized = false,

            _selector = '[view=home]',
            _selector_body = '.body',
            _selector_container = '[role=main]',
            _selector_background = '.background',
            _selector_hover_item = '.home-hover-item',
            _selector_teaser = '.teaser-fullscreen',
            _selector_statistic = '.statistic',
            _selector_featured = '.teaser-hero',
            _selector_next_teaser = '.arrow-right',
            _selector_teaser_link = '.teaser-link',

            _attr_topic = 'data-topic-bg',

            _element = $(),
            _element_body = $(),
            _element_background = $(),
            _element_hover_items = $(),
            _element_teaser = $(),

            _element_dropdown,

            _current_bg = $(),
            _last_bg = $(),

            _hover_timeout,

            _teaser_tween,
            _tween_bg,
            _tween_teaser,
            _tween_body;

        var $nextTeaser = $();
        var $teaserBackground = $();


        // PRIVATE METHODS ////////////////////////////////////////////////////
        var View = {};

        View.log = function (string) {
            console.log(_log + string);
        };

        View.init = function () {
            View.log('init');

            // cache dom elements
            _element = $(_selector);
            _element_body = $(_selector_body);
            _element_background = $(_selector_background, _element);
            _element_hover_items = $(_selector_hover_item, _element);


            var teaser = $(_selector_featured);
            // var count = teaser.length;
            // var random = Math.round(Math.random() * (count - 1));

            /*
             * Featured Artikel durchblätterbar
             */
            $nextTeaser = $(_selector_teaser).find(_selector_next_teaser);
            $teaserBackground = $(_selector_teaser).find(_selector_featured);

            _element_teaser = $(teaser.get(0));
            _element_teaser.show();

            _element_dropdown = new TopicDropdown(_element);

            /**
             * Durchklicken der Featured Artikel auf der Startseite,
             * Infinite Loop
             */
            $nextTeaser.on('click', function (e) {
                e.preventDefault();
                var _nextTeaser = _element_teaser.index() + 1;
                if (_nextTeaser >= teaser.length) {
                    _nextTeaser = 0;
                }
                _element_teaser.hide();
                _element_teaser = $(teaser.get(_nextTeaser));
                _element_teaser.show();
                LazyImage.init(_element_teaser);
            });

            // mouse interaction and topic backgrounds only on desktop browsers
            if (!State.is_mobile) {
                // listen for events from the topic menu
                $(window)
                // mouse enter dropdown list
                    .on('topic.dropdown.mouseenter', function () {
                        View.log('topic.dropdown.mouseenter');

                        // disable home content
                        _element_body.removeClass('active');

                        // hide teaser and content
                        TweenMax.to(_element_teaser, 0.5, {autoAlpha: 0});
                        TweenMax.to(_element_body, 0.5, {autoAlpha: 0});

                    })
                    // mouse leave dropdown list
                    .on('topic.dropdown.mouseleave', function () {
                        View.log('topic.dropdown.mouseleave');

                        // enable home content
                        _element_body.addClass('active');

                        // reset partner slider
                        PartnerSlider.filter(false);

                        // stop color tween
                        if (_tween_bg) _tween_bg.kill();
                        _tween_bg = false;

                        // show teaser and content
                        TweenMax.to(_element_teaser, 0.5, {autoAlpha: 1});
                        TweenMax.to(_element_body, 0.5, {autoAlpha: 1});
                    })
                    // enter topic link in dropdown list
                    .on('topic.mouseenter', function (event) {
                        View.log('topic.mouseenter');

                        // read color attribute and topic
                        var bgColor = event.target.dataset.color,
                            topic = event.target.dataset.topicHoverBg;

                        // filter partner slider
                        PartnerSlider.filter(topic);

                        // stop current color tween
                        if (_tween_bg) _tween_bg.kill();

                        // start tween to topic color
                        _tween_bg = TweenMax.to(_element_background, 0.4, {
                            backgroundColor: bgColor, onComplete: function () {

                                View.log('Tween Complete');
                                var item = _element_hover_items.filter('[' + _attr_topic + '=' + topic + ']');
                                // console.log(item);
                                LazyImage.init(item);

                                item.show();

                                Helper.requestAnimationFrame(function () {
                                    item.addClass('active');
                                });

                                TweenMax.fromTo(item, 0.35, {autoAlpha: 0}, {autoAlpha: 1});
                                _current_bg = item;

                            }
                        });
                    })
                    // leave topic link in dropdown list
                    .on('topic.mouseleave', function () {
                        View.log('topic.mouseleave');

                        // if there is an active topic background;
                        if (_current_bg.length) {

                            var item = $(_current_bg);

                            // disable topic background
                            Helper.requestAnimationFrame(function () {
                                item.removeClass('active');
                            });

                            // hide topic background
                            TweenMax.to(item, 0.5, {
                                autoAlpha: 0, onComplete: function () {
                                    item.hide();
                                }
                            });

                            // reset active topic
                            _current_bg = $();
                        }
                    });

                /**
                 * Click on fullscreen background image will navigate to the article  
                 */
                $teaserBackground.on('click', function (e) {
                    e.preventDefault();
                    var url = $(this).find(_selector_teaser_link).attr('href');
                    if (url) {
                        page(url);
                    }
                });
            }

            // load teaser image
            LazyImage.init(_element_teaser);

            Meta.readFrom(_element);

            _initialized = true;
        };

        View.show = function (ctx) {
            View.log('show');

            State.current_view = name;
            State.last_content_route = ctx.path;

            if (!_initialized) {
                View.init();
            } else if (_element.length) {
                _element.appendTo($(_selector_container));
            }


            // open dropdown on desktop
            if (!State.is_mobile) {
                _element_dropdown.open();
            }
        };


        View.load = function (ctx) {

            // check if data is already cached

            View.log('Route.load');

            return $.get(ctx.path, function (data) {

                var $temp = $(data).filter('.page');
                
                $(window).trigger('pageload.event', [data]);

                $(_selector_container).append($temp);

                _element = $temp;
            });

        };


        View.exit = function (ctx) {

            if (_initialized) {
                if (_tween_bg) _tween_bg.kill();
                _element.remove();
                $(window)
                    .off('topic.dropdown.mouseenter')
                    .off('topic.dropdown.mouseleave')
                    .off('topic.mouseenter')
                    .off('topic.mouseleave');

                $nextTeaser.off('click');
                $nextTeaser = null;
                
                $teaserBackground.off('click');
                $teaserBackground = null;

                _element_dropdown.destroy();
            }

            _initialized = false;
            State.last_view = name;
        };


        // ROUTE CONNECTOR ////////////////////////////////////////////////////
        /**
         * View.Routes
         * Provide Methos for Page.js router
         * Connect your View methods with the router
         */
        var _routes = {

            // Load and setup any View specific data
            load: function (ctx, next) {

                console.groupCollapsed(TYPE + name + ' | ' + ctx.path);

                if (State.is_first_load && next) {
                    State.is_first_load = false;
                    next();
                } else {
                    View.load(ctx).then(function () {
                        if (next) next();
                    });
                }

            },

            // Add nodes to the Dom, start listeners, setup environment
            init: function (ctx, next) {
                //View.log( 'Route.init' );

                // continue
                if (next) next();
            },

            // show (fadeIn) the View;
            show: function (ctx, next) {

                View.show(ctx);

                console.groupEnd();
                PageChange.enter(ctx);
            },

            // Remove nodes from the Dom, stop listeners, setup environment
            exit: function (ctx, next) {
                //View.log( 'Route.exit' );
                View.exit();
                PageChange.exit(ctx);
                if (next) next();
            }

        };

        // PUBLIC METHODS /////////////////////////////////////////////////////
        return {
            Routes: _routes
        };
    }
);