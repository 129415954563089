// ==========================================================================================================
// ==== GLOSSARY LIST CONTROLLER ============================================================================
// ==========================================================================================================

modulejs.define(
	// name
	'controller.glossaryList',
	// dependencies
	[
		'jQuery',
		'shared.helper',
		'controller.ajax'
	],
	// constructor
	function ( $, Helper, Ajax ) {
		// VIEW NAME //////////////////////////////////////////////////////////
		var name = 'GlossaryList';

		// STATIC VARS ////////////////////////////////////////////////////////
		var TYPE = 'Controller';

		// PRIVATE VARS ///////////////////////////////////////////////////////
		var _log = TYPE + '.' + name + ' | ',
			_selector = '.glossary-list',
			_attr_id = 'data-id',
			_element = [],

			_initialized = false,
			_loading = false,
			_loading_url = false;

		// PRIVATE METHODS ////////////////////////////////////////////////////
		var Controller = {};

		Controller.log = function ( string ) {
			console.log( _log + string );
		};

		Controller.init = function () {
			Controller.log('init');
			
			_element = $(_selector);

			_initialized = true;
		};

		Controller.load = function (url) {
			if(!_loading && _initialized) {
				
				_loading = true;

				_element.addClass('loading');
			}

			_loading_url = url;

			return Ajax.load(url, {module:'teaser'}).then(function (data) {
				Controller.onLoad(data, url);
			});
		};

		// set item with "articleId" active;
		Controller.update = function (articleId) {

			$('.active', _element).removeClass('active');

			if (articleId) {	
				$('[' + _attr_id + '=' + articleId + ']', _element).addClass('active');
			}
		};


		Controller.onLoad = function (data, url) {
			if (url != _loading_url) return;

			Controller.log('onLoad');
			// console.log(data);

			if (_initialized) {
				_element.removeClass('loading');
				_loading = false;
				_loading_url = '';

				var $temp = $(data).find(_selector);
				console.log($temp);

				_element.replaceWith($temp);
				_element = $temp;
			}
		};


		Controller.destroy = function () {
			_element.remove();
			_loading = false;
			_loading_url = '';
			_initialized = false;
		};


		Controller.getElement = function () {
			if (!_initialized) return [];

			return _element;
		};


		// PUBLIC METHODS /////////////////////////////////////////////////////
		return {
			load: Controller.load,
			init: Controller.init,
			update: Controller.update,
			destroy: Controller.destroy,
			getElement: Controller.getElement
		}
	}
);
