// ==========================================================================================================
// ==== RATHSCHECK SCHIEFER POLLING LAYER MODULE CONTROLLER =================================================
// ==========================================================================================================

modulejs.define(
    // name
    'module.pollLayer',
    // dependencies
    [
        'jQuery',
        'TweenMax',
        'service.state',
        'controller.ajax',
        'lscache'
    ],
    // constructor
    function ($, TweenMax, State, Ajax, lscache) {
        // VIEW NAME //////////////////////////////////////////////////////////
        var name = 'PollLayer';
        var campaignName = '2017_rathscheck';

        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'Module';


        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ',

            _selector_container = '[view=topic]',
            _selector = '.profession-poll',
            _selector_close = '.btn-close-panel',
            _selector_page1 = '.poll-page-1',
            _selector_page2 = '.poll-page-2',

            _element = $(),
            _element_form = $(),
            _element_page1 = $(),
            _element_page2 = $(),
            _element_close = $(),
            _loading_url = '',
            _loading = false,
            _sending = false,
            _initialized = false,
            _wasVisible = false;

        // PRIVATE METHODS ////////////////////////////////////////////////////
        var Controller = {};

        Controller.log = function (string) {
            var now = new Date(Date.now());
            console.log(_log, string, now.toLocaleTimeString() + '.' + now.getMilliseconds(), Array.prototype.slice.call(arguments));
        };

        Controller.init = function () {

            Controller.log('init');

            _element = $(_selector);
            if (_element.length) {
                _element_close = $(_selector_close, _element);
                _element_form = _element.find('form');
                _element_page1 = _element.find(_selector_page1);
                _element_page2 = _element.find(_selector_page2);

                // close layer
                _element_close.off('click.close');
                _element_close.on('click.close', function (e) {
                    Controller.close();
                });

                // form submit
                _element_form.off('submit');
                _element_form.on('submit', Controller.send);

                _initialized = true;
            }
        };

        Controller.wasVisible = function () {
            if (!_initialized) return;

            if (!_wasVisible) {
                // check cookies
                _wasVisible = !!lscache.get(campaignName);
            }

            return _wasVisible;
        };

        Controller.load = function (url) {

            // prepare loading if not already loading
            if (!_loading && _initialized) {

                _loading = true;
                _element.addClass('loading');
            }

            _loading_url = url;

            return Ajax.load(url, {module: 'schiefer.poll'}).then(function (data) {
                Controller.onLoad(data, url);
            });
        };

        Controller.onLoad = function (data, url) {
            if (url !== _loading_url) return;

            Controller.log('onLoad | initialized: ' + _initialized);
            _loading = false;
            var $data = $(data);

            if (!_initialized) {
                $(_selector_container).append($data.find(_selector));
            }
        };

        Controller.show = function () {

            if (!_initialized) Controller.init();

            // already shown?
            if (Controller.wasVisible()) return;

            Controller.log('show');

            _element.addClass('active');
        };

        Controller.close = function () {
            if (!_initialized) return;

            // wurde bereits angesehen setzen 
            lscache.set(campaignName, '1');
            _wasVisible = true;

            Controller.log('close');
            _element.removeClass('active');
        };

        /**
         * Send user data to server
         *
         * @param e
         * @returns {boolean}
         */
        Controller.send = function (e) {
            e.preventDefault();

            Controller.log('send', _element_form.serialize());

            var dataKeys = {};
            $.each(_element_form.serializeArray(), function (i, value) {
                if (typeof value === 'object' && 'name' in value && 'value' in value) {
                    dataKeys[value.name] = value.value;
                }
            });

            // Pflichtfeld mind. content
            var content = dataKeys['profession'];
            if (dataKeys['profession-text']) {
                content = dataKeys['profession-text'];
            }

            if (!content) {
                alert('Bitte geben Sie Daten an.');
                return;
            }

            if (_sending) {
                // nur einmal versenden 
                return;
            }

            // todo nur einmal versenden 
            _sending = true;

            $.ajax({
                url: _element_form.attr('action'),
                method: 'post',
                cache: false,
                dataType: 'json',
                data: {
                    content: content,
                    topic: dataKeys['topic'],
                    campaign: dataKeys['campaign'],
                    url: window.location.href ? window.location.href : '',
                    referrer: document.referrer ? document.referrer : State.referrer
                }
            }).then(function (data) {

                Controller.log('successfull send', data);
                console.info(data['success'], data['message'], data);

                _sending = false;

                if (data['success'] === true) {

                    _element_page1.removeClass('active');
                    _element_page2.addClass('active');

                } else {
                    if (typeof data['message'] === 'string') {
                        alert('Fehler: ' + data['message']);
                    }
                }

            }).fail(function (jqXHR, textStatus, errorThrown) {

                alert('Es ist ein Fehler beim Versenden aufgetreten. Bitte versuchen Sie es später erneut.');
                console.warn(jqXHR, textStatus, errorThrown);

            });

            return false;
        };

        Controller.destroy = function () {

            if (!_initialized) return false;

            Controller.close();
            Controller.log('exit');

            _element.remove();
            _element = $();
            _element_close = $();

            _loading = false;
            _initialized = false;
        };

        // PUBLIC METHODS /////////////////////////////////////////////////////
        return {
            load: Controller.load,
            init: Controller.init,
            close: Controller.close,
            show: Controller.show,
            destroy: Controller.destroy
        }
    }
);
