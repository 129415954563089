// ==========================================================================================================
// ==== ROUTE CONTROLLER ====================================================================================
// ==========================================================================================================

modulejs.define(
    // name
    'settings.route',
    // dependencies
    [
        'jQuery',
        'page',
        'view.exception',
        'view.default',
        'view.home',
        'view.topic',
        'view.glossary',
        'view.watchlist',
        'view.watchlist.shared',
        'view.search',
        'view.newsletter',
        'view.history'
    ],
    // constructor
    function ($, Page, ViewException, ViewDefault, ViewHome, ViewTopic, ViewGlossary, ViewWatchlist, ViewWatchlistShared, ViewSearch, ViewNewsletter, ViewHistory) {

        // VIEW NAME //////////////////////////////////////////////////////////
        var name = 'Route';

        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'Controller',

            ROUTES = [
                {
                    'url': '/',
                    'name': 'home',
                    'controller': ViewHome
                },
                {
                    'url': '/about',
                    'name': 'about',
                    'controller': ViewDefault
                },
                {
                    'url': '/datenschutz',
                    'name': 'datenschutz',
                    'controller': ViewDefault
                },
                {
                    'url': '/impressum',
                    'name': 'imprint',
                    'controller': ViewDefault
                },
                {
                    'url': '/impressum/:topic',
                    'name': 'imprint.topic',
                    'controller': ViewDefault
                },
                {
                    'url': '/nutzungsbedingungen',
                    'name': 'disclaimer',
                    'controller': ViewDefault
                },
                {
                    'url': '/newsletter',
                    'name': 'newsletter',
                    'controller': ViewNewsletter
                },
                {
                    'url': '/newsletter/aktivierung',
                    'name': 'newsletter',
                    'controller': ViewNewsletter
                },
                {
                    'url': '/newsletter/abmeldung',
                    'name': 'newsletter',
                    'controller': ViewDefault
                },
                {
                    'url': '/verlauf',
                    'name': 'history',
                    'controller': ViewHistory
                },
                {
                    'url': '/merkliste',
                    'name': 'watchlist',
                    'controller': ViewWatchlist
                },
                {
                    'url': '/merkliste/:id',
                    'name': 'watchlist.shared',
                    'controller': ViewWatchlistShared
                },
                {
                    'url': '/glossar/:letter',
                    'name': 'glossary',
                    'controller': ViewGlossary
                },
                {
                    'url': '/glossar/:letter/:article',
                    'name': 'glossary.article',
                    'controller': ViewGlossary
                },
                {
                    'url': '/glossar/:letter/:article/:gallery/:image',
                    'name': 'glossary.article',
                    'controller': ViewGlossary
                },
                {
                    'url': '/suche',
                    'name': 'search',
                    'controller': ViewSearch
                },
                {
                    'url': '/:topic',
                    'name': 'topic',
                    'controller': ViewTopic
                },
                {
                    'url': '/:topic/:section/:selection',
                    'name': 'topic',
                    'controller': ViewTopic
                },
                {
                    'url': '/:topic/:section/:selection/:article',
                    'name': 'topic.article',
                    'controller': ViewTopic
                },
                {
                    'url': '/:topic/:section/:selection/:article/:gallery/:image',
                    'name': 'topic.gallery',
                    'controller': ViewTopic
                }
            ];

        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ',
            _element = [];

        // PRIVATE METHODS ////////////////////////////////////////////////////
        var Controller = {};

        Controller.log = function (string) {
            console.log(_log + string);
        };

        Controller.init = function () {

            Controller.log('init');

            if (window.location.pathname === '/index.php') {
                //Umleiten auf /
                window.location.href = '/';
                return;
            }

            $.each(ROUTES, function (index, value) {

                var url = value['url'],
                    route = value['controller'].Routes;

                page(url, route.load, route.init, route.show);
                page.exit(url, route.exit);

            });

            page('*', ViewException.Routes.show);

            page({
                click: true, // enable for ajax
                dispatch: true
            });

        };

        // PUBLIC METHODS /////////////////////////////////////////////////////
        return {
            init: Controller.init
        }
    }
);
