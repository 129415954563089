// ==========================================================================================================
// ==== PARTNER SLIDER MODULE CONTROLLER ====================================================================
// ==========================================================================================================

modulejs.define(
    // name
    'module.partnerSlider',
    // dependencies
    [
        'jQuery',
        'shared.helper'
    ],
    // constructor
    function ($, Helper) {
        // VIEW NAME //////////////////////////////////////////////////////////
        var name = 'PartnerSlider';


        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'Module';


        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ',

            _selector = '#partner_slider .partner-slides',
            _element = $(),

            _filter = false,
            _slider;


        // PRIVATE METHODS ////////////////////////////////////////////////////
        var Controller = {};

        Controller.STYLE_GALLERY = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 500,
            fade: true,
            cssEase: 'linear',
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000
        };

        Controller.log = function (string) {
            console.log(_log + string);
        };

        Controller.init = function (container, style) {

            Controller.log('init');

            if (!style) style = Controller.STYLE_GALLERY;

            _element = $(_selector, container);

            $(_element).slick(style);

        };


        Controller.filter = function (topic) {

            if (topic === _filter) return;

            Controller.log('filter | ' + topic);

            if (topic) {
                if (_filter) {
                    $(_element).slick('slickUnfilter');
                }
                // apply filter
                var selector = '[data-topic="' + topic + '"]';
                $(_element).slick('slickFilter', selector);
            } else {
                // reset filter
                $(_element).slick('slickUnfilter');
            }

            _filter = topic;
        };


        // PUBLIC METHODS /////////////////////////////////////////////////////
        return {
            init: Controller.init,
            filter: Controller.filter
        }
    }
);
