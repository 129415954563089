// ==========================================================================================================
// ==== EYE CATCHER MODULE CONTROLLER ============================================================================
// ==========================================================================================================

modulejs.define(
    // name
    'module.glossarLayer',
    // dependencies
    [
        'jQuery',
        'page',
        'TweenMax',
        'shared.helper',
        'controller.ajax',
        'controller.article',
        'service.state'
    ],
    // constructor
    function ($, page, TweenMax, Helper, Ajax, Article, State) {
        // VIEW NAME //////////////////////////////////////////////////////////
        var name = 'GlossarLayer';


        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'Module';


        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ',

            _selector = '[view="glossary-layer"]',
            _selector_container = '[view=topic]',
            _selector_close = '.btn-close-panel',
            _selector_panel = '.panel',
            _selector_wrapper = '.wrapper',
            _selector_glossary_links = '.glossarlink',
            _selector_article = '[view="article"]',

            _element = $(),
            _element_close = $(),
            _element_panel = $(),
            _element_wrapper = $(),

            _article = false,

            _loading_url = '',
            _loading = false,
            _initialized = false;

        // PRIVATE METHODS ////////////////////////////////////////////////////
        var Controller = {};

        Controller.log = function (string) {
            var now = new Date(Date.now());
            console.log(_log, string, now.toLocaleTimeString() + '.' + now.getMilliseconds());
        };

        Controller.init = function () {

            Controller.log('init');

            _element = $(_selector);
            _element_close = $(_selector_close, _element);
            _element_panel = $(_selector_panel, _element);
            _element_wrapper = $(_selector_wrapper, _element);

            // close layer
            _element_close.off('click.close');
            _element_close.on('click.close', function (e) {
                Controller.close();
            });

            _initialized = true;
        };

        Controller.show = function () {
            Controller.log('show');

            // reset scrollTop position to 0
            _element_panel.attr('data-scrollTop', '0');

            // show panel if hidden 
            if (!State.is_mobile && !_element.is(':visible')) {
                _element.show();
            }
        };

        Controller.hide = function () {
            _element.hide();
        };

        Controller.close = function () {
            Controller.log('close');

            if (!State.current_glossary_layer) return;

            if (!State.is_mobile && !_loading) {
                if (_article) {
                    _article.exit();
                }
                _element.hide();

                // State.current_glossary_layer = false;

                // return to topic article url
                // not glossary only for Topic view reset route 
                if (State.current_view === 'Topic') {
                    page.show('/' + State.current_topic + '/' + State.current_section + '/' + State.current_selection + '/' + State.current_article);
                }
            }
        };

        Controller.load = function (url) {
            Controller.log('load: ' + url);

            if (typeof url !== 'string' || url === '') {
                return;
            }

            // prepare loading if not already loading
            if (!_loading && _initialized) {
                TweenMax.to(_element_wrapper, 0.2, {autoAlpha: 0});

                _loading = true;
                _element.addClass('loading');
            }

            _loading_url = url;

            return Ajax.load(url, {module: 'layer'}).then(function (data) {
                Controller.onLoad(data, url);
            });
        };

        Controller.onLoad = function (data, url) {

            if (url !== _loading_url) return;

            Controller.log('onLoad | initialized: ' + _initialized);

            // create element in topic view if removed (by switching the view)
            if (!_initialized) {
                $(_selector_container).append($(data));
                Controller.init();
            }

            if (_initialized) {
                Controller.show();

                _element.removeClass('loading');
                _loading = false;
                _loading_url = '';

                var $data = $(data);

                // override layer content with new data 
                _element_wrapper.html($data.find(_selector_wrapper).html());

                _article = new Article({element: $(_selector_article, _element), container: _element});

                // todo change urls on server with module parameter 'layer'?
                // layer internal glossary links change path to new glossar url parameter  
                $(_selector_glossary_links, _element).each(function () {
                    var path = $(this).attr('href');
                    if (path && path.indexOf('?glossar=') === -1) {
                        $(this).attr('href', '?glossar=' + path);
                    }
                });

                // change gallery links to new glossar url parameter
                _element.find('.gallery-link').each(function () {
                    var path = $(this).attr('href');
                    if (path && path.indexOf('?glossar=') === -1) {
                        $(this).attr('href', '?glossar=' + path);
                    }
                });

                _article.show();
                _article.getElement().find('> .panel').attr('data-scrollTop', 0);

                Helper.requestAnimationFrame(function () {
                    var w = _element_wrapper.outerWidth();
                    var h = _element_wrapper.outerHeight();

                    // position of active article, left to it 
                    var $article = $('> [view=article] > .panel', _selector_container);
                    if ($article.length) {
                        var x = $article.offset().left - w - 20;
                        TweenMax.set(_element, {x: x, width: w});
                    }

                    TweenMax.to(_element_panel, 0.45, {
                        height: h, onComplete: function () {
                            _element_panel.attr('style', '');
                        }
                    });

                    TweenMax.to(_element_wrapper, 0.3, {autoAlpha: 1, delay: 0.65});
                });
            }
        };

        Controller.destroy = function () {

            if (!_initialized) return false;

            Controller.close();
            Controller.log('exit');

            if (_article) {
                _article.exit();
            }

            State.current_glossary_layer = false;

            _element.remove();
            _element = $();
            _element_close = $();
            _initialized = false;
            _article = null;
        };

        Controller.getArticle = function () {
            return _article;
        };

        Controller.getElement = function () {
            return _element;
        };

        // PUBLIC METHODS /////////////////////////////////////////////////////
        return {
            init: Controller.init,
            load: Controller.load,
            close: Controller.close,
            hide: Controller.hide,
            show: Controller.show,
            destroy: Controller.destroy,
            getArticle: Controller.getArticle,
            getElement: Controller.getElement
        }
    }
);
