// ==========================================================================================================
// ==== VIEW GLOSSARY =======================================================================================
// ==========================================================================================================

/**
 * @description Methods for the glossary view
 */

modulejs.define(
    // name
    'view.glossary',
    // dependencies
    [
        'jQuery',
        'shared.helper',
        'service.config',
        'service.state',
        'controller.meta',
        'controller.pageChange',
        'module.topicDropdown',
        'module.closePage',
        'controller.article',
        'controller.glossaryList',
        'controller.history',
        'controller.watchlist'
    ],
    // constructor
    function ($, Helper, Config, State, Meta, PageChange, TopicDropdown, ClosePage, Article, GlossaryList, History, Watchlist) {

        // VIEW NAME //////////////////////////////////////////////////////////
        var name = 'Glossary';

        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'View';

        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ',

            _initialized = false,
            _selector = '[view=glossary]',
            _selector_container = '[role=main]',
            _selector_content = '[view=glossary] .page-glossary > .content',

            _selector_alphabet = '.alphabetic-selector',
            _selector_article = '[view="article"]',

            _attr_alphabet_item = 'data-letter',

            _element = [],
            _element_dropdown,
            _element_alphabet,
            _element_content,

            _article = false,
            _next_article = false,

            _loading = false,

            _selected_letter = false;


        // PRIVATE METHODS ////////////////////////////////////////////////////
        var View = {};

        View.log = function (string) {
            var now = new Date(Date.now());
            console.log(_log, string, now.toLocaleTimeString() + '.' + now.getMilliseconds());
        };

        View.init = function (ctx) {
            View.log('init');

            _element = $(_selector);

            _element_dropdown = new TopicDropdown(_element);

            _element_alphabet = $(_selector_alphabet);

            _element_content = $(_selector_content);

            GlossaryList.init();

            ClosePage.init(_selector);

            _initialized = true;
        };

        View.show = function (ctx) {
            View.log('show');
            //console.log(ctx);

            if (!_initialized) View.init(ctx);

            var initial_show = !State.is_glossary_route;

            State.is_glossary_route = true;
            State.current_view = name;
            State.glossaryDestroy = View.destroy;

            var article = ctx.params.article || 0,
                letter = ctx.params.letter || 0,
                gallery = ctx.params.gallery || 0,
                galleryImage = ctx.params.image || 0,
                topic = Helper.getParameterByName('thema', ctx.path),
                changeArticle = article != State.current_glossary_article;

            _selected_letter = ctx.params.letter || 0;

            View.log('changeArticle ' + changeArticle);
            View.log('article ' + article);
            View.log('_article ' + _article);
            View.log('State.current_article ' + State.current_glossary_article);

            _element_dropdown.update(topic);

            // remove outdated article
            if (changeArticle && _article) {
                _article.exit();
                _article = false;
            }

            if (_next_article) {
                // swap current article
                _article = _next_article;
                _next_article = false;
            } else if (article && changeArticle) {
                // there is a new article that was not initiated on load => first load
                _article = new Article({element: $(_selector_article, _element), container: _element_content});
            }

            if (_article && changeArticle) {
                // show article if it is a new one
                _article.show();

                _article.sticky(_element);

                // update teaser list active
                GlossaryList.update(_article.getId());

                var history = [
                    _article.getId(),
                    Date.now()
                    //,State.current_topic
                ];

                var watchlist = [_article.getId()];

                Watchlist.enable(watchlist);
                History.add(history);

                Meta.readFrom(_article.getElement());

            } else if (!_article) {
                GlossaryList.update(false);

                Meta.readFrom(GlossaryList.getElement());
            }


            $('.active', _element_alphabet).removeClass('active');

            $('[' + _attr_alphabet_item + '="' + letter + '"]', _element_alphabet).addClass('active');
            $('[' + _attr_alphabet_item + '] > a', _element_alphabet).each(function () {

                var $this = $(this),
                    href = $this.attr('data-base-href');

                if (topic && topic.length) {
                    href += '?thema=' + topic;
                }

                $this.attr('href', href);
            });


            if (article && gallery && galleryImage && gallery != State.current_glossary_gallery) {
                _article.showGallery(gallery, galleryImage);

                // when gallery change image, change url with gallery-index + image-index 
                $(window).on('pswp-change', function (event, index) {
                    page.show(Config.GLOSSARY_URL + '/' + letter + '/' + article + '/' + State.current_glossary_gallery + '/' + (index + 1));
                });

                // when gallery closes reset url to article
                $(window).one('pswp-close', function () {
                    var path = Config.GLOSSARY_URL + '/' + letter + '/' + article;
                    if (topic) {
                        path += '?thema=' + topic;
                    }
                    page.show(path);
                });

            } else if (State.current_glossary_gallery && !gallery) {
                _article.closeGallery();
            }


            State.current_glossary_gallery = gallery;
            State.current_glossary_article = article;
            State.current_glossary_topic = topic;
            State.current_article_instance = _article;

            var deferred = $.Deferred();

            if (initial_show) {
                PageChange.startTransition();

                TweenMax.fromTo(_element, 0.360, {y: '100%'}, {
                    y: '0%', ease: Power2.easeOut, onComplete: function () {

                        // remove transform styles to avoid unpredictable position:fixed behavior
                        _element.attr('style', '');

                        deferred.resolve();
                        PageChange.stopTransition();
                    }
                });
            } else {
                deferred.resolve();
            }

            return deferred.promise();
        };

        View.load = function (ctx) {

            View.log('Route.load');

            var article = ctx.params.article || 0,
                letter = ctx.params.letter || 'a',
                topic = Helper.getParameterByName('thema', ctx.path),
                changeLetter = ctx.params.letter != _selected_letter,
                changeTopic = topic != State.current_glossary_topic,
                changeArticle = article && article != State.current_glossary_article;


            if (!_initialized) {
                return $.get(ctx.path, function (data) {

                    var $temp = $(data).filter('.page');
                    
                    $(window).trigger('pageload.event', [data]);

                    $(_selector_container).append($temp);

                    _element = $temp;
                });
            } else {
                var loading = [],
                    defer = $.Deferred();

                // load article
                if (article && changeArticle) {

                    _next_article = new Article({container: _element_content});
                    loading.push(_next_article.load(ctx.path));

                }

                // load glossary list
                if (changeLetter || changeTopic) {

                    loading.push(GlossaryList.load(ctx.path));

                }

                $.when.apply($, loading).done(function () {
                    defer.resolve();
                });

                return defer.promise();
            }


        };


        View.exit = function (ctx) {

            View.log('article ' + ctx.params.article);
            View.log('_article ' + _article);
            View.log('State.current_article ' + State.current_glossary_article);
            
            View.log('exit', ctx);

            State.last_view = name;
        };

        View.destroy = function () {

            $(window).off('pswp-change');
            
            _element_dropdown.destroy();
            GlossaryList.destroy();

            _initialized = false;

            if (_element.length) {
                _element.remove();
            }

            State.is_glossary_route = false;
            State.current_glossary_topic = false;
            State.current_glossary_gallery = false;
            State.current_glossary_article = false;

        };


        // ROUTE CONNECTOR ////////////////////////////////////////////////////
        /**
         * View.Routes
         * Provide Methos for Page.js router
         * Connect your View methods with the router
         */
        var _routes = {

            // Load and setup any View specific data
            load: function (ctx, next) {
                console.groupCollapsed(TYPE + name + ' | ' + ctx.path);

                if (State.is_first_load && next) {
                    State.is_first_load = false;
                    next();
                } else {
                    View.load(ctx).then(function () {
                        if (next) next();
                    });
                }

            },

            // Add nodes to the Dom, start listeners, setup environment
            init: function (ctx, next) {
                //View.log( 'Route.init' );

                // continue
                if (next) next();
            },

            // show (fadeIn) the View;
            show: function (ctx, next) {

                console.groupEnd();

                View.show(ctx).then(function () {
                    PageChange.enter(ctx);
                });
            },

            // Remove nodes from the Dom, stop listeners, setup environment
            exit: function (ctx, next) {
                //View.log( 'Route.exit' );
                View.exit(ctx);

                PageChange.exit(ctx);

                if (next) next();
            }

        };

        // PUBLIC METHODS /////////////////////////////////////////////////////
        return {
            Routes: _routes
        };
    }
);