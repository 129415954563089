/**
 * (c) webXells GmbH
 *
 * This computer program is the sole property of webXells GmbH
 * (http://www.webXells.com) and is protected under the German Copyright Act
 * (paragraph 69a UrhG). All rights are reserved. Making copies,
 * duplicating, modifying, using or distributing this computer program
 * in any form, without prior written consent of webXells, is
 * prohibited. Violation of copyright is punishable under the
 * German Copyright Act (paragraph 106 UrhG). Removing this copyright
 * statement is also a violation.
 *
 * @author: Christian Lahrz-Beckmann
 * @since: 15.12.2016 - 14:30
 * @version: 0.0.1
 * @project: Baunetzwissen
 * @file:
 * @description: Webtrekk Service
 */

modulejs.define(
    // name
    'service.webtrekk',
    // dependencies
    [
        'jQuery',
        'service.state',
        'shared.helper'
    ],
    // constructor
    function ($, State, Helper) {
        //Custom Groups
        var cg1 = 'baunetzwissen'; //projectname baunetzwissen
        var cg2;
        var cg3;
        var cg4;
        var cg5;
        var contentId;

        /**
         * @var object
         */
        var wt;

        var WebtrekkService = {
            /**
             * Prüft ob Webtrekk Skript integriert wurde
             * @returns {boolean}
             */
            isAvailable: function () {
                return typeof webtrekkV3 !== 'undefined';
            },
            isTeaserPluginAvailable: function () {
                return typeof wt_teaserTracking !== 'undefined';
            },
            init: function () {
                var me = this;
                if (!me.isAvailable()) {
                    return false;
                }

                //general config
                var pageConfig = {
                    linkTrack: "link", 	// Activate Link Tracking [link or standard]
                    heatmap: "0", 		// Activate Heatmap Tracking [1 = on | 0 = off]
                    form: "",			// Activate Form Tracking [1 = on | 0 = off]
                    contentId: ''
                };

                //singleton
                wt = typeof wt === 'object' ? wt : new webtrekkV3(pageConfig);

                //Teaser Tracking Plugin aktivieren
                if (me.isTeaserPluginAvailable()) {
                    wt.executePluginFunction = 'wt_teaserTracking';
                }

                me.initEventListener();

                return true;
            },

            initEventListener: function () {
                var me = this;

                //kleine Bildergalerie im Artikel wechselt Bild  
                $(window).on('slick.changed', function () {
                    me.process();
                    //Direkt austauschen von article zu slider (suffix der contentId)
                    // wt.contentId = wt.contentId.replace('.article', '.slider');
                    me.setContentId(cg1, cg2, cg3, cg4, cg5, 'slider');
                    me.send();
                });

                //Seitenwechsel tracken (bei Initialisierung wird ebenfalls ein PageChange getriggert)
                $(window).on('PageChange', function () {
                    //run
                    me.process();
                    me.send();
                });

                //Print detection
                //Quelle: https://www.tjvantoll.com/2012/06/15/detecting-print-requests-with-javascript/
                if (window.matchMedia) {
                    var mediaQueryList = window.matchMedia('print');
                    mediaQueryList.addListener(function (mql) {
                        if (mql.matches) {
                            me.trackPrint();
                        }
                    });
                }

                window.onbeforeprint = WebtrekkService.trackPrint;
            },

            trackPrint: function () {
                WebtrekkService.process();
                WebtrekkService.setContentId(cg1, cg2, cg3, cg4, cg5, 'drucken');
                WebtrekkService.send();
            },

            getWebtrekkPixel: function () {
                return wt;
            },

            /**
             * Sendeprozess vorbereiten
             */
            process: function () {
                var me = this;

                me.buildContentGroups();
                me.customParameter.build();
                //Werbeblockerverfolgung deaktiviert
                //me.Pecunia.send();

                //Suche nach nachgeladenem Teaser machen
                if (me.isTeaserPluginAvailable()) {
                    if (typeof wt_tt !== 'undefined' &&
                        typeof wt_tt.tt !== 'undefined') {
                        wt_tt.tt.searchTeaser();
                    }
                }

                if (typeof console.group !== 'undefined') {
                    console.group('service.webtrekk');
                    console.log('WEBTREKK GROUPS ', cg1, '|', cg2, '|', cg3, '|', cg4, '|', cg5);
                    console.log('WEBTREKK ID ', me.getContentId());
                    console.log('WEBTREKK CUSTOM PARAMETER ', me.getCustomParameter());
                    console.log('STATE ', State);
                    console.groupEnd();
                }
            },

            send: function () {
                // don't track with api_preview_url in url
                var preview = window.location.search.indexOf('api_preview_url') >= 0;
                var host = window.location.host ? window.location.host : window.location.hostname;
                var live = host.indexOf('baunetzwissen.de') > -1;
                
                if (!preview && live) {
                    wt.linkTrackInit();
                    wt.sendinfo();
                }
            },

            getContentGroups: function () {
                return wt.contentGroup;
            },

            buildContentGroups: function () {
                var me = this;
                if (State.is_topic_route) {
                    cg2 = State.current_topic;
                    cg3 = State.current_section ? State.current_section : 'index';
                    cg4 = State.current_selection ? State.current_selection : 'index';
                    cg5 = State.current_article ? State.current_article : 'index';
                    me.setContentId(cg1, cg2, cg3, cg4, cg5, 'index');
                }
                if (State.is_glossary_route || (State.is_topic_route && State.current_glossary_layer)) {
                    cg2 = State.current_glossary_topic ? State.current_glossary_topic : 'home';
                    cg3 = 'allgemein';
                    cg4 = 'glossar';
                    cg5 = State.current_glossary_article ? State.current_glossary_article : 'glossar';
                    me.setContentId(cg1, cg2, cg3, cg4, cg5, 'glossar');
                }

                //Home, Impressum, Suche, ...
                if (State.current_view == 'Home') {
                    cg2 = 'home';
                    cg3 = 'index';
                    cg4 = 'index';
                    cg5 = 'index';
                    me.setContentId(cg1, cg2, cg3, cg4, cg5, 'index');
                }
                if (State.current_view == 'Search') {
                    cg2 = State.current_search_topic ? State.current_search_topic : 'home';
                    cg3 = 'allgemein';
                    cg4 = 'suche';
                    cg5 = 'trefferliste';
                    me.setContentId(cg1, cg2, cg3, cg4, cg5, 'trefferliste');
                }
                //Informationsseiten: Impressum, About
                if (State.current_view == 'Default') {
                    var pathname = $('article.page[view]').attr('view');
                    pathname = pathname == 'imprint' ? 'impressum' : pathname;
                    pathname = pathname == 'newsletter_deregister' ? 'newsletterabmeldung' : pathname;
                    cg2 = State.partner_filter_topic ? State.partner_filter_topic : 'home';
                    cg3 = 'allgemein';
                    cg4 = pathname ? pathname : State.current_pathname.substr(1, State.current_pathname.length);
                    cg5 = cg4;
                    me.setContentId(cg1, cg2, cg3, cg4, cg5, cg5);
                }
                if (State.current_view == 'Newsletter') {
                    cg2 = 'home';
                    if ((State.last_view == 'Topic' || State.last_view == 'Glossary') && State.last_content_route) {
                        var tmp = State.last_content_route.match(/\/\w+(?=\/)/);
                        if (tmp !== null && tmp[0]) {
                            cg2 = tmp[0].replace('/', '');
                        }
                    }
                    cg3 = 'allgemein';
                    cg4 = 'newsletter';
                    cg5 = 'anmeldung';
                    me.setContentId(cg1, cg2, cg3, cg4, cg5, 'anmeldung');
                }
                //Verlauf
                if (State.current_view == 'History') {
                    cg2 = 'home';
                    cg3 = 'allgemein';
                    cg4 = 'verlauf';
                    cg5 = 'verlauf';
                    me.setContentId(cg1, cg2, cg3, cg4, cg5, 'verlauf');
                }
                //Merkliste - anzeigen
                if (State.current_view == 'Watchlist') {
                    cg2 = 'home';
                    cg3 = 'allgemein';
                    cg4 = 'merkliste';
                    cg5 = 'index';
                    me.setContentId(cg1, cg2, cg3, cg4, cg5, 'index');
                }
                //Merkliste - teilen
                if (State.current_view == 'WatchlistShared') {
                    cg2 = 'home';
                    cg3 = 'allgemein';
                    cg4 = 'merkliste';
                    cg5 = 'teilen';
                    me.setContentId(cg1, cg2, cg3, cg4, cg5, 'teilen');
                }

                //Topic, TopicSelection, Article
                if (State.is_topic_route) {
                    if (State.current_article) {
                        //Einzelartikel
                        me.setContentId(cg1, cg2, cg3, cg4, cg5, 'article');
                        //Photoswipe Vollbildgallerie aktiv
                        if (State.current_gallery) {
                            me.setContentId(cg1, cg2, cg3, cg4, cg5, 'lightbox');
                        }
                    } else {
                        //Topic oder Unterrubriken
                        cg5 = 'index';
                        me.setContentId(cg1, cg2, cg3, cg4, cg5, 'index');
                    }
                }
                if (State.is_glossary_route) {
                    if (State.current_glossary_article) {
                        //Glossartikel
                        cg5 = State.current_glossary_article;
                        me.setContentId(cg1, cg2, cg3, cg4, cg5, 'article');
                    } else {
                        //Glossarübersicht
                        cg5 = 'index';
                        me.setContentId(cg1, cg2, cg3, cg4, cg5, 'index');
                    }
                }
                
                // glossary layer route - as if it is a glossar article but with customParameter 11 as glossarylayer 
                if (State.is_topic_route && State.current_glossary_layer) {
                    cg2 = State.current_topic ? State.current_topic : 'home';
                    cg3 = 'allgemein';
                    cg4 = 'glossar';
                    
                    if (typeof State.context !== 'undefined' && 
                        typeof State.context.query !== 'undefined' && 
                        typeof State.context.query.params !== 'undefined' && 
                        typeof State.context.query.params.glossar !== 'undefined' &&
                        typeof State.context.query.params.glossar.article !== 'undefined'
                    ) {
                        cg5 = State.context.query.params.glossar.article ? State.context.query.params.glossar.article : 'glossar';
                    } else {
                        cg5 = 'glossar';
                    }
                    me.setContentId(cg1, cg2, cg3, cg4, cg5, 'glossar');
                }

                //ContentGroups setzen
                wt.contentGroup = {
                    1: cg1,
                    2: cg2,
                    3: cg3,
                    4: cg4,
                    5: cg5
                }
            },

            getContentId: function () {
                return wt.contentId;
            },

            setContentId: function (cg1, cg2, cg3, cg4, cg5, suffix) {
                contentId = cg1 + '.' + cg2 + '.' + cg3 + '.' + cg4 + '.' + cg5 + '.' + suffix;
                wt.contentId = contentId;
            },

            getCustomParameter: function () {
                return wt.customParameter;
            },

            Pecunia: {
                isBannerEnabled: function () {
                    return (typeof window.banner === 'function');
                },
                send: function () {
                    var me = this;
                    //Werbung geblockt?
                    var hasBanner = me.isBannerEnabled() ? 1 : 0; //1 Werbung ist sichtbar, 0 Adblocker aktiv
                    if (wt) {
                        var contentId = encodeURI(wt.contentId);
                        var contentGroup = encodeURI(wt.contentGroup[2]);
                        var url = '/bnw-fliege?contentid=' + contentId + '&contengroup=' + contentGroup + '&w=' + hasBanner + '&r=' + (Math.floor(Math.random() * 10000) + 1);
                        var $bnwFliege = $('#bnw-fliege');
                        if (!$bnwFliege.length) {
                            $bnwFliege = $('<iframe style="display: none; visibility: hidden; width: 0; height: 0; overflow: hidden;" id="bnw-fliege" border="0" height="0" width="0" scrolling="no" src=""></iframe>');
                        }
                        $bnwFliege.attr('src', url);
                        // deaktiviert 10.2021
                        //$('body').append($bnwFliege);
                    }
                }
            },

            //alle Methoden zum customParameter aggregieren
            customParameter: {
                getResponsiveLayout: function () {
                    var output = 'l';
                    switch (true) {
                        case (State.is_ultra):
                            //is larger than 2400px with
                            output = 'xl';
                            break;
                        case (State.is_tablet):
                            output = 'm';
                            break;
                        case (State.is_mobile):
                            output = 's';
                            break;
                    }
                    return output;
                },
                getSearchResults: function () {
                    return State.is_search_route ? (State.current_search_count).toString() : '';
                },
                getSearchTerm: function () {
                    var output = Helper.getParameterByName('q');
                    output = typeof output === 'string' ? output : false;
                    output = output != '' ? output : false;
                    return output;
                },
                getFilterThemen: function () {
                    var output = '';
                    if (State.is_search_route) {
                        output = State.current_search_topic ? State.current_search_topic : 'alle Themen';
                    }
                    return output;
                },
                getFilterRubrik: function () {
                    var output = '';
                    if (State.is_search_route) {
                        output = State.current_search_selection ? State.current_search_selection : 'alle Inhalte';
                    }
                    return output;
                },
                getPagination: function () {
                    return State.is_search_route ? (State.current_search_page).toString() : '';
                },
                getPaginationCount: function () {
                    return State.is_search_route ? (State.current_search_page_counter).toString() : '';
                },
                // getServerResponseTime: function () {
                //     var output = '';
                //     if ('performance' in window &&
                //         'timing' in window.performance) {
                //         //1malig geladene Seite ohne AJAX-Element
                //         //Server Response vom Start der Abfrage bis zur Antwort (kein DOM Rendern berücksichtigt)
                //         output = (window.performance.timing.responseStart - window.performance.timing.navigationStart).toString();
                //
                //         //nachgeladene Seiten per AJAX hiermit in Ressourcen Suchen die per xmlhttprequest Objekt geladen wurden
                //         if ('getEntriesByType' in window.performance) {
                //             var _resourceList = window.performance.getEntriesByType("resource");
                //             var i = _resourceList.length - 1;
                //             //rückwärts Suche schneller als die Historie von vorne durchzugehen
                //             for (i; i >= 0; --i) {
                //                 //baunetzwissen Link der aktuellper AJAX geladen wurde
                //                 if (_resourceList[i] &&
                //                     _resourceList[i].initiatorType == "xmlhttprequest" &&
                //                     _resourceList[i].name.indexOf('baunetzwissen')) {
                //                     output = Math.round(_resourceList[i].responseStart - _resourceList[i].requestStart).toString();
                //                     break;
                //                 }
                //             }
                //         }
                //     }
                //     return output;
                // },
                getTopicPartner: function () {
                    //1 oder 2 Partner des Themas zurückgeben als CSV
                    var output = '';

                    if (State.is_topic_route) {
                        output = $('.partner-link').map(function () {
                            return $(this).attr('data-company')
                        }).get().join(',');
                    }

                    return output;
                },
                getDeviceType: function () {
                    return State.is_mobile ? 'mobile' : 'desktop';
                },
                getSponsoredArticle: function () {
                    //Partnerartikel Partner Titel zurückgehen
                    var output = '';

                    if (State.is_topic_route || State.is_glossary_route) {
                        output = $('article[view=article]').attr('data-article-sponsor');
                    }

                    return output ? output : '';
                },
                /**
                 * Wenn der Glossar Layer geöffnet ist true zurückliefern 
                 * 
                 * @returns {boolean|*}
                 */
                hasGlossaryLayer: function() {
                    return (State.is_topic_route && State.current_glossary_layer);
                },
                build: function () {
                    var me = this;

                    //Es werden nur String Parameter versendet, kein Integer

                    //customParameter
                    wt.customParameter = {
                        1: me.getSearchResults(), //Search results counter
                        2: me.getFilterThemen(), //Filter Themen
                        3: me.getFilterRubrik(), //Filter Rubrik
                        4: me.getPaginationCount(), //Result Sites
                        5: me.getPagination(), //Search Pagination
                        6: me.getTopicPartner(), //Sponsor
                        7: me.getDeviceType(), //Mobile oder Desktop
                        8: me.getResponsiveLayout(), //Responsive Layout
                        9: me.getSponsoredArticle(), //spezieller Sponsor/Partner für einen Artikel
                        11: me.hasGlossaryLayer() ? 'glossarylayer' : '', //wenn Glossar Layer offen ist 
                        // 570: me.getServerResponseTime(), //Ladezeit (Server Response Time siehe https://www.html5rocks.com/en/tutorials/webperformance/basics/)
                        // 772: '', //Fehlermeldungen
                        776: $('head title').text() //Seitentitel
                    };

                    //zur Suche gehört der Suchbegriff
                    wt.internalSearch = me.getSearchTerm();
                }

            }

        };

        return WebtrekkService;

    });