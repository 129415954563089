// ==========================================================================================================
// ==== DROPDOWN MODULE CONTROLLER ==========================================================================
// ==========================================================================================================

modulejs.define(
    // name
    'module.topicDropdown',
    // dependencies
    [
        'jQuery',
        'TweenMax',
        'service.config'
    ],
    // constructor
    function ($, TweenMax, Config) {
        // VIEW NAME //////////////////////////////////////////////////////////
        var name = 'TopicDropdown';


        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'Module',
            LINK_HEIGHT = 28;


        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ',

            _selector = '.dropdown',
            _selector_toggle = '.dropdown-toggle',
            _selector_body = '.dropdown-body',
            _selector_head = '.dropdown-head',
            _selector_link = '.topic-dropdown-current-link',

            _attr_topic_bg = 'data-topic-text-bg',
            _attr_topic_hover = 'data-topic-hover-bg',
            _attr_color = 'data-bg-color',

            _element = [],
            _element_toggle = [],
            _element_body = [],
            _element_head = [],
            _element_link = [],

            _height_body = 0,
            _is_open = false,
            _is_hovered = false,
            _initialized = false;


        // PRIVATE METHODS ////////////////////////////////////////////////////
        var Controller = {};

        Controller.log = function (string) {
            console.log(_log + string);
        };


        // CONSTRUCTOR ////////////////////////////////////////////////////////
        var Dropdown = function (container) {

            this.element = $(_selector, container);

            if (this.element.data('topicDropdown')) return;

            this.init();
        };


        Dropdown.prototype.init = function () {

            Controller.log('init');
            console.log(this.element);

            var self = this;

            this.element_toggle = $(_selector_toggle, this.element);
            this.element_body = $(_selector_body, this.element);
            this.element_head = $(_selector_head, this.element);
            this.element_link = $(_selector_link, this.element);

            this.height_body = $('a', this.element_body).length * LINK_HEIGHT;


            this.element.data('topicDropdown', true);

            // open/close menu
            $(this.element_toggle).on('click', function (event) {

                Controller.log('click');
                console.log(self);

                if (self.is_open) {
                    self.close();
                } else {
                    self.open();
                }
            });


            $(this.element_body).on('click', 'a', function () {
                
                // select by topic slug 
                self.selectTopic($(this).attr(_attr_topic_hover));

            });


            if (Config['MOBILE_BREAKPOINT'] < $(window).width()) {

                this.element_body
                    .on('mouseenter', 'a', function (event) {
                        $(this).trigger('topic.mouseenter', event);
                        return false;
                    })
                    .on('mouseleave', 'a', function (event) {
                        $(this).trigger('topic.mouseleave', event);
                        return false;
                    });
                // track if the links are interacted with
                this.element_body
                    .on('mouseenter', function (event) {
                        if (_is_hovered) return true;
                        _is_hovered = true;
                        $(this).trigger('topic.dropdown.mouseenter', event);
                    })
                    .on('mouseleave', function (event) {
                        $(this).trigger('topic.dropdown.mouseleave', event);
                    });
            }
        };

        Dropdown.prototype.update = function (topic) {

            var self = this,
                target = $('[' + _attr_topic_hover + '="' + topic + '"]', self.element);

            // update selected
            $('.active', self.element).removeClass('active');

            if (topic && topic.length) {
                target.addClass('active');
                self.element.addClass('has-selection');

                // update label
                self.element_toggle.attr(_attr_color, topic);
                self.element_toggle.attr('data-label', $(target).text());
                
                self.selectTopic(topic);
            } else {
                self.element.removeClass('has-selection');
                self.element_toggle.attr(_attr_color, '');
                self.element_toggle.attr('data-label', '');
            }
            
        };

        /**
         * Change topic by slug
         * 
         * @param topic
         */
        Dropdown.prototype.selectTopic = function (topic) {
            if (topic !== '') {
                var self = this;
                var $linkTopic = $('[' + _attr_topic_hover + '="' + topic + '"]', self.element_body);

                // found topic in list 
                if ($linkTopic.length) {
                    self.close();

                    // update active topic link
                    $('.active', self.element).removeClass('active');
                    $linkTopic.addClass('active');
                    self.element.addClass('has-selection');

                    // update label
                    self.element_head.attr(_attr_topic_bg, $linkTopic.attr(_attr_topic_hover));

                    // update current link 
                    var $link = self.element_link.find('a');
    
                    if ($link.length) {
                        $link.replaceWith($linkTopic.clone());
                    } else {
                        self.element_link.append($linkTopic.clone());
                    }
                    
                    return true;
                }
            }
            
            return false;
        };

        Dropdown.prototype.open = function () {

            Controller.log('open');

            if (this.is_open) return;

            var self = this;

            this.element_toggle.attr('aria-expanded', 'true');
            this.is_open = true;
            this.element_body.show();
            this.element_body.css('will-change', 'height');
            // TweenMax.fromTo(this.element_body, 0.5, {y:-1*this.height_body+'px'}, {y:0, ease: Power3.easeInOut});
            TweenMax.fromTo(this.element_body, 0.5, {height: 0}, {
                height: this.height_body + 'px', ease: Power3.easeInOut, onComplete: function () {
                    self.element_body.css('will-change', 'auto');
                }
            });
            this.element.addClass('active');
        };


        Dropdown.prototype.close = function () {

            if (!this.is_open) return;

            Controller.log('close');

            var self = this;

            this.element_toggle.attr('aria-expanded', 'false');
            this.is_open = false;

            this.element_body.css('will-change', 'height');

            TweenMax.to(this.element_body, 0.4, {
                height: 0, ease: Power1.easeIn, onComplete: function () {
                    self.element_body.hide();
                    self.element_body.css('will-change', 'auto');
                }
            });

            this.element.removeClass('active');
        };


        Dropdown.prototype.destroy = function () {
            $('.active', this.element).removeClass('active');
            this.element.removeClass('has-selection');

            this.element_toggle.attr(_attr_color, '');
            this.element_toggle.attr('data-label', '');

            this.element.data('topicDropdown', false);

            this.element.remove();

            delete this;
        };


        // PUBLIC METHODS /////////////////////////////////////////////////////
        return Dropdown;
    }
);
