// ==========================================================================================================
// ==== META CONTROLLER =====================================================================================
// ==========================================================================================================

modulejs.define(
    // name
    'controller.meta',
    // dependencies
    [
        'jQuery'
    ],
    // constructor
    function ($) {
        // VIEW NAME //////////////////////////////////////////////////////////
        var name = 'Meta';

        // STATIC VARS ////////////////////////////////////////////////////////
        var TYPE = 'Controller';

        // PRIVATE VARS ///////////////////////////////////////////////////////
        var _log = TYPE + '.' + name + ' | ',
            _selector = '.meta-ajax';

        // PRIVATE METHODS ////////////////////////////////////////////////////
        var Controller = {};

        Controller.log = function (string) {
            console.log(_log + string);
        };

        Controller.update = function (meta) {

            if (meta.title) {
                $('head title').html(meta.title);
                $('head meta[property="og:title"]').attr('content', meta.title);
            }

            if (meta.description) {
                $('head meta[property="og:description"]').attr('content', meta.description);
                $('head meta[name="description"]').attr('content', meta.description);
            }

            if (meta.url) {
                $('head link[rel="canonical"]').attr('href', meta.url);
                $('head meta[property="og:url"]').attr('content', meta.url);
            }

            if (meta.image) {
                $('head meta[property="og:image"]').attr('content', meta.image);
                $('head meta[property="og:image:width"]').attr('content', meta.image_width);
                $('head meta[property="og:image:height"]').attr('content', meta.image_height);
            }

            if (meta.tags) {
                $('head meta[property="og:tags"]').attr('content', meta.tags);
            }

            if (meta.type) {
                $('head meta[property="og:type"]').attr('content', meta.type);
            }
        };
        
        // expects the .meta-ajax element from the current page
        Controller.parse = function (elem) {
            Controller.log('parse');
            var meta = {};

            $(elem).children().each(function () {
                var key = $(this).data('key');
                var value = $(this).data('value');

                meta[key] = value;
            });

            console.log(meta);

            Controller.update(meta);
        };

        // search for meta element in container
        Controller.readFrom = function (container) {

            var element = $(_selector, container);

            if (element.length) {
                Controller.parse(element);
            }
        };

        // PUBLIC METHODS /////////////////////////////////////////////////////
        return {
            readFrom: Controller.readFrom
        }
    }
);
