
// ==========================================================================================================
// ==== VIDEO EMBED CONTROLLER ==============================================================================
// ==========================================================================================================


modulejs.define(
	// name
	'controller.video.embed',
	// dependencies
	[
		'jQuery',
		'service.config'
	],
	// constructor
	function ($, Config)
	{
		// VIEW NAME //////////////////////////////////////////////////////////
		var name = 'VideoEmbed';


		// STATIC VARS ////////////////////////////////////////////////////////
		var TYPE = 'Controller',
			YOUTUBE_IMG_URL = 'https://img.youtube.com/vi', //'/<youtube-video-id>/<youtube-thumbnail>',
			YOUTUBE_IMG_THUMB = 'sddefault.jpg',


			VIMEO_API_URL = 'https://vimeo.com/api/oembed.json?url=https://vimeo.com/'; // append video id returns json


		// PRIVATE VARS ///////////////////////////////////////////////////////
		var _log = TYPE+'.'+name+' | ',
			_element = [];

		
		// PRIVATE METHODS ////////////////////////////////////////////////////
		var Controller = {};

		Controller.log = function ( string )
		{
			console.log(_log + string);
		};

		Controller.getCode = function ( link ) {
			var video = Controller.parseYouTube(link);

			if (video) {
				return video;
			} else {
				video = Controller.parseVimeo(link);

				if (video) {
					return video;
				}
			}

			return false;
		}

		Controller.create = function ( link ) {
			
			var video = Controller.parseYouTube(link);

			// var link = href.replace('https', ''); //.replace('http', '');
			

			if (video)
			{
				return Controller.createYouTube(video);
			}
			else
			{
				video = Controller.parseVimeo(link);

				if (video)
				{
					return Controller.createVimeo(video);
				}
			}

			// return false if link could not be processed
			return false;
			
		};

		Controller.parseYouTube = function ( str ) {
			// url : //youtube.com/watch?v=Bo_deCOd1HU
			// share : //youtu.be/Bo_deCOd1HU
			// embed : //youtube.com/embed/Bo_deCOd1HU

			var re = /\/\/(?:www\.)?youtu(?:\.be|be\.com)\/(?:watch\?v=|embed\/)?([a-z0-9_\-]+)/i; 
			var matches = re.exec(str);
			
			if ( matches && matches[1] )
			{
				return matches[1];
			}
			else
			{
				return false;
			}
		};

		Controller.createYouTube = function (video) {

			return $('<iframe src="https://www.youtube.com/embed/'+video+'?rel=0&showinfo=0&autoplay=1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
		};


		Controller.createYouTubeImage = function (video) {
			Controller.log('createYoutubeImage');
			return $('<img width="640" height="480" src="' + YOUTUBE_IMG_URL + '/' + video + '/' + YOUTUBE_IMG_THUMB + '">');
		};


		Controller.parseVimeo = function (str) {
			// http://vimeo.com/86164897
			// or https://player.vimeo.com/video/168351499
			
			var re = /\/\/(?:www\.|player\.)?vimeo.com\/(?:video\/)([0-9a-z\-_]+)/i;
			var matches = re.exec(str);

			Controller.log('vimeo');
			console.log(matches);

			if ( matches && matches[1] )
			{
				return matches[1];
			}
			else
			{
				return false;
			}
		};

		Controller.createVimeo = function (video) {

			return $('<iframe src="https://player.vimeo.com/video/'+video+'?color=ffffff&title=0&portrait=1&autoplay=1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');

		};


		Controller.createVimeoImage = function (video) {
			Controller.log('createVimeoImage');

			var deferred = $.Deferred();

			$.get(VIMEO_API_URL+video, function(data) {
				console.log(data);
				
				if ( data && data.thumbnail_url ) {
					deferred.resolve($('<img width="' + data.thumbnail_width + '" height="' + data.thumbnail_height + '" src="' + data.thumbnail_url + '">'));
				} else {
					deferred.resolve( $('<img width="640" height="360" src="' + Config.PLACEHOLDER_IMAGE + '">') );
				}
				
			});
			
			return deferred.promise();
		};


		Controller.createImage = function (link) {
			Controller.log('createImage | ' + link);
			
			var video = Controller.parseYouTube(link),
				deferred = $.Deferred();
			
			if (video)
			{
				console.log(video);
				deferred.resolve( Controller.createYouTubeImage(video) );
			}
			else
			{
				video = Controller.parseVimeo(link);

				if (video)
				{
					return Controller.createVimeoImage(video);
				}
			}

			// return empty object if link could not be processed
			return deferred.promise();
		}

		// PUBLIC METHODS /////////////////////////////////////////////////////
		return {
			create: Controller.create,
			getCode: Controller.getCode,
			createImage: Controller.createImage
		}
	}
);
