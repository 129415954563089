// ==========================================================================================================
// ==== HISTORY CONTROLLER ==================================================================================
// ==========================================================================================================

modulejs.define(
	// name
	'controller.history',
	// dependencies
	[
		'jQuery',
		'Cookies',
		'lscache',
		'shared.helper',
		'service.config',
		'service.state',
	],
	// constructor
	function ( $, Cookies, lscache, Helper, Config, State ) {
		// VIEW NAME //////////////////////////////////////////////////////////
		var name = 'History';

		// STATIC VARS ////////////////////////////////////////////////////////
		var TYPE = 'Controller';

		// PRIVATE VARS ///////////////////////////////////////////////////////
		var _log = TYPE + '.' + name + ' | ',
			_selector_counter = '#history_counter',
			_selector_label = '#history_label',

			_element_counter = [],

			_initialized = false;

		// PRIVATE METHODS ////////////////////////////////////////////////////
		var Controller = {};

		Controller.log = function ( string ) {
			console.log( _log + string );
		};


		Controller.init = function () {

			Controller.log('cache_disabled: '+State.cache_disabled);

			if ( State.cache_disabled ) {
				$(_selector_label).addClass('disabled');
				return;
			}

			_element_counter = $(_selector_counter);
				
			Controller.log('init');

			_initialized = true;

			var history;

			var historyCache = lscache.get('history');


			if ( historyCache == null || historyCache == 'undefined' || Controller.checkFormat(historyCache) ) {
				
				history = Controller.reset();
			} else {
				// history = JSON.parse(historyCookie);
				history = historyCache;
			}

			

			_element_counter.on(Helper.whichAnimationEnd(), function (e) {
				$(this).removeClass('updating');
			});

			Controller.updateCounter(history);

			
			
		};

		//todo: bei Strukturaenderung bei Cookiedaten anpassen
        Controller.checkFormat = function (list) {
			// if (list.list.length && list.list[list.list.length-1].articleId) {
			// 	alert('History Cache Outdated');
			// 	return true;
			// }

			return false;
		};

		Controller.add = function (historyObject) {

			if (!_initialized) return;

			Controller.log('add');

			// var history = JSON.parse(Cookies.get('history'));
			var history = lscache.get('history');
			
			if ( history == null ) {
				Controller.log('no history cache');
				return false;
			};

			var exists = Helper.indexByAttr(history.list, 0, historyObject[0]);

			// remove old entry if it allready exists in history
			if ( exists !== false ) {
				history.list.splice(exists, 1);
			}

			if(history && history.list) {
				history.list.unshift(historyObject);

				Controller.updateCache(history);
				Controller.updateCounter(history);
			}
		};


		Controller.get = function () {

			if (!_initialized) return false;

			var history = lscache.get('history');

			if (history == null) {
				return false;
			} else {
				return history;
			}
		};


		Controller.reset = function () {

			if (!_initialized) return;

			var history = {};
			history.list = [];
			
			Controller.updateCache(history);
			Controller.updateCounter(history);

			return history;
		};


		Controller.updateCache = function (history) {

			lscache.set('history', history);

		};


		Controller.updateCounter = function (history) {

			var count = history.list.length;
			
			if ( !count ) count = '-';

			_element_counter.addClass('updating');

			_element_counter.text(count);
		};


		Controller.overwrite = function (history) {
			Controller.updateCache(history);
			Controller.updateCounter(history);
		};

		// PUBLIC METHODS /////////////////////////////////////////////////////
		return {
			init: Controller.init,
			add: Controller.add,
			get: Controller.get,
			reset: Controller.reset,
			overwrite: Controller.overwrite,
		}
	}
);
